import React, { useState, useEffect, useRef } from 'react';
import Chart from "react-apexcharts";
import { Container, Row, Button, Accordion } from 'react-bootstrap';
import { ChevronLeft, ChevronRight, Search, Filter, ChevronDown } from 'react-bootstrap-icons';
import { useHistory } from "react-router-dom";
import { fast_api_axios, dashboard_axios } from '../../utils/axiosFast';
import { useToast } from '../../contexts/ToastContext';
import Loader from '../Loader';
import moment from 'moment';
import { DateRangePicker as RDateRangePicker } from 'rsuite';
import NoDataModal from './NoDataModal';
import Select from 'react-select';
import SliderItem from './SliderItem';
import NotFound from '../../assets/images/restricted.png'
import { useAuth } from '../../contexts/AuthContext';
import { getDevices, getStreams, getLines, getClasses, getDirections, findDeviceByDirection, findCameraByDirection, findLineNameByLineId, findDirectionNameByDirectionId } from '../../utils/generic';
import momentTimezone from 'moment-timezone';
import NoDataBoard from '../exampleDashboards/NoDataBoard';

const {
  afterToday,
} = RDateRangePicker;

const pieColors = [
  '#22FCDB',
  '#492CE6',
  '#60EFFF',
  '#FF00F8',
  '#8020CF',
  '#159A86',
  '#9585F1',
  '#352B63',
  '#B300AE',
  '#90F4FF',
  '#2D1B8C',
  '#B57EE3',
  '#26909C'
]

export default function CountingNew() {
  momentTimezone.tz.setDefault(localStorage.getItem('timezone'));
  let history = useHistory();
  const { updateMessage } = useToast();
  const { logout, currentUser } = useAuth();

  const deviceOptions = getDevices()
  const streamOptions = getStreams();
  const classOptions = getClasses()
  const lineOptions = getLines();
  const directionOptions = getDirections();

  const [filterDevices, setFilterDevices] = useState([]);
  const [filterStreams, setFilterStreams] = useState([]);
  const [filterClasses, setFilterClasses] = useState([]);
  const [filterLines, setFilterLines] = useState([]);
  const [filterDirections, setFilterDirections] = useState([]);
  const [isApplyActive, setIsApplyActive] = useState(false);

  const [dateFilter, setDateFilter] = useState({
    'start': moment().startOf('day'),
    'end': moment()
  });

  const [showFilterBox, setShowFilterBox] = useState(false);
  const dropdownRef = useRef();
  const closeOpenMenus = (e) => {
    if (dropdownRef.current && showFilterBox && !dropdownRef.current.contains(e.target)) {
      setShowFilterBox(false)
    }
  }
  document.addEventListener('mousedown', closeOpenMenus)

  const [totalCount, setTotalCount] = useState(0);
  const [isSummaryLoading, setIsSummaryLoading] = useState(true);
  const [summaryData, setSummaryData] = useState([]);

  const [isStreamBasedClassesBarLoading, setIsStreamBasedClassesBarLoading] = useState(true);
  const [isClassesBarLoading, setIsClassesBarLoading] = useState(true);

  const [streamBasedBarDataClasses, setStreamBasedBarDataClasses] = useState([]);
  const [streamBasedBarDataClassesLabel, setStreamBasedBarDataClassesLabel] = useState([]);
  const [barDataClasses, setBarDataClasses] = useState([]);
  const [barDataClassesLabel, setBarDataClassesLabel] = useState([]);

  const [isLineChartLoading, setIsLineChartLoading] = useState(true);
  const [granularity, setGranularity] = useState({
    'label': "Hourly",
    'value': "hour"
  });
  const [hourlySelectedDay, setHourlySelectedDay] = useState(moment(dateFilter['start']).startOf('day'));
  const [chartLabels, setChartLabels] = useState([]);
  const [chartDataset, setChartDataset] = useState([]);
  const [chartDraw, setChartDraw] = useState([]);
  const lineData = {
    labels: chartLabels,
    datasets: chartDataset
  };


  const handleDeviceSelect = (items) => {
    setFilterDevices(items);
  };
  const handleStreamSelect = (items) => {
    setFilterStreams(items);
  };
  const handleClassSelect = (items) => {
    setFilterClasses(items);
  };
  const handleLineSelect = (items) => {
    setFilterLines(items);
  };
  const handleDirectionSelect = (items) => {
    setFilterDirections(items);
  };

  const handleFilterApply = () => {
    setIsApplyActive(false);
    setShowFilterBox(false);
    getAllData();
  }

  useEffect(() => {
    if (filterDevices.length + filterStreams.length + filterClasses.length + filterLines.length + filterDirections.length === 0) {
      setIsApplyActive(false);
    }
    else {
      setIsApplyActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDevices, filterStreams, filterClasses, filterLines, filterDirections])

  const clearAllFilters = () => {
    setFilterDevices([]);
    setFilterStreams([]);
    setFilterClasses([]);
    setFilterLines([]);
    setFilterDirections([]);
    setDateFilter({
      'start': moment().tz(localStorage.getItem('timezone')).startOf('day'),
      'end': moment().tz(localStorage.getItem('timezone'))
    });
    setShowFilterBox(false);
    //localStorage.removeItem('filterObj');
  }


  const handleGranularitySelect = (opt) => {
    if (opt.value === "hour") {
      setHourlySelectedDay(moment(dateFilter['start']).startOf('day'));
    }
    setGranularity(opt);
    getDistribution(opt.value, moment(dateFilter['start']).startOf('day'));
    /*getTopn()
    getCount()
    getSummary()*/
  }


  const handlePrevDay = () => {
    let start = moment(hourlySelectedDay).subtract(1, 'days').format('MM/DD/YYYY') === moment(dateFilter['start']).format('MM/DD/YYYY') ? moment(dateFilter['start']).unix() : moment(hourlySelectedDay).subtract(1, 'days').startOf('day').unix();
    let end = moment(hourlySelectedDay).subtract(1, 'days').endOf('day').unix();

    getDistributionHourly(start, end);
    getTopn()
    getCount()
    getSummary()
  }

  const handleNextDay = () => {
    let start = moment(hourlySelectedDay).add(1, 'days').startOf('day').unix();
    let end = moment(hourlySelectedDay).add(1, 'days').format('MM/DD/YYYY') === moment(dateFilter['end']).format('MM/DD/YYYY') ? moment(dateFilter['end']).unix() : moment(hourlySelectedDay).add(1, 'days').endOf('day').unix();
    getDistributionHourly(start, end);
    getTopn()
    getCount()
    getSummary()
  }

  const getCount = () => {
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/line/count',
        params: {
          'start': moment(dateFilter['start']).unix(),
          'end': moment(dateFilter['end']).unix(),
          'devices': filterDevices.length > 0 ? filterDevices.map(a => a.value).toString() : null,
          'cameras': filterStreams.length > 0 ? filterStreams.map(a => a.value).toString() : null,
          'lines': filterLines.length > 0 ? filterLines.map(a => a.value).toString() : null,
          'classes': filterClasses.length > 0 ? filterClasses.map(a => a.value).toString() : null,
          'directions': filterDirections.length > 0 ? filterDirections.map(a => a.value).toString() : null,
        }
      })
      .then(res => {
        setTotalCount(res.data.total_count)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getSummary = () => {
    setIsSummaryLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/line/summary',
        params: {
          'start': moment(dateFilter['start']).unix(),
          'end': moment(dateFilter['end']).unix(),
          'devices': filterDevices.length > 0 ? filterDevices.map(a => a.value).toString() : null,
          'cameras': filterStreams.length > 0 ? filterStreams.map(a => a.value).toString() : null,
          'lines': filterLines.length > 0 ? filterLines.map(a => a.value).toString() : null,
          'classes': filterClasses.length > 0 ? filterClasses.map(a => a.value).toString() : null,
          'directions': filterDirections.length > 0 ? filterDirections.map(a => a.value).toString() : null,
        }
      })
      .then(res => {
        var newSummaryData = []

        res.data.forEach((summaryItem, index) => {
          let newItem = {
            "id": index,
            "name": summaryItem.class_title,
            "count": summaryItem.count,
            "dirname": findDirectionNameByDirectionId(summaryItem.direction_id),
            "linename": findLineNameByLineId(summaryItem.line_id),
            "strname": findCameraByDirection(summaryItem.direction_id),
            "dvcname": findDeviceByDirection(summaryItem.direction_id),
          }
          newSummaryData.push(newItem)
        })

        setSummaryData(newSummaryData);
        setIsSummaryLoading(false);
      })
      .catch(error => {
        console.log(error)
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getDistribution = (graVal, hsd) => {
    setIsLineChartLoading(true);

    let start = moment(dateFilter['start']).unix()
    let end = graVal === 'hour' ? moment(dateFilter['end']).format('MM/DD/YYYY') === moment(hsd).format('MM/DD/YYYY') ? moment(dateFilter['end']).unix() : moment(hsd).endOf('day').unix() : moment(dateFilter['end']).unix()


    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/line/distribution',
        params: {
          'granularity': graVal,
          'start': start,
          'end': end,
          'devices': filterDevices.length > 0 ? filterDevices.map(a => a.value).toString() : null,
          'cameras': filterStreams.length > 0 ? filterStreams.map(a => a.value).toString() : null,
          'lines': filterLines.length > 0 ? filterLines.map(a => a.value).toString() : null,
          'classes': filterClasses.length > 0 ? filterClasses.map(a => a.value).toString() : null,
          'directions': filterDirections.length > 0 ? filterDirections.map(a => a.value).toString() : null,
        }
      })
      .then(res => {
        let distData = res.data;
        let labels = [];

        distData.forEach((a, alertIndex) => a.forEach((x, index) => {
          let timeItem = x.time;
          timeItem = timeItem.includes('+') ? timeItem : timeItem + '+00:00'
          distData[alertIndex][index].time = timeItem;

          var index = labels.findIndex(i => i === moment(timeItem).format('MM/DD/YYYY HH:mm'));
          index === -1 && labels.push(moment(timeItem).format('MM/DD/YYYY HH:mm'))
        }));

        var duration = moment.duration(dateFilter['end'].diff(dateFilter['start'])).asDays();

        if (graVal === 'hour') {
          setChartDraw([])
          var labelDatesRemoved = []
          var draw = []

          labels.forEach((item, index) => {
            if (index === 0 || index === labels.length - 1) {
              labelDatesRemoved.push(item)
            }
            else {
              labelDatesRemoved.push(item.slice(11, 16))
            }
            draw.push(item)
          });


          setChartDraw(draw)
          setChartLabels(labelDatesRemoved)
        } else if (graVal === 'day') {
          setChartDraw([])
          var labelDatesRemovedtwo = []
          var drawtwo = []
          var tzOffset = moment().utcOffset() / 60;

          labels.forEach((item, index) => {
            if (duration <= 60) {
              labelDatesRemovedtwo.push(moment(item).local().add(-tzOffset, 'hours').format('MM/DD/YYYY'))
            }
            else {
              if (index % 2 === 0) {
                labelDatesRemovedtwo.push(moment(item).local().add(-tzOffset, 'hours').format('MM/DD/YYYY'))
              }
              else {
                labelDatesRemovedtwo.push('')
              }
            }

            drawtwo.push(moment(item).local().add(-tzOffset, 'hours').format('MM/DD/YYYY'));

          });


          setChartDraw(drawtwo)
          setChartLabels(labelDatesRemovedtwo)
        }
        else if (graVal === 'week') {
          setChartDraw([])
          var labelDatesRemovedtwo = []
          var drawtwo = []
          var tzOffset = moment().utcOffset() / 60;

          labels.forEach((item, index) => {
            drawtwo.push(moment(item).local().add(-tzOffset, 'hours').format('MM/DD/YYYY'));
            labelDatesRemovedtwo.push(moment(item).local().add(-tzOffset, 'hours').format('MM/DD/YYYY'))

          });
          setChartDraw(drawtwo)
          setChartLabels(labelDatesRemovedtwo)
        }



        let datasets = [];
        distData.forEach((item, index) => {
          let innerDataset = [];
          labels.forEach(function (labelItem) {
            let cnt = item.find(o => moment(o.time).format('MM/DD/YYYY HH:mm') === labelItem) ? item.find(o => moment(o.time).format('MM/DD/YYYY HH:mm') === labelItem).count : 0;
            innerDataset.push(cnt)
          })

          let chartDataset = {
            name: streamOptions.find(a => a.value === item[0].camera_id) ? streamOptions.find(a => a.value === item[0].camera_id).label + '-' + item[0].class_title : "-",
            data: innerDataset,
            fill: false,
            borderColor: pieColors[index % 13],
            backgroundColor: pieColors[index % 13],
            tension: 0.1
          }
          datasets.push(chartDataset)
        })

        let sortedDatasets = datasets.sort((a, b) => b.data.reduce((a, b) => a + b, 0) - a.data.reduce((a, b) => a + b, 0))

        sortedDatasets.forEach((item, index) => {
          item.backgroundColor = pieColors[index % 13]
          item.borderColor = pieColors[index % 13]
        })

        setChartDataset(sortedDatasets)
        setIsLineChartLoading(false)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }


  const getDistributionHourly = (start, end) => {
    setIsLineChartLoading(true);

    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/line/distribution',
        params: {
          'granularity': 'hour',
          'start': start,
          'end': end,
          'devices': filterDevices.length > 0 ? filterDevices.map(a => a.value).toString() : null,
          'cameras': filterStreams.length > 0 ? filterStreams.map(a => a.value).toString() : null,
          'lines': filterLines.length > 0 ? filterLines.map(a => a.value).toString() : null,
          'classes': filterClasses.length > 0 ? filterClasses.map(a => a.value).toString() : null,
          'directions': filterDirections.length > 0 ? filterDirections.map(a => a.value).toString() : null,
        }
      })
      .then(res => {
        let distData = res.data;
        let labels = [];

        distData.forEach((a, alertIndex) => a.forEach((x, index) => {
          let timeItem = x.time;
          timeItem = timeItem.includes('+') ? timeItem : timeItem + '+00:00'
          distData[alertIndex][index].time = timeItem;

          var index = labels.findIndex(i => i === moment(timeItem).format('MM/DD/YYYY HH:mm'));
          index === -1 && labels.push(moment(timeItem).format('MM/DD/YYYY HH:mm'))
        }));


        setChartDraw([])
        var labelDatesRemoved = []
        var draw = []

        labels.forEach((item, index) => {
          if (index === 0 || index === labels.length - 1) {
            labelDatesRemoved.push(item)
          }
          else {
            labelDatesRemoved.push(item.slice(11, 16))
          }
          draw.push(item)
        });


        setChartDraw(draw)
        setChartLabels(labelDatesRemoved)



        let datasets = [];
        distData.forEach((item, index) => {
          let innerDataset = [];
          labels.forEach(function (labelItem) {
            let cnt = item.find(o => moment(o.time).format('MM/DD/YYYY HH:mm') === labelItem) ? item.find(o => moment(o.time).format('MM/DD/YYYY HH:mm') === labelItem).count : 0;
            innerDataset.push(cnt)
          })

          let chartDataset = {
            name: streamOptions.find(a => a.value === item[0].camera_id).label + '-' + item[0].class_title,
            data: innerDataset,
            fill: false,
            borderColor: pieColors[index % 13],
            backgroundColor: pieColors[index % 13],
            tension: 0.1
          }
          datasets.push(chartDataset)
        })

        let sortedDatasets = datasets.sort((a, b) => b.data.reduce((a, b) => a + b, 0) - a.data.reduce((a, b) => a + b, 0))

        sortedDatasets.forEach((item, index) => {
          item.backgroundColor = pieColors[index % 13]
          item.borderColor = pieColors[index % 13]
        })

        setChartDataset(sortedDatasets)
        setHourlySelectedDay(moment.unix(start))
        setIsLineChartLoading(false)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getTopn = () => {
    setIsStreamBasedClassesBarLoading(true);
    setIsClassesBarLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/tracker/line/topn',
        params: {
          'start': moment(dateFilter['start']).unix(),
          'end': moment(dateFilter['end']).unix(),
          'devices': filterDevices.length > 0 ? filterDevices.map(a => a.value).toString() : null,
          'cameras': filterStreams.length > 0 ? filterStreams.map(a => a.value).toString() : null,
          'lines': filterLines.length > 0 ? filterLines.map(a => a.value).toString() : null,
          'classes': filterClasses.length > 0 ? filterClasses.map(a => a.value).toString() : null,
          'directions': filterDirections.length > 0 ? filterDirections.map(a => a.value).toString() : null,
        }
      })
      .then(res => {
        setBarDataClasses(res.data.class_title.sort((a, b) => b.count - a.count).slice(0, 5).map(a => a.count))
        setBarDataClassesLabel(res.data.class_title.sort((a, b) => b.count - a.count).slice(0, 5).map(a => a.class_title))

        setStreamBasedBarDataClasses(res.data.class_title_with_camera.sort((a, b) => b.count - a.count).slice(0, 5).map(a => a.count))
        //setStreamBasedBarDataClassesLabel(res.data.class_title_with_camera.sort((a, b) => b.count - a.count).slice(0, 5).map(a => streamOptions.find(x => x.value === a.camera_id) ? streamOptions.find(x => x.value === a.camera_id).label + ' - ' + a.class_title : '? - ' + a.class_title))
        setStreamBasedBarDataClassesLabel(res.data.class_title_with_camera.sort((a, b) => b.count - a.count).slice(0, 5).map(a => streamOptions.find(x => x.value === a.camera_id) ? streamOptions.find(x => x.value === a.camera_id).label : '?'))
        setIsStreamBasedClassesBarLoading(false);
        setIsClassesBarLoading(false);

      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else {
          updateMessage('Something went wrong.', 'error');
        }
      });
  }

  const getAllData = (hsd) => {
    getCount()
    getSummary()
    getDistribution(granularity.value, hsd)
    getTopn()
  }

  useEffect(() => {
    setHourlySelectedDay(moment(dateFilter['start']).startOf('day'));
    getAllData(moment(dateFilter['start']).startOf('day'))

    if (moment(dateFilter['end']).diff(moment(dateFilter['start']), 'days') > 0) {
      setGranularity({
        'label': "Daily",
        'value': "day"
      })
      getDistribution('day', moment(dateFilter['start']).startOf('day'))
    }
    else {
      setGranularity({
        'label': "Hourly",
        'value': "hour"
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter])


  const getAssets = () => {
    dashboard_axios({
      method: "get",
      url: "/edge/assets",
      headers: {
        Authorization: "token " + localStorage.getItem("dashboard_token"),
      },
      params: {
        user_id: localStorage.getItem("companyId"),
      },
      baseURL: process.env.REACT_APP_DASHBOARD_URL,
    })
      .then((res) => {
        localStorage.setItem('assets', JSON.stringify(res.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAssets();
  }, [])

  const getDiffer = () => {
    var tzOffset = moment().utcOffset() / 60;
    var utcLocal = new Date().getTimezoneOffset() / 60;
    var differ = -(tzOffset + utcLocal)
    return differ;
  }

  const handleExport = () => {
    fast_api_axios(
      {
        method: 'get',
        responseType: 'blob',
        url: '/api/v1/tracker/line/export',
        params: {
          'start': moment(dateFilter['start']).unix(),
          'end': moment(dateFilter['end']).unix(),
          'devices': filterDevices.length > 0 ? filterDevices.map(a => a.value).toString() : null,
          'cameras': filterStreams.length > 0 ? filterStreams.map(a => a.value).toString() : null,
          'lines': filterLines.length > 0 ? filterLines.map(a => a.value).toString() : null,
          'classes': filterClasses.length > 0 ? filterClasses.map(a => a.value).toString() : null,
          'directions': filterDirections.length > 0 ? filterDirections.map(a => a.value).toString() : null,
        }
      })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.csv')
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  return (
    <Container fluid>
      <Row className='d-flex'>
        <div className='heatmap-content-wide'>
          <div className='page-content-header d-flex justify-content-between align-items-center'>
            <p className='breadcrumb'>
              <span className='main-breadcrumb' onClick={() => history.push('/dashboards')}>Dashboards</span>
              <ChevronRight size={14} />
              <span className='inner-breadcrumb'>Counting</span>
              {/*<ChevronRight size={14} />
              <span className='main-breadcrumb' onClick={() => history.push('/dashboards/sample/counting')}>Sample Dashboard</span>*/}
            </p>
            <div className='d-flex'>
              <div className='filter-dropdown' ref={dropdownRef}>
                <Button className="light-purple-btn me-3" onClick={() => setShowFilterBox(!showFilterBox)}>
                  <Filter size={24} />
                  &nbsp;
                  Filter
                  &nbsp;
                  {filterDevices.length + filterStreams.length + filterClasses.length + filterLines.length + filterDirections.length > 0 ?
                    <span className='filter-selected-count'>{(filterDevices.length + filterStreams.length + filterClasses.length + filterLines.length + filterDirections.length)}</span>
                    :
                    null
                  }
                  &nbsp;
                  <ChevronDown size={16} />
                </Button>
                {showFilterBox ?
                  <div className='filters-box'>
                    <Accordion>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header className={filterDevices.length > 0 && 'selected-filters-name'}>
                          {filterDevices.length > 0 ? filterDevices.map(itm => itm.label).join(',') : 'Device'}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Select
                            isMulti
                            closeMenuOnSelect={false}
                            name="deviceOptions"
                            options={deviceOptions}
                            className="basic-multi-select mt-2"
                            classNamePrefix="select"
                            placeholder={<span><Search className="mb-1 me-1" /> Select Devices</span>}
                            onChange={(items) => handleDeviceSelect(items)}
                            value={filterDevices}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header className={filterStreams.length > 0 && 'selected-filters-name'}>
                          {filterStreams.length > 0 ? filterStreams.map(itm => itm.label).join(',') : 'Stream'}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Select
                            isMulti
                            closeMenuOnSelect={false}
                            name="streamOptions"
                            options={streamOptions}
                            className="basic-multi-select mt-2"
                            classNamePrefix="select"
                            placeholder={<span><Search className="mb-1 me-1" /> Select Streams</span>}
                            onChange={(items) => handleStreamSelect(items)}
                            value={filterStreams}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header className={filterClasses.length > 0 && 'selected-filters-name'}>
                          {filterClasses.length > 0 ? filterClasses.map(itm => itm.label).join(',') : 'Class'}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Select
                            isMulti
                            closeMenuOnSelect={false}
                            name="classOptions"
                            options={classOptions}
                            className="basic-multi-select mt-2"
                            classNamePrefix="select"
                            placeholder={<span><Search className="mb-1 me-1" /> Select Classes</span>}
                            onChange={(items) => handleClassSelect(items)}
                            value={filterClasses}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header className={filterLines.length > 0 && 'selected-filters-name'}>
                          {filterLines.length > 0 ? filterLines.map(itm => itm.label).join(',') : 'Lines'}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Select
                            isMulti
                            closeMenuOnSelect={false}
                            name="lineOptions"
                            options={lineOptions}
                            className="basic-multi-select mt-2"
                            classNamePrefix="select"
                            placeholder={<span><Search className="mb-1 me-1" /> Select Lines</span>}
                            onChange={(items) => handleLineSelect(items)}
                            value={filterLines}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                        <Accordion.Header className={filterDirections.length > 0 && 'selected-filters-name'}>
                          {filterDirections.length > 0 ? filterDirections.map(itm => itm.label).join(',') : 'Directions'}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Select
                            isMulti
                            closeMenuOnSelect={false}
                            name="directionOptions"
                            options={directionOptions}
                            className="basic-multi-select mt-2"
                            classNamePrefix="select"
                            placeholder={<span><Search className="mb-1 me-1" /> Select Directions</span>}
                            onChange={(items) => handleDirectionSelect(items)}
                            value={filterDirections}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <div className='d-flex justify-content-between align-items-center px-4 py-2'>
                      <div className={filterDevices.length + filterStreams.length + filterClasses.length + filterLines.length + filterDirections.length > 0 ? 'clear-filter-active' : 'clear-filter'} onClick={clearAllFilters}>Clear Filter</div>
                      <div className='d-flex justify-content-between'>
                        <Button className="cancel-filter me-3" onClick={() => setShowFilterBox(false)}>Cancel</Button>
                        <Button className="apply-filter-enabled"
                          disabled={!isApplyActive}
                          onClick={handleFilterApply}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </div>
                  :
                  null
                }
              </div>
              <RDateRangePicker
                editable={false}
                className='date-filter-button'
                placeholder="Default"
                placement="bottomEnd"
                disabledDate={afterToday()}
                format="MM/dd/yyyy HH:mm"
                value={[
                  new Date(moment(dateFilter['start']).format('MM/DD/YYYY HH:mm')),
                  new Date(moment(dateFilter['end']).format('MM/DD/YYYY HH:mm'))
                ]}
                cleanable={false}
                ranges={[
                  {
                    label: 'Current Time',
                    value: [
                      new Date(new Date(moment().startOf('day')) - getDiffer() * (60 * 60 * 1000)),
                      new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                    ],
                    closeOverlay: true
                  },
                  {
                    label: 'yesterday',
                    value: [
                      new Date(new Date(moment().subtract(1, 'days').startOf('day')) - getDiffer() * (60 * 60 * 1000)),
                      new Date(new Date(moment().subtract(1, 'days').endOf('day')) - getDiffer() * (60 * 60 * 1000))
                    ],
                    closeOverlay: true
                  },
                  {
                    label: 'Last Hour',
                    value: [
                      new Date(new Date(moment().subtract(1, 'hours')) - getDiffer() * (60 * 60 * 1000)),
                      new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                    ],
                    closeOverlay: true
                  },
                  {
                    label: 'Last 3 Days',
                    value: [
                      new Date(new Date(moment().subtract(3, 'days')) - getDiffer() * (60 * 60 * 1000)),
                      new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                    ],
                    closeOverlay: true
                  },
                  {
                    label: 'Last 15 Days',
                    value: [
                      new Date(new Date(moment().subtract(15, 'days')) - getDiffer() * (60 * 60 * 1000)),
                      new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                    ],
                    closeOverlay: true
                  },
                  {
                    label: 'Last 30 Days',
                    value: [
                      new Date(new Date(moment().subtract(30, 'days')) - getDiffer() * (60 * 60 * 1000)),
                      new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                    ],
                    closeOverlay: true
                  },
                ]}
                onChange={(e) => {
                  setDateFilter({
                    'start': moment(e[0]).add(getDiffer(), 'hours'),
                    'end': moment(e[1]).add(getDiffer(), 'hours')
                  })
                }}
              />
              <div className='counting-sum-box'>
                <p>Total Count</p>
                <div>{totalCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
              </div>
              {totalCount > 0 ? <Button className='light-purple-btn' onClick={handleExport}>Export</Button> : null}

            </div>
          </div>

          <div className='page-wrapper'>
            {totalCount > 0 || isSummaryLoading ?
              <>
                <div className='counting-slider-box'>
                  <SliderItem
                    isSummaryLoading={isSummaryLoading}
                    summaryData={summaryData}
                  />
                </div>

                <div className='d-flex w-100'>
                  <div className='pie-chart-box me-5'>
                    {isStreamBasedClassesBarLoading ?
                      <Loader />
                      :
                      <div className='w-100'>
                        <h5>Stream-Based Counting</h5>
                        <Chart
                          series={
                            [{
                              name: '',
                              data: streamBasedBarDataClasses
                            }]
                          }
                          type="bar"
                          height="220"
                          options={{
                            chart: {
                              toolbar: {
                                show: true,
                                export: {
                                  csv: {
                                    filename: 'Top-Streams-' + new Date().toISOString().slice(0, 10),
                                  },
                                },
                              },
                            },
                            plotOptions: {
                              bar: {
                                borderRadius: 4,
                                horizontal: true,
                                dataLabels: {
                                  position: 'top'
                                }
                              }
                            },
                            dataLabels: {
                              enabled: true,
                              offsetX: 25,
                              style: {
                                colors: ['#333']
                              },
                              formatter: function (value) {
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              }
                            },
                            xaxis: {
                              categories: streamBasedBarDataClassesLabel,
                              labels: {
                                formatter: function (value) {
                                  return Number.isInteger(value) ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                }
                              },
                            },
                            tooltip: {
                              y: {
                                formatter: function (val, opt) {
                                  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                }
                              },
                            },
                            colors: [
                              function ({ value, seriesIndex, dataPointIndex, w }) {
                                return pieColors[dataPointIndex];
                              }
                            ]
                          }}
                        />
                      </div>
                    }
                  </div>


                  <div className='pie-chart-box'>
                    {isClassesBarLoading ?
                      <Loader />
                      :
                      <div className='w-100'>
                        <h5>Top Classes</h5>
                        <Chart
                          series={
                            [{
                              name: '',
                              data: barDataClasses
                            }]
                          }
                          type="bar"
                          height="220"
                          options={{
                            chart: {
                              toolbar: {
                                show: true,
                              },
                            },
                            plotOptions: {
                              bar: {
                                borderRadius: 4,
                                horizontal: true,
                                dataLabels: {
                                  position: 'top'
                                }
                              }
                            },
                            dataLabels: {
                              enabled: true,
                              offsetX: 25,
                              style: {
                                colors: ['#333']
                              },
                              formatter: function (value) {
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              }
                            },
                            xaxis: {
                              categories: barDataClassesLabel,
                              labels: {
                                formatter: function (value) {
                                  return Number.isInteger(value) ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                }
                              },
                            },
                            tooltip: {
                              y: {
                                formatter: function (val, opt) {
                                  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                }
                              }
                            },
                            colors: [
                              function ({ value, seriesIndex, dataPointIndex, w }) {
                                return pieColors[dataPointIndex];
                              }
                            ]
                          }}
                        />
                      </div>
                    }
                  </div>
                </div>

                <div className='line-chart-box mt-3'>
                  <div className='d-flex justify-content-between'>
                    <h5>Class based Counting</h5>

                    {granularity.label === "Hourly" &&
                      <div className='d-flex align-items-center'>
                        {moment(hourlySelectedDay).format('MM/DD/YYYY') === moment(dateFilter['start']).format('MM/DD/YYYY') ?
                          <ChevronLeft className='chevron-disabled' size={20} />
                          :
                          <ChevronLeft className='chevron-enabled' size={20} onClick={() => handlePrevDay()} />
                        }

                        <div className='current-day mx-2'>
                          {hourlySelectedDay ? moment(hourlySelectedDay).format('MM/DD/YYYY') : moment.utc(dateFilter['start']).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY')}
                        </div>

                        {moment(hourlySelectedDay).format('MM/DD/YYYY') === moment(dateFilter['end']).format('MM/DD/YYYY') ?
                          <ChevronRight className='chevron-disabled' size={20} />
                          :
                          <ChevronRight className='chevron-enabled' size={20} onClick={() => handleNextDay()} />
                        }
                      </div>
                    }
                    <div className='d-flex me-3'>
                      <div
                        className={granularity.label === "Hourly" ? 'activeGranularity' : 'granularity'}
                        onClick={() => handleGranularitySelect({
                          'label': "Hourly",
                          'value': "hour",
                        })}
                      >
                        Hourly
                      </div>
                      <div>&nbsp;|&nbsp;</div>
                      <div
                        className={granularity.label === "Daily" ? 'activeGranularity' : 'granularity'}
                        onClick={() => handleGranularitySelect({
                          'label': "Daily",
                          'value': "day",
                        })}
                      >
                        Daily
                      </div>
                      <div>&nbsp;|&nbsp;</div>
                      <div
                        className={granularity.label === "Weekly" ? 'activeGranularity' : 'granularity'}
                        onClick={() => handleGranularitySelect({
                          'label': "Weekly",
                          'value': "week",
                        })}
                      >
                        Weekly
                      </div>
                    </div>
                  </div>

                  {isLineChartLoading ?
                    <Loader />
                    :
                    lineData.datasets.length === 0 ?
                      <div className="line-graph-not-found">
                        <div className="text-center">
                          <img src={NotFound} alt="not-found" className="not-found-img" />
                          <p className="not-found-text">Looks like no data found!</p>
                          <p>Please select another day.</p>
                        </div>
                      </div>
                      :
                      <Chart
                        options={{

                          chart: {
                            animations: {
                              enabled: false,
                            },
                            fontFamily: 'Lato, sans-serif',
                            type: 'line',
                            toolbar: {
                              autoSelected: 'zoom',
                              show: true,
                              tools: {
                                zoom: false,
                                zoomin: false,
                                zoomout: false,
                                pan: false,
                                selection: false,
                                reset: false,
                                download: true //!lineData.labels.every(item => item === 0) // <== line to add // <== line to add
                              },
                              export: {
                                csv: {
                                  filename: 'Alerts-Distribution' + new Date().toISOString().slice(0, 10),
                                },
                              },
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            curve: 'straight',
                            width: 3
                          },
                          grid: {
                            row: {
                              colors: ['transparent']
                            },
                            padding: {
                              top: 0,
                              right: 20,
                              bottom: 50,
                              left: 20
                            },
                          },

                          xaxis: {
                            categories: lineData.labels,
                            tooltip: {
                              formatter: function (value, opts) {
                                const v = opts.w.globals.categoryLabels[opts.dataPointIndex]
                                if (v === undefined || v === '') {
                                  return ''
                                } else if (opts.w.globals.categoryLabels[opts.dataPointIndex].length === 5) {
                                  return opts.w.globals.categoryLabels[opts.dataPointIndex]
                                } else {
                                  return v;
                                }
                              },
                            },
                            labels: {
                              rotate: -70,
                              style: {
                                fontSize: '11px',
                                fontWeight: 400,
                              },
                              rotateAlways: true,
                              formatter: function (value) {
                                var xaxisLabel = document.querySelectorAll('.apexcharts-xaxis-label tspan')
                                xaxisLabel.forEach((char, i) => {
                                  /*if (char.textContent.length === 5 && char.textContent !== '12:00') {
                                    document.querySelectorAll('.apexcharts-xaxis-label tspan')[i].classList.add('hidetime');
                                  }*/
                                })
                                return value;
                              }
                            }
                          },
                          tooltip: {
                            fixed: {
                              enabled: false
                            },
                            enabled: true,
                            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                              const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
                              const hoverIndexes = w.globals.seriesX.map(seriesX => {
                                return seriesX.findIndex(xData => xData === hoverXaxis);
                              });

                              let hoverList = '';
                              hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                                if (hoverIndex >= 0) {
                                  hoverList += `<span class="apexcharts-tooltip-marker circlemake" style="background-color: ${pieColors[seriesEachIndex % 13]};"></span><span>${w.globals.seriesNames[seriesEachIndex]}: ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                                }
                              });
                              const formatHoverX = chartDraw[dataPointIndex]

                              return `<div class="card">
                              <div class="card-header p-1">${formatHoverX}</div>
                              <div class="card-body p-1">
                               ${hoverList}
                              </div>
                            </div>`;
                            }
                          },
                          colors: pieColors,
                          legend: {
                            position: 'bottom',
                            horizontalAlign: 'center',
                            showForSingleSeries: true
                          },
                        }}
                        series={
                          lineData.datasets
                        }
                        height={350}
                        type="line"
                      />
                  }
                </div>
              </>
              :
              <NoDataBoard exampleRoute='/dashboards/sample/counting' />
            }

          </div>
        </div>
      </Row>
    </Container>
  )
}
