import React, { useEffect, useState, useRef } from 'react'
import { Table, Container, Row, Button, Accordion, OverlayTrigger } from 'react-bootstrap'
import { ChevronDown, ChevronRight, Search } from 'react-bootstrap-icons';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { fast_api_axios } from '../../utils/axiosFast';
import Loader from '../Loader';
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';
import Select from 'react-select';
import { DateRangePicker as RDateRangePicker } from 'rsuite';
import {
  findClassTitleByRuleId
} from '../../utils/generic';

const {
  afterToday,
  allowedMaxDays,
  combine
} = RDateRangePicker;

export default function TrendsTable() {
  const { updateMessage } = useToast()
  const deviceData = JSON.parse(localStorage.getItem('deviceData'))
  const streamData = JSON.parse(localStorage.getItem('streamData'))
  const zoneData = JSON.parse(localStorage.getItem('zoneData'))
  const eventData = JSON.parse(localStorage.getItem('eventData'))
  const allEventData = JSON.parse(localStorage.getItem('allEventData'))
  const [noData, setNodata] = useState(false);

  const [dateFilter, setDateFilter] = useState({
    'start': localStorage.getItem('filterObj') !== null ? moment(JSON.parse(localStorage.getItem('filterObj')).start) : moment().subtract(1, 'days').startOf('day'),
    'end': localStorage.getItem('filterObj') !== null ? moment(JSON.parse(localStorage.getItem('filterObj')).end) : moment().subtract(1, 'days').endOf('day')
  })
  const [filterDevices, setFilterDevices] = useState(localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).devices : []);
  const [filterStreams, setFilterStreams] = useState(localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).streams : []);
  const [filterZones, setFilterZones] = useState(localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).zones : []);
  const [filterEvents, setFilterEvents] = useState(localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).events : []);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [filterObj, setFilterObj] = useState({
    'start': localStorage.getItem('filterObj') !== null ? moment(JSON.parse(localStorage.getItem('filterObj')).start) : moment().subtract(1, 'days').startOf('day'),
    'end': localStorage.getItem('filterObj') !== null ? moment(JSON.parse(localStorage.getItem('filterObj')).end) : moment().subtract(1, 'days').endOf('day'),
    'devices': localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).devices : deviceData,
    'streams': localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).streams : [],
    'zones': localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).zones : [],
    'events': localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).events : [],
  });
  const filterCount = filterDevices.length + filterStreams.length + filterZones.length + filterEvents.length;

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let history = useHistory();
  const { logout } = useAuth();

  const goToAllComment = (item) => {
    history.push('/trends/comment/' + item.device_id + '/' + item.camera_id + '/' + item.rule_id);
  }

  const handleFilterApply = () => {
    let newFilterObj = {
      'start': dateFilter['start'],
      'end': dateFilter['end'],
      'devices': filterDevices,
      'streams': filterStreams,
      'zones': filterZones,
      'events': filterEvents,
    }

    localStorage.setItem('filterObj', JSON.stringify(newFilterObj));

    setFilterObj(newFilterObj);
    setShowFilterBox(false);
  }

  const getAlertsDetail = () => {
    let start = moment(dateFilter['start']).unix()
    let end = moment(dateFilter['end']).unix()
    let devices = filterDevices.map(device => device.value);
    let cameras = filterStreams.map(camera => camera.value);

    const allZoneData = [];
    JSON.parse(localStorage.getItem("assets")).forEach((device) => {
      device.cameras.forEach((camera) => {
        camera.zones.forEach((zone) => {
          if (allZoneData.find(o => o.value === zone.zone_id) === undefined) {
            allZoneData.push({
              value: zone.zone_id,
              label: zone.zone,
            });
          }
        });
      });
    });

    let selectedZoneIds = [];
    filterZones.forEach((item) => {
      var newSelectedZones = allZoneData.filter((ch) => ch.label === item.label);
      newSelectedZones.forEach((item) => {
        selectedZoneIds.push(item);
      })
    })

    let zones = selectedZoneIds.map(zone => zone.value);


    const allEventData = [];
    JSON.parse(localStorage.getItem("assets")).forEach((device) => {
      device.cameras.forEach((camera) => {
        camera.zones.forEach((zone) => {
          zone.rules.forEach((rule) => {
            if (!allEventData.some(e => e.value === rule.rule_id)) {
              allEventData.push(
                {
                  value: rule.rule_id,
                  label: rule.event,
                }
              )
            }
          });
        });
      });
    });

    let selectedEventIds = [];
    filterEvents.forEach((item) => {
      var newSelectedEvents = allEventData.filter((ch) => ch.label === item.label);
      newSelectedEvents.forEach((item) => {
        selectedEventIds.push(item);
      })
    })

    let rules = selectedEventIds.map(rule => rule.value);


    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/detail/',
        params: {
          "start": start,
          "end": end,
          "devices": devices.length > 0 ? devices.toString() : null,
          "cameras": cameras.length > 0 ? cameras.toString() : null,
          "zones": zones.length > 0 ? zones.toString() : null,
          "rules": rules.length > 0 ? rules.toString() : null,
        }
      })
      .then(res => {
        if (res.data.length === 0) {
          setNodata(true);
        }

        let myAlerts = res.data.filter(function (element) {
          return allEventData.find(x => x.value === element.rule_id) && element
        });
        myAlerts.length > 0 ? setNodata(false) : setNodata(true);

        setTableData(myAlerts)
        setIsLoading(false)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  useEffect(() => {
    let newFilterObj = {
      'start': dateFilter['start'],
      'end': dateFilter['end'],
      'devices': filterDevices,
      'streams': filterStreams,
      'zones': filterZones,
      'events': filterEvents,
    }

    localStorage.setItem('filterObj', JSON.stringify(newFilterObj));
    setFilterObj(newFilterObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter]);

  useEffect(() => {
    setIsLoading(true);
    getAlertsDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterObj]);

  const handleExport = () => {
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/detail/export',
        params: {
          "start": moment(dateFilter['start']).unix(),
          "end": moment(dateFilter['end']).unix(),
          "devices": filterDevices.length > 0 ? filterDevices.map(item => item.value) : null,
          "cameras": filterStreams.length > 0 ? filterStreams.map(item => item.value) : null,
          "zones": filterZones.length > 0 ? filterZones.map(item => item.value) : null,
          "rules": filterEvents.length > 0 ? filterEvents.map(item => item.value) : null,
        }
      })
      .then(response => {
        const d = new Date()
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Trends-Details-' + d.toISOString().slice(0, 10) + '.csv')
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const handleDeviceSelect = (items) => {
    setFilterDevices(items);
  };
  const handleStreamSelect = (items) => {
    setFilterStreams(items);
  };
  const handleZoneSelect = (items) => {
    setFilterZones(items);
  };
  const handleEventSelect = (items) => {
    setFilterEvents(items);
  };

  const clearAllFilters = () => {
    setFilterDevices([]);
    setFilterStreams([]);
    setFilterZones([]);
    setFilterEvents([]);
    setDateFilter({
      'start': moment().subtract(7, 'days'),
      'end': moment()
    });
    setShowFilterBox(false);
    localStorage.removeItem('filterObj');
  }

  const dropdownRef = useRef();
  const closeOpenMenus = (e) => {
    if (dropdownRef.current && showFilterBox && !dropdownRef.current.contains(e.target)) {
      setShowFilterBox(false)
    }
  }
  document.addEventListener('mousedown', closeOpenMenus)

  const filterTrigger = () => {
    var trigger;
    filterCount === filterDevices.length + filterStreams.length + filterZones.length + filterEvents.length ? trigger = true : trigger = false
    return trigger;
  }

  return (
    <>
      <Container fluid>
        <Row className="d-flex">
          <div className='trends-content-wide'>
            <div className='page-content-header'>
              <p className='breadcrumb'>
                <span className='main-breadcrumb' onClick={() => history.push('/trends')}>Alert Trends</span>
                <ChevronRight size={14} />
                <span className='inner-breadcrumb'>Alert Details</span>
              </p>
            </div>

            <div className='page-wrapper'>
              <div className='table-box px-4 mt-3'>
                <div className='d-flex justify-content-between align-items-center mb-3'>
                  <h5>Alert Details</h5>
                  {noData ?
                    <h5 className="export-table disabled">
                      Export CSV
                    </h5>
                    :
                    <h5
                      className="export-table"
                      onClick={() => handleExport()}
                    >
                      Export CSV
                    </h5>
                  }
                </div>
                {isLoading ?
                  <Loader />
                  :
                  <Table>
                    <thead>
                      <tr>
                        <th>ALERTS</th>
                        <th>DEVICE</th>
                        <th>STREAM</th>
                        <th>ZONE</th>
                        <th>REVIEWED</th>
                        <th>REVIEWED %</th>
                        {/*<th>COMMENT</th>*/}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((item, index) => {
                        return <tr key={index}>
                          <td className='applications'>{allEventData.find(x => x.value === item.rule_id) ? allEventData.find(x => x.value === item.rule_id).label+ ' - ' + findClassTitleByRuleId(item?.rule_id) : '-'}</td>
                          <td>{deviceData.find(x => x.value === item.device_id) ? deviceData.find(x => x.value === item.device_id).label : '-'}</td>
                          <td>{streamData.find(x => x.value === item.camera_id) ? streamData.find(x => x.value === item.camera_id).label : '-'}</td>
                          <td>{item.zone_ids.length > 0 ? zoneData.find(x => x.value === item.zone_ids[0]) ? zoneData.find(x => x.value === item.zone_ids[0]).label : '-' : '-'}</td>
                          <td className={item.reviewed_amount === 0 ? 'none-reviewed' : item.reviewed_amount === item.total_amount ? 'all-reviewed' : 'some-reviewed'}>{item.reviewed_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/{item.total_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                          <td className="percentage-col">{item.percentage}%</td>
                          {/*<td>
                            {item.reviewed_amount === 0 ?
                              <span className='comment comment-disabled'>View Comment</span>
                              :
                              <span className='comment' onClick={() => goToAllComment(item)}>View Comment</span>
                            }
                          </td>*/}
                        </tr>
                      })
                      }
                    </tbody>
                  </Table>
                }
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  )
}
