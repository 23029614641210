import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Row,
  Button,
  Accordion,
  Dropdown, Popover, OverlayTrigger
} from 'react-bootstrap';
import {  Star, Search, ChevronRight, ExclamationCircleFill, Filter, GridFill, ListTask, SortDown } from 'react-bootstrap-icons';
import Alert from './Alert';
import Frames from './Frames';
import ModalCreatePreset from "./ModalCreatePreset";
import ModalDeletePreset from './ModalDeletePreset';
import moment from 'moment';
import { fast_api_axios, dashboard_axios } from '../../utils/axiosFast';
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';
import { DateRangePicker as RDateRangePicker } from 'rsuite';
import Select from "react-select";
import Loader from '../Loader';
import trash from '../../assets/images/trash.svg';
import momentTimezone from 'moment-timezone';
import { useHistory } from "react-router-dom";
import AlertListView from './AlertListView';
import RefreshIcon
  from '../../assets/images/refresh-current-time.svg';

const {
  afterToday,
  allowedMaxDays,
  combine
} = RDateRangePicker;


export default function Content(props) {
  momentTimezone.tz.setDefault(localStorage.getItem('timezone'));
  let history = useHistory();
  const { logout } = useAuth();
  const [isDateApplied, setIsDateApplied] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    'start': localStorage.getItem('filterObj') !== null ? moment(JSON.parse(localStorage.getItem('filterObj')).start) : moment().startOf('day'),
    'end': localStorage.getItem('filterObj') !== null ? moment(JSON.parse(localStorage.getItem('filterObj')).end) : moment()
  })
  const [filterDevices, setFilterDevices] = useState(localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).devices : []);
  const [filterStreams, setFilterStreams] = useState(localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).streams : []);
  const [filterZones, setFilterZones] = useState(localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).zones : []);
  const [filterEvents, setFilterEvents] = useState(localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).events : []);
  const [filterAlerts, setFilterAlerts] = useState({});
  const [alertDataType, setAlertDataType] = useState(null);
  const [sortType, setSortType] = useState('reviewed');

  const [filterObj, setFilterObj] = useState({
    'start': localStorage.getItem('filterObj') !== null ? moment(JSON.parse(localStorage.getItem('filterObj')).start) : moment().startOf('day'),
    'end': localStorage.getItem('filterObj') !== null ? moment(JSON.parse(localStorage.getItem('filterObj')).end) : moment(),
    'devices': localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).devices : [],
    'streams': localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).streams : [],
    'zones': localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).zones : [],
    'events': localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).events : [],
    'alertType': alertDataType,
  });

  const [filterPreset, setFilterPreset] = useState(null);
  const [showDeletePresetModal, setShowDeletePresetModal] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [showAll, setShowAll] = useState(localStorage.getItem('activeAlertPage') ? localStorage.getItem('activeAlertPage') === 'all' ? true : false : false);
  const [showReviewed, setShowReviewed] = useState(localStorage.getItem('isActiveAlertPageReviewed') ? localStorage.getItem('isActiveAlertPageReviewed') === 'true' ? true : false : false);
  const { updateMessage } = useToast();
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApplyActive, setIsApplyActive] = useState(false);
  const [isApplyActiveAgain, setIsApplyActiveAgain] = useState(false);

  const [deviceOptions, setDeviceOptions] = useState([]);
  const [streamOptions, setStreamOptions] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [eventOptions, setEventOptions] = useState([]);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [showCreatePresetModal, setShowCreatePresetModal] = useState(false);
  const [presetOptions, setPresetOptions] = useState([]);

  const [isGridActive, setIsGridActive] = useState(localStorage.getItem("isGrid") !== null ? localStorage.getItem("isGrid") === 'true' : true);
  const [showSortBtn, setShowSortBtn] = useState(true);

  const handleCloseDeletePresetModal = () => setShowDeletePresetModal(false);
  const handleShowDeletePresetModal = (preset) => {
    setFilterPreset(preset);
    setShowDeletePresetModal(true);
  }
  const handleCloseCreatePresetModal = () => setShowCreatePresetModal(false);
  const handleShowCreatePresetModal = () => setShowCreatePresetModal(true);

  const getAssets = () => {
    dashboard_axios({
      method: "get",
      url: "edge/assets",
      headers: {
        Authorization: "token " + localStorage.getItem("dashboard_token"),
      },
      params: {
        user_id: localStorage.getItem("dashboardId"),
      },
      baseURL: process.env.REACT_APP_DASHBOARD_URL,
    }).then((res) => {
      localStorage.setItem("assets", JSON.stringify(res.data));

      const deviceData = res.data.map((item) => {
        return {
          value: item.device_id,
          label: item.device_name,
        };
      });

      const streamData = [];
      res.data.forEach((device) => {
        device.cameras.forEach((camera) => {
          streamData.push({
            value: camera.camera_id,
            label: camera.camera_name,
          });
        });
      });

      const zoneData = [];
      res.data.forEach((device) => {
        device.cameras.forEach((camera) => {
          camera.zones.forEach((zone) => {
            if (zoneData.find(o => o.value === zone.zone_id) === undefined) {
              /*if (!zoneData.some(e => e.label === zone.zone)) {
                zoneData.push({
                  value: zone.zone_id,
                  label: zone.zone,
                });
              }*/
              zoneData.push({
                value: zone.zone_id,
                label: zone.zone,
              });
            }
          });
        });
      });

      const eventData = [];
      const allEventData = [];
      res.data.forEach((device) => {
        device.cameras.forEach((camera) => {
          camera.zones.forEach((zone) => {
            zone.rules.forEach((rule) => {
              allEventData.push({
                value: rule.rule_id,
                label: rule.event,
              })
              if (!eventData.some(e => e.label === rule.event)) {
                eventData.push({
                  value: rule.rule_id,
                  label: rule.event,
                });
              }
            });
          });
        });
      });

      localStorage.setItem("deviceData", JSON.stringify(deviceData));
      localStorage.setItem("streamData", JSON.stringify(streamData));
      localStorage.setItem("zoneData", JSON.stringify(zoneData));
      localStorage.setItem("eventData", JSON.stringify(eventData));

      localStorage.setItem("allEventData", JSON.stringify(allEventData));

      setDeviceOptions(deviceData);
      setStreamOptions(streamData);
      setZoneOptions(zoneData);
      setEventOptions(eventData);
    })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeviceSelect = (items) => {
    setFilterDevices(items);
  };
  const handleStreamSelect = (items) => {
    setFilterStreams(items);
  };
  const handleZoneSelect = (items) => {
    setFilterZones(items);
  };
  const handleEventSelect = (items) => {
    setFilterEvents(items);
  };

  const handleAlertSelect = (items) => {
    localStorage.setItem("alertData", JSON.stringify([
      items
    ]));
    if (items && items.value) {
      setFilterAlerts(items);
      setAlertDataType(items.value);
    } else {

      setAlertDataType(null);
    }
  };

  const handleShowAll = (isShowReviewed) => {
    localStorage.setItem('activeAlertPage', 'all');
    localStorage.setItem('isActiveAlertPageReviewed', isShowReviewed)
    setShowAll(!showAll);
    setShowReviewed(isShowReviewed);
  }

  const handleShowBase = () => {
    localStorage.setItem('activeAlertPage', 'base');
    setShowAll(!showAll);
    window.location.reload();
  }

  const handlePresetSelect = (preset) => {
    setSelectedPreset({ value: preset.id, label: preset.name });
    fast_api_axios({
      method: "get",
      url: "/api/v1/presets/" + preset.id,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("fast_api_token"),
      }
    })
      .then((res) => {
        setShowFilterBox(false);
        setFilterDevices(
          deviceOptions.filter((item) =>
            res.data.filters.devices.find((device) => item.value === device)
          )
        );

        setFilterStreams(
          streamOptions.filter((item) =>
            res.data.filters.cameras.find((camera) => item.value === camera)
          )
        );
        let myZoneFilterData = [];
        res.data.filters.zones.filter((zone) =>
          myZoneFilterData.push(zoneOptions.find((item) => item.value === zone))
        )
        setFilterZones(myZoneFilterData)

        setFilterEvents(
          eventOptions.filter((item) =>
            res.data.filters.rules.find((rule) => item.value === rule)
          )
        );
        handleFilterApply();
      })
      .catch((error) => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  };

  const handleFilterApply = () => {
    setIsDateApplied(true);
    setAlertDataType(filterAlerts.value);

    let newFilterObj = {
      'start': dateFilter['start'],
      'end': dateFilter['end'],
      'devices': filterDevices,
      'streams': filterStreams,
      'zones': filterZones,
      'events': filterEvents,
      'alertType': alertDataType,
    }

    localStorage.setItem('filterObj', JSON.stringify(newFilterObj));
    setIsApplyActive(false);
    setIsApplyActiveAgain(false);
    setFilterObj(newFilterObj);
    setShowFilterBox(false);
  }

  const clearAllFilters = () => {
    setFilterDevices([]);
    setFilterStreams([]);
    setFilterZones([]);
    setFilterEvents([]);
    setFilterPreset(null);
    setSelectedPreset(null);
    setDateFilter({
      'start': moment.tz(localStorage.getItem('timezone')).startOf('day'),
      'end': moment.tz(localStorage.getItem('timezone'))
    });
    setShowFilterBox(false);
    setFilterAlerts({});
    setAlertDataType(null);
    setIsApplyActiveAgain(false);
    localStorage.setItem('ispresetapplied', 'false')
    localStorage.removeItem('filterObj');
  }

  useEffect(() => {
    let newFilterObj = {
      'start': dateFilter['start'],
      'end': dateFilter['end'],
      'devices': filterDevices,
      'streams': filterStreams,
      'zones': filterZones,
      'events': filterEvents,
      'alertType': alertDataType,
    }

    localStorage.setItem('filterObj', JSON.stringify(newFilterObj));
    setFilterObj(newFilterObj);
    setFilterPreset(localStorage.getItem('ispresetapplied') !== null && localStorage.getItem('ispresetapplied') !== 'false' ? JSON.parse(localStorage.getItem('ispresetapplied')) : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter]);

  useEffect(() => {
    getAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let localFilter = JSON.parse(localStorage.getItem('filterObj'))

    if (
      JSON.stringify(localFilter.devices) === JSON.stringify(filterDevices) &&
      JSON.stringify(localFilter.streams) === JSON.stringify(filterStreams) &&
      JSON.stringify(localFilter.zones) === JSON.stringify(filterZones) &&
      JSON.stringify(localFilter.events) === JSON.stringify(filterEvents) &&
      JSON.stringify(localFilter.alertType) === JSON.stringify(alertDataType) &&
      !filterAlerts.value) {
      setIsApplyActive(false);
    }
    else {
      if ((filterDevices.length + filterStreams.length + filterZones.length + filterEvents.length === 0) && alertDataType === null) {
        setIsApplyActive(false);
      }
      else {
        setIsApplyActive(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDevices, filterStreams, filterZones, filterEvents, filterAlerts])

  const handleRefresh = () => {
    setShouldRefresh(!shouldRefresh)
  }

  const getLatest = () => {
    setIsLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/latest_entry/',
        params: {
          "application": 0
        }
      })
      .then(res => {
        setIsDateApplied(true);
        if (res.data !== null) {
          setDateFilter({
            'start': moment(res.data.alert_date + '+00:00').tz(localStorage.getItem('timezone')).subtract(7, 'days'),
            'end': moment(res.data.alert_date + '+00:00').tz(localStorage.getItem('timezone'))
          });
        }
        else {
          setDateFilter({
            'start': moment().tz(localStorage.getItem('timezone')).startOf('day'),
            'end': moment().tz(localStorage.getItem('timezone'))
          });
        }
        setIsLoading(false);
      })
      .catch(error => {
        if (error.response?.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const alreadyFound = () => {
    setIsDateApplied(true);
  }

  const dropdownRef = useRef();
  const closeOpenMenus = (e) => {
    if (dropdownRef.current && showFilterBox && !dropdownRef.current.contains(e.target)) {
      setShowFilterBox(false)
    }
  }
  document.addEventListener('mousedown', closeOpenMenus)

  const getNewPresetData = () => {
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/presets/',
        headers: {
          Authorization: "Bearer " + localStorage.getItem("fast_api_token"),
        },
      })
      .then(res => {
        setPresetOptions(res.data.results)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getNewPresetDataAndApply = (id, name) => {
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/presets/',
        headers: {
          Authorization: "Bearer " + localStorage.getItem("fast_api_token"),
        },
      })
      .then(res => {
        setPresetOptions(res.data.results)
        handlePresetSelect({ "id": id, "name": name })
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  useEffect(() => {
    getNewPresetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDiffer = () => {
    var tzOffset = moment().utcOffset() / 60;
    var utcLocal = new Date().getTimezoneOffset() / 60;
    var differ = -(tzOffset + utcLocal)
    return differ;
  }

  const changeViewOption = (type) => {
    if(type === 'grid'){
      setIsGridActive(true)
      localStorage.setItem("isGrid", true)
    }
    else{
      setIsGridActive(false)
      localStorage.setItem("isGrid", false)
    }
  }

  const changeSortView = (shouldShow) => {
    shouldShow ? setShowSortBtn(true) : setShowSortBtn(false);
  }

  const getCurrentTimeAlerts = () => {
    setDateFilter({
      'start': moment.tz(localStorage.getItem('timezone')).startOf('day'),
      'end': moment.tz(localStorage.getItem('timezone'))
    });
  }
  return (
    <>
      <Container fluid>
        <Row className='d-flex'>
          <div className='alerts-content-wide'>
            <div className='page-content-header'>
              {showAll ?
                showReviewed ?
                  <p className='breadcrumb'>
                    <span className='main-breadcrumb' onClick={() => handleShowBase()}>Alerts</span>
                    <ChevronRight size={14} />
                    <span className='inner-breadcrumb'>Reviewed Detection Frames</span>
                  </p>
                  :
                  <p className='breadcrumb'>
                    <span className='main-breadcrumb' onClick={() => handleShowBase()}>Alerts</span>
                    <ChevronRight size={14} />
                    <span className='inner-breadcrumb'>Unreviewed Detection Frames</span>
                  </p>
                :
                <>
                  <div className='d-flex justify-content-between align-items-center alerts-header'>
                    <div className='page-name'>Alerts</div>
                    <div className='d-flex'>
                      <div className='filter-dropdown' ref={dropdownRef}>
                        <Button className="light-purple-btn me-3" onClick={() => setShowFilterBox(!showFilterBox)}>
                          <Filter size={24} />
                          &nbsp;
                          Filter
                          &nbsp;
                          {(filterDevices.length + filterStreams.length + filterZones.length + filterEvents.length > 0) || alertDataType !== null ?
                            <span className='filter-selected-count'>
                              {
                                (filterDevices.length + filterStreams.length + filterZones.length + filterEvents.length)
                              }
                            </span>
                            :
                            null
                          }
                        </Button>
                        {showFilterBox ?
                          <div className='filters-box'>
                            <Accordion>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header className={selectedPreset !== null && 'selected-filters-name'}>
                                  {selectedPreset !== null ?
                                    selectedPreset.label
                                    :
                                    'Saved Filters'
                                  }

                                </Accordion.Header>
                                <Accordion.Body className='preset-body'>
                                  {presetOptions.length > 0 ?
                                    presetOptions.map((preset, index) => {
                                      return (
                                        <div className='preset-option' key={index}>
                                          <div onClick={() => handlePresetSelect(preset)}>{preset.name}</div>
                                          <img className='trashicon' onClick={() => handleShowDeletePresetModal(preset)} src={trash} alt="trash" />
                                        </div>
                                      )
                                    })
                                    :
                                    <div className='d-flex align-items-center p-3'>
                                      <ExclamationCircleFill size={20} color="#8020CF" />
                                      &nbsp; There is no saved filter yet. First click ‘Save New Filter’ on ‘Alerts Page’ and see your<br></br>  &nbsp; saved filters.
                                    </div>
                                  }
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="1">
                                <Accordion.Header className={filterDevices.length > 0 && 'selected-filters-name'}>
                                  {filterDevices.length > 0 ? filterDevices.map(itm => itm.label).join(',') : 'Device'}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    name="deviceOptions"
                                    options={deviceOptions}
                                    className="basic-multi-select mt-2"
                                    classNamePrefix="select"
                                    placeholder={<span><Search className="mb-1 me-1" /> Select Devices</span>}
                                    onChange={(items) => handleDeviceSelect(items)}
                                    value={filterDevices}
                                  />
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="2">
                                <Accordion.Header className={filterStreams.length > 0 && 'selected-filters-name'}>
                                  {filterStreams.length > 0 ? filterStreams.map(itm => itm.label).join(',') : 'Stream'}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    name="streamOptions"
                                    options={streamOptions}
                                    className="basic-multi-select mt-2"
                                    classNamePrefix="select"
                                    placeholder={<span><Search className="mb-1 me-1" /> Select Streams</span>}
                                    onChange={(items) => handleStreamSelect(items)}
                                    value={filterStreams}
                                  />
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="3">
                                <Accordion.Header className={filterZones.length > 0 && 'selected-filters-name'}>
                                  {filterZones.length > 0 ? filterZones.map(itm => itm.label).join(',') : 'Zone'}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    name="zoneOptions"
                                    options={zoneOptions}
                                    className="basic-multi-select mt-2"
                                    classNamePrefix="select"
                                    placeholder={<span><Search className="mb-1 me-1" /> Select Zones</span>}
                                    onChange={(items) => handleZoneSelect(items)}
                                    value={filterZones}
                                  />
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="5">
                                <Accordion.Header className={filterAlerts.label && 'selected-filters-name'}>
                                  {filterAlerts.label || 'Model Types'}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Select
                                    closeMenuOnSelect={false}
                                    isSearchable={false}
                                    isClearable={true}
                                    name="alertOptions"
                                    options={[
                                      { value: '0', label: 'Object Detection Models' },
                                      { value: '1', label: 'ImageChat Models' },
                                    ]}
                                    className="basic-single mt-2"
                                    classNamePrefix="select"
                                    placeholder={<span><Search className="mb-1 me-1" /> Model Alerts</span>}
                                    onChange={(items) => handleAlertSelect(items)}
                                    value={filterAlerts.value}
                                  />
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="4">
                                <Accordion.Header className={filterEvents.length > 0 && 'selected-filters-name'}>
                                  {filterEvents.length > 0 ? filterEvents.map(itm => itm.label).join(',') : 'Events'}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    name="eventOptions"
                                    options={eventOptions}
                                    className="basic-multi-select mt-2"
                                    classNamePrefix="select"
                                    placeholder={<span><Search className="mb-1 me-1" /> Select Events</span>}
                                    onChange={(items) => handleEventSelect(items)}
                                    value={filterEvents}
                                  />
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                            <div className='d-flex justify-content-between align-items-center px-4 py-2'>
                              <div className={(filterDevices.length + filterStreams.length + filterZones.length + filterEvents.length > 0) || alertDataType !== null ? 'clear-filter-active' : 'clear-filter'} onClick={clearAllFilters}>Clear Filter</div>
                              <div className='d-flex justify-content-between'>
                                <Button className="cancel-filter me-3" onClick={() => setShowFilterBox(false)}>Cancel</Button>
                                <Button className={(isApplyActive || isApplyActiveAgain) ? "apply-filter-enabled" : "apply-filter-disabled"}
                                  disabled={!(isApplyActive || isApplyActiveAgain)}
                                  onClick={handleFilterApply}>Apply</Button>
                              </div>
                            </div>
                          </div>
                          :
                          null
                        }
                      </div>
                      <RDateRangePicker
                        placeholder="Default"
                        editable={false}
                        className="date-filter-button me-3"
                        placement="bottomEnd"
                        disabledDate={combine(allowedMaxDays(90), afterToday())}
                        format="MM/dd/yyyy HH:mm"
                        value={[
                          new Date(moment(dateFilter['start']).format('MM/DD/YYYY HH:mm')),
                          new Date(moment(dateFilter['end']).format('MM/DD/YYYY HH:mm'))
                        ]}
                        ranges={[
                          {
                            label: 'Current Time',
                            value: [
                              new Date(new Date(moment().startOf('day')) - getDiffer() * (60 * 60 * 1000)),
                              new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                            ],
                            closeOverlay: true
                          },
                          {
                            label: 'yesterday',
                            value: [
                              new Date(new Date(moment().subtract(1, 'days').startOf('day')) - getDiffer() * (60 * 60 * 1000)),
                              new Date(new Date(moment().subtract(1, 'days').endOf('day')) - getDiffer() * (60 * 60 * 1000))
                            ],
                            closeOverlay: true
                          },
                          {
                            label: 'Last Hour',
                            value: [
                              new Date(new Date(moment().subtract(1, 'hours')) - getDiffer() * (60 * 60 * 1000)),
                              new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                            ],
                            closeOverlay: true
                          },
                          {
                            label: 'Last 3 Days',
                            value: [
                              new Date(new Date(moment().subtract(3, 'days')) - getDiffer() * (60 * 60 * 1000)),
                              new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                            ],
                            closeOverlay: true
                          },
                          {
                            label: 'Last 15 Days',
                            value: [
                              new Date(new Date(moment().subtract(15, 'days')) - getDiffer() * (60 * 60 * 1000)),
                              new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                            ],
                            closeOverlay: true
                          },
                          {
                            label: 'Last 30 Days',
                            value: [
                              new Date(new Date(moment().subtract(30, 'days')) - getDiffer() * (60 * 60 * 1000)),
                              new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                            ],
                            closeOverlay: true
                          },
                        ]}
                        cleanable={false}
                        onChange={(e) => {
                          setDateFilter({
                            'start': moment(e[0]).add(getDiffer(), 'hours'),
                            'end': moment(e[1]).add(getDiffer(), 'hours')
                          })
                        }}
                      />

                      <Button className="bordered-transparent-btn me-3" onClick={handleShowCreatePresetModal}><Star size={14} />&nbsp;Save New Filter</Button>
                      <OverlayTrigger
                        trigger={['hover']}
                        placement="bottom"
                        overlay={
                          <Popover id="popover-basic">
                            <Popover.Body>Get the Latest Data</Popover.Body>
                          </Popover>
                        }
                      >
                        <Button className="bordered-transparent-btn me-3" onClick={getCurrentTimeAlerts}>
                          <img src={RefreshIcon} alt="refresh-icon"/>
                        </Button>
                      </OverlayTrigger>
                      {/*<Button className="blue-btn me-3"><PlusLg size={18} /> Create Report</Button>*/}
                      {/*currentUser.name === 'cagan@chooch.com' ? null : <Button disabled={noDataState} className="bordered-transparent-btn" onClick={() => handleExport()}><Download size={18} />&nbsp;Export CSV</Button>*/}

                    </div>
                  </div>
                </>
              }
            </div>

            <div className='page-wrapper'>
              {showAll ?
                showReviewed ?
                  <>
                    <Frames
                      {...props}
                      dateFilter={dateFilter}
                      filterDevices={filterDevices}
                      filterStreams={filterStreams}
                      filterZones={filterZones}
                      filterEvents={filterEvents}
                      alertType={alertDataType}
                      isReviewed={true}
                      handleRefresh={handleRefresh}
                      shouldRefresh={shouldRefresh}
                      handleShowAll={handleShowAll}
                      showAll={showAll}
                      getLatest={getLatest}
                      isDateApplied={isDateApplied}
                      alreadyFound={alreadyFound}
                      filterObj={filterObj}
                    />
                  </>
                  :
                  <>
                    <Frames
                      {...props}
                      dateFilter={dateFilter}
                      filterDevices={filterDevices}
                      filterStreams={filterStreams}
                      filterZones={filterZones}
                      filterEvents={filterEvents}
                      alertType={alertDataType}
                      isReviewed={false}
                      handleRefresh={handleRefresh}
                      shouldRefresh={shouldRefresh}
                      handleShowAll={handleShowAll}
                      showAll={showAll}
                      getLatest={getLatest}
                      isDateApplied={isDateApplied}
                      alreadyFound={alreadyFound}
                      filterObj={filterObj}
                    />
                  </>
                :
                <>
                  {isLoading ?
                    <Loader />
                    :
                    <>
                      <Alert
                        {...props}
                        dateFilter={dateFilter}
                        filterDevices={filterDevices}
                        filterStreams={filterStreams}
                        filterZones={filterZones}
                        filterEvents={filterEvents}
                        alertType={alertDataType}
                        isReviewed={false}
                        isDateApplied={isDateApplied}
                        filterObj={filterObj}
                        isClickable={true}
                        noDataText="No data within the selected dates."
                      />

                      <div className='view-options-header'>
                        <h5 className='all-alerts-header'>All Alerts</h5>
                        {!isGridActive && showSortBtn ?
                          <Dropdown className='sort-dropdown'>
                            <Dropdown.Toggle>
                              <SortDown size={24} />
                              &nbsp;
                              Sort
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => setSortType('descending')} active={sortType === 'descending'}>Newest to Oldest</Dropdown.Item>
                              <Dropdown.Item onClick={() => setSortType('ascending')} active={sortType === 'ascending'}>Oldest to Newest</Dropdown.Item>
                              <Dropdown.Item onClick={() => setSortType('reviewed')} active={sortType === 'reviewed'}>Reviewed to Unreviewed</Dropdown.Item>
                              <Dropdown.Item onClick={() => setSortType('not_reviewed')} active={sortType === 'not_reviewed'}>Unreviewed to Reviewed</Dropdown.Item>
                              <Dropdown.Item onClick={() => setSortType('not_correct')} active={sortType === 'not_correct'}>False to True Alerts</Dropdown.Item>
                              <Dropdown.Item onClick={() => setSortType('correct')} active={sortType === 'correct'}>True to False Alerts</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          :
                          null
                        }

                        <div className='view-options'>
                          <div className={`view-option ${isGridActive && 'active'}`} onClick={() => changeViewOption('grid')}>
                            <GridFill />
                          </div>
                          <div className={`view-option ${!isGridActive && 'active'}`} onClick={() => changeViewOption('list')}>
                            <ListTask />
                          </div>
                        </div>
                      </div>

                      {isGridActive ?
                        <>
                          <Frames
                            {...props}
                            dateFilter={dateFilter}
                            filterDevices={filterDevices}
                            filterStreams={filterStreams}
                            filterZones={filterZones}
                            filterEvents={filterEvents}
                            alertType={alertDataType}
                            isReviewed={false}
                            handleRefresh={handleRefresh}
                            shouldRefresh={shouldRefresh}
                            handleShowAll={handleShowAll}
                            showAll={showAll}
                            getLatest={getLatest}
                            isDateApplied={isDateApplied}
                            alreadyFound={alreadyFound}
                            filterObj={filterObj}
                          />
                          <Frames
                            {...props}
                            dateFilter={dateFilter}
                            filterDevices={filterDevices}
                            filterStreams={filterStreams}
                            filterZones={filterZones}
                            filterEvents={filterEvents}
                            alertType={alertDataType}
                            isReviewed={true}
                            handleRefresh={handleRefresh}
                            shouldRefresh={shouldRefresh}
                            handleShowAll={handleShowAll}
                            showAll={showAll}
                            getLatest={getLatest}
                            isDateApplied={isDateApplied}
                            alreadyFound={alreadyFound}
                            filterObj={filterObj}
                          />
                        </>
                        :
                        <AlertListView
                          {...props}
                          dateFilter={dateFilter}
                          filterDevices={filterDevices}
                          filterStreams={filterStreams}
                          filterZones={filterZones}
                          filterEvents={filterEvents}
                          alertType={alertDataType}
                          filterObj={filterObj}
                          sortType={sortType}
                          changeSortView={changeSortView}
                        />
                      }

                    </>
                  }
                </>
              }
            </div>
          </div>
        </Row>
      </Container>

      <ModalDeletePreset presetName={filterPreset ? filterPreset.name : null} preset={filterPreset} show={showDeletePresetModal} handleClose={handleCloseDeletePresetModal} />
      <ModalCreatePreset show={showCreatePresetModal} handleClose={handleCloseCreatePresetModal} getNewPresetDataAndApply={getNewPresetDataAndApply} />
    </>
  );
}
