import React from 'react'
import { PlayCircleFill, FileImageFill } from 'react-bootstrap-icons';
import ImageAlert from '../../assets/images/image-purple.svg';

export default function FrameType(props) {
  return (
    <>
      {
        props.item.alert_type === 1 || props.item.alert_type === 2 ?
          (
            <div className='play-btn'>
              <img src={ImageAlert} alt={props.item.alert_uuid} className={'alert-icon'} />
            </div >
          ):
          (
            <div className='play-btn'>< PlayCircleFill alt={props.item.alert_uuid} color={'#18B099'} className={'alert-icon'}/></div >
          )
      }
    </>
  )
}
