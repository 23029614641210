const getDevices = () => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  let deviceData = assets.map((item) => {
    return {
      value: item.device_id,
      label: item.device_name,
    };
  });

  return deviceData;
}

const getStreams = () => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  let streamData = [];

  assets.forEach((device) => {
    device.cameras.forEach((camera) => {
      streamData.push({
        value: camera.camera_id,
        label: camera.camera_name,
      });
    });
  });

  return streamData;
}

const getZones = () => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  let zoneData = [];

  assets.forEach((device) => {
    device.cameras.forEach((camera) => {
      camera.zones.forEach((zone) => {
        if (zoneData.find(o => o.value === zone.zone_id) === undefined) {
          zoneData.push({
            value: zone.zone_id,
            label: zone.zone,
          });
        }
      });
    });
  });

  return zoneData;
}

const getEvents = () => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  const eventData = [];

  assets.forEach((device) => {
    device.cameras.forEach((camera) => {
      camera.zones.forEach((zone) => {
        zone.rules.forEach((rule) => {
          if (!eventData.some(e => e.label === rule.event)) {
            eventData.push({
              value: rule.rule_id,
              label: rule.event,
            });
          }
        });
      });
    });
  });

  return eventData;
}

const getAllEvents = () => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  const allEventData = [];

  assets.forEach((device) => {
    device.cameras.forEach((camera) => {
      camera.zones.forEach((zone) => {
        zone.rules.forEach((rule) => {
          allEventData.push({
            value: rule.rule_id,
            label: rule.event,
          })
        });
      });
    });
  });

  return allEventData;
}

const getLines = () => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  let lineData = [];

  assets.forEach((device) => {
    device.cameras.forEach((camera) => {
      camera.lines.forEach((line) => {
        if (lineData.find(o => o.value === line.id) === undefined) {
          lineData.push({
            value: line.id,
            label: line.name,
          });
        }
      });
    });
  });

  return lineData;
}

const getClasses = () => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  const classData = [];

  assets.forEach((device) => {
    device.cameras.forEach((camera) => {
      camera.lines.forEach((line) => {
        line.classes.forEach((classItem) => {
          if (!classData.some(e => e.label === classItem.class_title)) {
            classData.push({
              value: classItem.class_title,
              label: classItem.class_title,
            })
          }
        });
      });
    });
  });

  assets.forEach((device) => {
    device.cameras.forEach((camera) => {
      camera.zones.forEach((zone) => {
        zone.rules.forEach((ruleItem) => {
          if (!classData.some(e => e.label === ruleItem.class_title)) {
            classData.push({
              value: ruleItem.class_title,
              label: ruleItem.class_title,
            })
          }
        });
      });
    });
  });

  return classData;
}

const getDirections = () => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  const directionData = [];

  assets.forEach((device) => {
    device.cameras.forEach((camera) => {
      camera.lines.forEach((line) => {
        line.directions.forEach((direction) => {
          directionData.push({
            value: direction.id,
            label: direction.name,
          })
        });
      });
    });
  });

  return directionData;
}

const findDeviceByDirection = (directionId) => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  var deviceName = "-"
  assets.forEach((device) => {
    device.cameras.forEach((camera) => {
      camera.lines.forEach((line) => {
        line.directions.forEach((direction) => {
          if (directionId === direction.id) {
            deviceName = device.device_name
          }
        });
      });
    });
  });
  return deviceName;
}

const findCameraByDirection = (directionId) => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  var cameraName = "-"
  assets.forEach((device) => {
    device.cameras.forEach((camera) => {
      camera.lines.forEach((line) => {
        line.directions.forEach((direction) => {
          if (directionId === direction.id) {
            cameraName = camera.camera_name
          }
        });
      });
    });
  });
  return cameraName;
}

const findDirectionNameByDirectionId = (directionId) => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  var directionName = "-"
  assets.forEach((device) => {
    device.cameras.forEach((camera) => {
      camera.lines.forEach((line) => {
        if (line.directions.find((a) => a.id === directionId) !== undefined) {
          directionName = line.directions.find((a) => a.id === directionId).name
        }
      });
    });
  });
  return directionName;
}

const findLineNameByLineId = (lineId) => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  var lineName = "-"
  assets.forEach((device) => {
    device.cameras.forEach((camera) => {
      if (camera.lines.find((a) => a.id === lineId) !== undefined) {
        lineName = camera.lines.find((a) => a.id === lineId).name
      }
    });
  });
  return lineName;
}

const findDeviceNameByDeviceId = (deviceId) => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  var deviceName = "All Devices"

  if (assets.find((a) => a.device_id === deviceId) !== undefined) {
    deviceName = assets.find((a) => a.device_id === deviceId).device_name
  }

  return deviceName;
}

const findStreamNameByStreamId = (streamId) => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  var streamName = "All Streams"

  assets.forEach((device) => {
    if (device.cameras.find((a) => a.camera_id === streamId) !== undefined) {
      streamName = device.cameras.find((a) => a.camera_id === streamId).camera_name
    }
  });

  return streamName;
}

const findZoneNameByZoneId = (zoneId) => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  var zoneName = "-"

  assets.forEach((device) => {
    device.cameras.forEach((camera) => {
      camera.zones.forEach((zone) => {
        if (zone.zone_id === zoneId) {
          zoneName = zone.zone
        }
      });
    });
  });

  return zoneName;
}

const findRuleNameByRuleId = (ruleId) => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  var ruleName = "All Alerts"


  assets.forEach((device) => {
    device.cameras.forEach((camera) => {
      camera.zones.forEach((zone) => {
        if (zone.rules.find((a) => a.rule_id === ruleId) !== undefined) {
          ruleName = zone.rules.find((a) => a.rule_id === ruleId).event
        }
      });
    });
  });

  return ruleName;
}

const findClassTitlesByStreamId = (streamId) => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  const classData = [];

  assets.forEach((device) => {
    device.cameras.forEach((camera) => {
      if (camera.camera_id === streamId) {
        camera.lines.forEach((line) => {
          line.classes.forEach((classItem) => {
            if (!classData.some(e => e.label === classItem.class_title)) {
              classData.push({
                value: classItem.class_title,
                label: classItem.class_title,
              })
            }
          });
        });
      }

      if (camera.camera_id === streamId) {
        camera.zones.forEach((zone) => {
          zone.rules.forEach((ruleItem) => {
            if (!classData.some(e => e.label === ruleItem.class_title)) {
              classData.push({
                value: ruleItem.class_title,
                label: ruleItem.class_title,
              })
            }
          });
        });
      }
    });
  });

  return classData;
}

const findClassTitleByRuleId = (ruleId) => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  var classTitle = "-"

  assets.forEach((device) => {
    device?.cameras.forEach((camera) => {
      camera?.zones.forEach((zone) => {
        if (zone?.rules.find((a) => a?.rule_id === ruleId) !== undefined) {
          classTitle = zone?.rules.find((a) => a?.rule_id === ruleId)?.class_title
        }
      });
    });
  });

  return classTitle;
}

const findZoneByStream = (streamId) => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  let zoneData = [];

  assets.forEach((device) => {
    device.cameras.forEach((camera) => {
      if (camera.camera_id === streamId) {
        camera.zones.forEach((zone) => {
          if (zoneData.find(o => o.value === zone.zone_id) === undefined) {
            zoneData.push({
              value: zone.zone_id,
              label: zone.zone,
            });
          }
        });
      }
    });
  });

  return zoneData;
}

const getCamerasIncludingLinesWithLines = () => {
  let assets = JSON.parse(localStorage.getItem('assets'))
  let cameraData = [];

  assets.forEach((device) => {
    device.cameras.forEach((camera) => {
      if (camera.lines.length > 0) {
        cameraData.push(camera);
      }
    });
  });

  return cameraData;
}

export {
  getDevices,
  getStreams,
  getZones,
  getEvents,
  getAllEvents,
  getLines,
  getClasses,
  getDirections,
  findDeviceByDirection,
  findCameraByDirection,
  findLineNameByLineId,
  findDirectionNameByDirectionId,
  findDeviceNameByDeviceId,
  findStreamNameByStreamId,
  findZoneNameByZoneId,
  findRuleNameByRuleId,
  findClassTitlesByStreamId,
  findClassTitleByRuleId,
  findZoneByStream,
  getCamerasIncludingLinesWithLines,
};
