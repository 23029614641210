import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { fast_api_axios } from '../../utils/axiosFast';
import { useAuth } from '../../contexts/AuthContext'
import { useToast } from '../../contexts/ToastContext'
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import { findStreamNameByStreamId, findLineNameByLineId } from '../../utils/generic';
import Loader from '../Loader';

const directionOptions = [
  {
    value: 'in',
    label: 'IN'
  },
  {
    value: 'out',
    label: 'OUT'
  },
]

export default function EditLineAreaModal(props) {
  const { logout } = useAuth();
  const { updateMessage } = useToast();
  let history = useHistory();

  const [itemInfo, setItemInfo] = useState(null);
  const [areaName, setAreaName] = useState(null);

  useEffect(() => {
    if (props.item !== null) {
      var editData = props.item;
      setItemInfo(editData)
      setAreaName(props.item.name)
    }
  }, [props.item])

  const editLineArea = () => {
    let directionsDataArr = [];
    itemInfo.line_group_directions.forEach(function (item) {
      directionsDataArr.push(
        {
          "id": item.id,
          "line_type": item.line_type
        },
      )
    })

    fast_api_axios(
      {
        method: 'patch',
        url: '/api/v1/tracker/line_direction/' + props.item.id,
        data: {
          "name": areaName,
          "line_group_directions": directionsDataArr
        },
      })
      .then(res => {
        updateMessage('The line settings have been successfully deleted.', 'success')
        props.handleRefresh()
        closeModal()
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const handleDirectionSelect = (lineItem, option) => {
    var directionInfo = { ...itemInfo };
    directionInfo.line_group_directions.find(i => i.id === lineItem.id).line_type = option.value
    setItemInfo(directionInfo)
  }

  const closeModal = () => {
    setAreaName(null)
    setItemInfo(null)
    props.closeModal()
  }

  return (
    <Modal className='line-area-modal' show={props.show} onHide={closeModal} size='xl' centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Line Area</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {itemInfo !== null && areaName !== null ?
          <div className='d-flex justify-content-between'>
            <div className='d-flex w-50 gap-3'>
              <Form.Group>
                <Form.Label>Area Name</Form.Label>
                <Form.Control placeholder="Enter Area Name" value={areaName} onChange={e => setAreaName(e.target.value)} />
              </Form.Group>

              <Form.Group>
                <Form.Label>Camera Name</Form.Label>
                <Form.Control value={findStreamNameByStreamId(itemInfo.line_group_directions[0].camera_id)} disabled />
              </Form.Group>
            </div>
            <div className='d-flex flex-column w-50 justify-content-center gap-3 align-items-center'>
              {itemInfo.line_group_directions.map((directionItem, index) => {
                return (
                  <div className='d-flex gap-5' key={index}>
                    <Form.Group>
                      {index === 0 && <Form.Label>Line Name</Form.Label>}
                      <Form.Control value={findLineNameByLineId(itemInfo.line_group_directions[0].line_id)} disabled />
                    </Form.Group>
                    <Form.Group>
                      {index === 0 && <Form.Label>Class</Form.Label>}
                      <Form.Control value={itemInfo.class_title} disabled />
                    </Form.Group>
                    <Form.Group className='directions'>
                      {index === 0 && <Form.Label>Direction</Form.Label>}
                      <Select
                        name="options"
                        options={directionOptions}
                        className="basic-select"
                        classNamePrefix="select"
                        placeholder="Select"
                        onChange={opt => handleDirectionSelect(directionItem, opt)}
                        value={directionItem.line_type === 'in' ? directionOptions[0] : directionOptions[1]}
                      />
                    </Form.Group>
                  </div>
                )
              })}
            </div>
          </div>
          :
          <Loader />
        }
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button className='save-btn' onClick={() => editLineArea()}>Save</Button>
      </Modal.Footer>
    </Modal>
  )
}
