import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import {
  Container,
  Row,
  Table,
  Tabs,
  Tab,
  Dropdown,
  Button,
  Form,
  Popover,
  OverlayTrigger,
  Overlay, Tooltip,
  Accordion
} from 'react-bootstrap'; import { ChevronDown, PersonPlus, Plus, PlusLg, QuestionCircle, QuestionCircleFill, ThreeDotsVertical, X } from 'react-bootstrap-icons';
import AddUserModal from './AddUserModal'
import { fast_api_axios, dashboard_axios } from '../../utils/axiosFast';
import { useHistory } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import Loader from '../Loader'
import moment from 'moment'
import Select from 'react-select';
import Switch from "react-switch";
import MomentTimezone from 'moment-timezone';
import AlertEmailActivateModal from './AlertEmailActivateModal';
import AlertEmailDeactivateModal from './AlertEmailDeactivateModal';
import AlertEmailDeleteModal from './AlertEmailDeleteModal';
import AlertEmailDiscardModal from './AlertEmailDiscardModal';
import AlertEmailEditModal from './AlertEmailEditModal';
import AddReportModal from './AddReportModal';
import { findDeviceNameByDeviceId, findRuleNameByRuleId, findStreamNameByStreamId, getHoursInDay } from '../../utils/generic';
import AddPeriodicReportModal from './AddPeriodicReportModal';
import StreamStatusEmailActiveModal from './StreamStatusEmailActiveModal';
import StreamStatusEmailDeactivateModal from './StreamStatusDeactivateModal';
import AddInventoryReportModal
  from './AddInventoryReportModal';
import InventoryEditModal
  from './InventoryEditModal';
import RealTimeSettings from './RealTimeSettings';

const frequencyOptions = [
  {
    label: 'Daily',
    value: 'DAILY'
  },
  {
    label: 'Weekly',
    value: 'WEEKLY'
  },
  {
    label: 'Monthly',
    value: 'MONTHLY'
  }
]

const inventoryFrequencyOptions = [
  {
    label: 'Daily - 10:30 AM PST',
    value: 'DAILY'
  },
  {
    label: 'Every Monday',
    value: 'WEEKLY'
  },
  {
    label: 'Monthly',
    value: 'MONTHLY'
  }
]

const alertOptions = [
  {
    label: 'All Alerts',
    value: 'REPORT_ALERT'
  }
]

const weeklyOptions = [
  {
    label: 'Monday',
    value: 'Monday'
  },
  {
    label: 'Tuesday',
    value: 'Tuesday'
  },
  {
    label: 'Wednesday',
    value: 'Wednesday'
  },
  {
    label: 'Thursday',
    value: 'Thursday'
  },
  {
    label: 'Friday',
    value: 'Friday'
  },
  {
    label: 'Saturday',
    value: 'Saturday'
  },
  {
    label: 'Sunday',
    value: 'Sunday'
  }
]

export default function SettingsContent() {
  let history = useHistory();
  const { updateMessage } = useToast();
  const { currentUser, updateUserTimezone, logout } = useAuth();

  const [offsetTmz, setOffsetTmz] = useState(null);
  const [timezone, setTimezone] = useState(
    {
      label: "(UTC" + moment.tz(localStorage.getItem('timezone')).format('Z') + ") " + localStorage.getItem('timezone'),
      value: localStorage.getItem('timezone')
    }
  );

  const [tabKey, setTabKey] = useState('reportSettings');

  const [settingTabKey, setSettingTabKey] = useState('instant');

  const [isAlertSettingsLoading, setIsAlertSettingsLoading] = useState(true);
  const [alertSettings, setAlertSettings] = useState([]);
  const [selectedAlertSettingId, setSelectedAlertSettingId] = useState(null);
  const [selectedAlertSettingIsActive, setSelectedAlertSettingIsActive] = useState(false)
  const [addedUserList, setAddedUserList] = useState([]);
  const [showAlertEmailActivateModal, setShowAlertEmailActivateModal] = useState(false);
  const [showAlertEmailDeactivateModal, setShowAlertEmailDeactivateModal] = useState(false);
  const [showAlertEmailDeleteModal, setShowAlertEmailDeleteModal] = useState(false);
  const [showAlertEmailEditModal, setShowAlertEmailEditModal] = useState(false);
  const [showAlertEmailDiscardModal, setShowAlertEmailDiscardModal] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const [isAddAlertActive, setIsAddAlertActive] = useState(false);
  const [isReportSettingsLoading, setIsReportSettingsLoading] = useState(false);
  const [reportSettings, setReportSettings] = useState([]);

  const [newEventOptions, setNewEventOptions] = useState(JSON.parse(localStorage.getItem("allEventData")));
  const [eventOptions, setEventOptions] = useState(JSON.parse(localStorage.getItem("allEventData")));
  const deviceOptions = JSON.parse(localStorage.getItem("deviceData"));
  const [newStreamOptions, setNewStreamOptions] = useState(JSON.parse(localStorage.getItem("streamData")));
  const [streamOptions, setStreamOptions] = useState(JSON.parse(localStorage.getItem("streamData")));

  const [discardModalType, setDiscardModalType] = useState(null);
  const [editModalType, setEditModalType] = useState(null);
  const [deleteModalType, setDeleteModalType] = useState(null);
  const [activateModalType, setActivateModalType] = useState(null)
  const [userModalType, setUserModalType] = useState(null);

  const [isEditAlertActive, setIsEditAlertActive] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedStream, setSelectedStream] = useState(null);

  const [isEditReportActive, setIsEditReportActive] = useState(false);
  const [isAddReportActive, setIsAddReportActive] = useState(false);
  const [selectedReportDevice, setSelectedReportDevice] = useState(null);
  const [selectedReportStream, setSelectedReportStream] = useState(null);
  const [selectedReportAlert, setSelectedReportAlert] = useState(null);
  const [selectedFrequency, setSelectedFrequency] = useState(null);
  const [selectedReportSettingId, setSelectedReportSettingId] = useState(null);
  const [selectedReportTime, setSelectedReportTime] = useState([]);
  const [selectedWeekly, setSelectedWeekly] = useState([]);

  const [showAddReportModal, setShowAddReportModal] = useState(false)
  const [showAddPeriodicReportModal, setShowAddPeriodicReportModal] = useState(false)

  const [isStreamSettingsLoading, setIsStreamSettingsLoading] = useState(true);
  const [streamSettings, setStreamSettings] = useState([]);
  const [showStreamStatusEmailActivateModal, setShowStreamStatusEmailActivateModal] = useState(false);
  const [showStreamStatusEmailDeactivateModal, setShowStreamStatusEmailDeactivateModal] = useState(false);

  const [isInventorySettingsLoading, setIsInventorySettingsLoading] = useState(false);
  const [isInventorySwitchActive, setIsInventorySwitchActive] = useState(true);
  const [inventorySettings, setInventorySettings] = useState([]);
  const [showInventoryReportModal, setShowInventoryReportModal] = useState(false)
  const [showInventoryEditModal, setShowInventoryEditModal] = useState(false)

  const [questionActive, setQuestionActive] = useState(false);
  const questionTarget = useRef(null);

  const handleShowReportModal = () => {
    clearAddAlertFields();
    setShowAddReportModal(true)
  }

  const handleShowInventoryReportModal = () => {
    clearAddAlertFields();
    setShowInventoryReportModal(true)
  }

  const handleCloseInventoryReportModal = () => {
    clearAddAlertFields();
    setShowInventoryReportModal(false)
  }

  const handleShowPeriodicReportModal = () => {
    clearAddAlertFields();
    setShowAddPeriodicReportModal(true)
  }

  const handleCloseReportModal = () => {
    clearAddAlertFields();
    setShowAddReportModal(false)
  }

  const handleClosePeriodicReportModal = () => {
    clearAddAlertFields();
    setShowAddPeriodicReportModal(false)
  }

  const addAlertSetting = (selectedEvent, selectedStream, selectedDevice) => {

    fast_api_axios(
      {
        method: 'post',
        url: '/api/v1/setting/alert',
        data: {
          "rule_id": selectedEvent.value,
          "camera_id": selectedStream.value,
          "device_id": selectedDevice.value,
        }
      })
      .then(res => {
        //handleCloseReportModal();
        setShowAddReportModal(false)
        setIsAddAlertActive(false);
        getAlertSettings();
        clearAddAlertFields();
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        if (error.response.data.detail === 'Rule id already exists') {
          updateMessage('Alert email already exists.', 'error');
          clearAddAlertFields();
        }
        else {
          updateMessage(error.response.data.detail, 'error');
        }
      });
  }

  const addPeriodicReport = (selectedReportDevice, selectedReportStream, selectedReportAlert, selectedFrequency, selectedReportTimes, weeklyDay) => {
    fast_api_axios(
      {
        method: 'post',
        url: '/api/v1/setting/report',
        data: {
          "device_id": selectedReportDevice.value,
          "camera_id": selectedReportStream.value,
          "rule_id": selectedReportAlert?.value || -1,
          "report_frequency": selectedFrequency.value,
          "is_active": true,
          "report_time": selectedReportTimes,
          "report_date": weeklyDay?.length > 0 ? [weeklyDay] : [],
           "report_type": 'REPORT_ALERT'
        }
      })
      .then(res => {
        setShowAddPeriodicReportModal(false)
        setIsAddReportActive(false);
        getReportSettings();
        clearAddReportFields();
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        if (error.response.data.detail === 'Rule id already exists') {
          updateMessage('Report email already exists.', 'error');
          clearAddReportFields();
        }
        else {
          updateMessage(error.response.data.detail, 'error');
        }
      });
  }

  useEffect(() => {
    if (selectedReportDevice !== null) {
      let assets = JSON.parse(localStorage.getItem('assets'));
      let selectedDeviceData = assets.find(d => d.device_id === selectedReportDevice.value)

      let newStreamOptions = [];
      selectedDeviceData.cameras.forEach(element => {
        newStreamOptions.push(
          {
            value: element.camera_id,
            label: element.camera_name
          }
        )
      });
      setStreamOptions(newStreamOptions)
    }
  }, [selectedReportDevice])

  useEffect(() => {
    if(selectedReportStream !== null) {
      let assets = JSON.parse(localStorage.getItem('assets'));
      let selectedStreamData = assets.find(d => d.device_id === selectedReportDevice.value).cameras.find(c => c.camera_id === selectedReportStream.value);

      let newAlertOptions = [];
      selectedStreamData.zones.forEach(camera => {
        camera.rules.forEach(rule => {
          newAlertOptions.push(
            {
              value: rule.rule_id,
              label: rule.event + ' - ' + rule.class_title
            }
          )
        })
      })

      setEventOptions(newAlertOptions);
    }
  }, [selectedReportStream]);

  useEffect(() => {
    if (isAddReportActive && selectedFrequency !== null) {
      addPeriodicReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFrequency])


  /*const createAlertSetting = () => {
    fast_api_axios(
      {
        method: 'post',
        url: '/api/v1/setting/alert',
        data: {
          "rule_id": selectedEvent.value,
          "camera_id": selectedStream.value,
          "device_id": selectedDevice.value,
        }
      })
      .then(res => {
        setIsAddAlertActive(false);
        getAlertSettings();
        clearAddAlertFields();
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        if (error.response.data.detail === 'Rule id already exists') {
          updateMessage('Alert email already exists.', 'error');
          clearAddAlertFields();
        }
        else {
          updateMessage(error.response.data.detail, 'error');
        }
      });
  }*/


  const getAlertSettings = () => {
    setIsAlertSettingsLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/setting/alert'
      })
      .then(res => {
        let modifiedArray = []
        let allDevices = JSON.parse(localStorage.getItem("deviceData"))
        let allStreams = JSON.parse(localStorage.getItem("streamData"))
        let allEvents = JSON.parse(localStorage.getItem("allEventData"))
        res.data.forEach(item => {
          if (item.device_id === -1) {
            modifiedArray.push(item)
          }
          else if (allDevices.find(x => x.value === item.device_id) !== undefined && allStreams.find(x => x.value === item.camera_id) && allEvents.find(x => x.value === item.rule_id)) {
            modifiedArray.push(item)
          }
        })

        setAlertSettings(modifiedArray);
        setIsAlertSettingsLoading(false);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else {
          updateMessage(error.response.data.detail, 'error');
        }
      });
  }

  const getReportSettings = () => {
    setIsReportSettingsLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/setting/report'
      })
      .then(res => {

        const inventorySettings = res.data.filter(setting => setting.report_type === 'REPORT_STOCK')
        setInventorySettings(inventorySettings)


        //const reportSettings = res.data.filter(setting => setting.report_type === 'REPORT_ALERT')
        setReportSettings(res.data);

        setIsReportSettingsLoading(false);
        setIsInventorySettingsLoading(false);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getStreamSettings = () => {
    setIsStreamSettingsLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/setting/stream-status'
      })
      .then(res => {
        setStreamSettings(res?.data);
        setIsStreamSettingsLoading(false);
      })
      .catch(error => {
        console.log('error', error)
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  useEffect(() => {
    if (settingTabKey === "instant") {
      getAlertSettings();
    } else if (settingTabKey === "periodic" || settingTabKey === "inventoryStatus") {
      getReportSettings();
    } else if (settingTabKey === "streamStatus") {
      getStreamSettings();
    }
  }, [settingTabKey])

  const showAddUserModal = (selectedAlertSetting, type) => {
    type === "alert" ? clearEditAlertFields() : clearEditReportFields()
    setUserModalType(type)
    setAddedUserList(selectedAlertSetting.users);
    setSelectedAlertSettingId(selectedAlertSetting.id)
    setShowAddUser(true);
  }

  const closeAddUserModal = () => {
    setAddedUserList([]);
    setSelectedAlertSettingId(null);
    setShowAddUser(false);
  }
  const doneAddUserModal = (type) => {
    setAddedUserList([]);
    setSelectedAlertSettingId(null);
    type === "alert" ? getAlertSettings() : getReportSettings()
    setShowAddUser(false);
  }

  const handleCloseAlertEmailActivateModal = () => {
    setSelectedAlertSettingId(null);
    setShowAlertEmailActivateModal(false);
  }

  const handleCloseStreamStatusEmailActivateModal = () => {
    setShowStreamStatusEmailActivateModal(false);
  }

  const handleCloseStreamStatusEmailDeactivateModal = () => {
    setShowStreamStatusEmailDeactivateModal(false);
  }

  const handleCloseAlertEmailDeactivateModal = () => {
    setSelectedAlertSettingId(null);
    setShowAlertEmailDeactivateModal(false);
  }

  const handleShowAlertEmailDeleteModal = (selectedSettingId, type) => {
    setDeleteModalType(type)
    setSelectedAlertSettingId(selectedSettingId)
    setSelectedReportSettingId(selectedSettingId)
    setShowAlertEmailDeleteModal(true);
  }

  const handleCloseAlertEmailDeleteModal = () => {
    setSelectedAlertSettingId(null);
    setSelectedReportSettingId(null)
    setShowAlertEmailDeleteModal(false);
  }

  const handleShowAlertEmailEditModal = (selectedSettingId, selectedItemActive, type) => {
    setEditModalType(type)
    setSelectedAlertSettingId(selectedSettingId)
    setSelectedAlertSettingIsActive(selectedItemActive)
    setShowAlertEmailEditModal(true);
    setSelectedReportSettingId(selectedSettingId);
    setSelectedAlertSettingIsActive(selectedItemActive);
  }

  const handleCloseAlertEmailEditModal = () => {
    setShowAlertEmailEditModal(false);
    setSelectedWeekly([]);
    setSelectedReportTime([]);
  }

  const handleShowInventoryEditModal = (selectedSettingId, selectedItemActive, type) => {
    setEditModalType(type)
    setSelectedReportSettingId(selectedSettingId)
    setSelectedAlertSettingIsActive(selectedItemActive)
    setShowInventoryEditModal(true);
  }

  const handleCloseInventoryEditModal = () => {
    setShowInventoryEditModal(false);
  }

  const handleShowAlertEmailDiscardModal = (type) => {
    setDiscardModalType(type)
    setShowAlertEmailDiscardModal(true);
  }

  const handleCloseAlertEmailDiscardModal = () => {
    setShowAlertEmailDiscardModal(false);
  }
  const editAlertEmailSending = (selectedSettingId, isActive, type) => {
    let api_url = type === 'alert' ? '/api/v1/setting/alert/' : '/api/v1/setting/report/'
    fast_api_axios(
      {
        method: 'patch',
        url: api_url + selectedSettingId,
        data: {
          "is_active": isActive,
        }
      })
      .then(res => {
        if (isActive) {
          updateMessage('Successfully turned on ' + type + '.', 'success');
        }
        else {
          updateMessage('Successfully turned off ' + type + '.', 'success');
        }

        type === 'alert' ? getAlertSettings() : getReportSettings()
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else {
          updateMessage(error.response.data.detail, 'error');
        }
      });
  }

  const editStreamStatusEmailSending = (isActive) => {
    let api_url = '/api/v1/setting/stream-status/';
    fast_api_axios(
      {
        method: 'put',
        url: api_url,
        data: {
          'stream_status': isActive
        }
      })
      .then(res => {
        if (isActive) {
          updateMessage('Successfully turned on stream status.', 'success');
        }
        else {
          updateMessage('Successfully turned off stream status.', 'success');
        }
        getStreamSettings();
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else {
          updateMessage(error.response.data.detail, 'error');
        }
      })
  }

  const activateEmail = (type) => {
    handleCloseAlertEmailActivateModal();
    editAlertEmailSending(selectedAlertSettingId, true, type);
  }

  const deactivateEmail = (type) => {
    handleCloseAlertEmailDeactivateModal();
    editAlertEmailSending(selectedAlertSettingId, false, type);
  }

  const activateStreamStatusEmail = () => {
    handleCloseStreamStatusEmailActivateModal();
    editStreamStatusEmailSending(true);
  }
  const deactivateStreamStatusEmail = () => {
    handleCloseStreamStatusEmailDeactivateModal();
    editStreamStatusEmailSending(false);
  }

  const applyDelete = (selectedSettingId, type) => {
    handleCloseAlertEmailDeleteModal();
    deleteAlertSetting(selectedSettingId, type)
  }

  const editAlertSetting = (selectedAlertItem) => {
    clearAddAlertFields();
    setSelectedAlertSettingId(selectedAlertItem.id);
    //selectedReportSettingId(selectedAlertItem.id);
    setSelectedDevice(deviceOptions.find(x => x.value === selectedAlertItem.device_id));
    setSelectedEvent(eventOptions.find(x => x.value === selectedAlertItem.rule_id));
    setSelectedStream(streamOptions.find(x => x.value === selectedAlertItem.camera_id));
    setIsEditAlertActive(true);
  }

  const editReportSetting = (selectedReportItem) => {
    clearAddReportFields();
    setSelectedReportSettingId(selectedReportItem.id);
    setSelectedReportDevice(deviceOptions.find(x => x.value === selectedReportItem.device_id));
    setSelectedReportStream(streamOptions.find(x => x.value === selectedReportItem.camera_id));
    setSelectedEvent(eventOptions.find(x => x.value === selectedReportItem.rule_id));
    setSelectedReportAlert(eventOptions.find(x => x.value === selectedReportItem.rule_id));

    setSelectedFrequency(selectedReportItem.report_frequency === "DAILY" ?
      {
        label: 'Daily',
        value: 'DAILY'
      }
      :
      selectedReportItem.report_frequency === "WEEKLY" ?
        {
          label: 'Weekly',
          value: 'WEEKLY'
        }
        :
        selectedReportItem.report_frequency === "MONTHLY" ?
          {
            label: 'Monthly',
            value: 'MONTHLY'
          }
          :
          null)
    setIsEditReportActive(true);
  }

  const editInventorySetting = (selectedReportItem) => {
    clearAddReportFields();
    setSelectedReportSettingId(selectedReportItem.id);
    setSelectedFrequency(selectedReportItem.report_frequency === "DAILY" ?
      {
        label: 'Daily - 10:30 AM PST',
      }
      :
      selectedReportItem.report_frequency === "WEEKLY" ?
        {
          label: 'Weekly',
        }
        :
        selectedReportItem.report_frequency === "MONTHLY" ?
          {
            label: 'Monthly',
          }
          :
          null
    )
    setIsEditReportActive(true);

  }

  useEffect(() => {

    if (selectedDevice !== null) {
      let assets = JSON.parse(localStorage.getItem('assets'));
      let selectedDeviceData = assets.find(d => d.device_id === selectedDevice.value)
      let newStreamOptions = [];
      selectedDeviceData.cameras.forEach(element => {
        newStreamOptions.push(
          {
            value: element.camera_id,
            label: element.camera_name
          }
        )
      });


      setStreamOptions(newStreamOptions);
    }
  }, [selectedDevice]);

  useEffect(() => {

    if (selectedStream !== null) {
      let assets = JSON.parse(localStorage.getItem('assets'));
      let selectedStreamData = assets.find(d => d.device_id === selectedDevice.value).cameras.find(c => c.camera_id === selectedStream.value)


      let newAlertOptions = [];
      selectedStreamData.zones.forEach(camera => {
        camera.rules.forEach(rule => {
          newAlertOptions.push(
            {
              value: rule.rule_id,
              label: rule.event + (rule.class_title ? ' - ' + rule.class_title : '')
            }
          )
        })
      })

      setEventOptions(newAlertOptions);
    }
  }, [selectedStream])


  const deleteAlertSetting = (selectedSettingId, type) => {
    let api_url = type === 'alert' ? '/api/v1/setting/alert/' : '/api/v1/setting/report/'
    fast_api_axios(
      {
        method: 'delete',
        url: api_url + selectedSettingId,
      })
      .then(res => {
        if (type === 'alert') {
          updateMessage('Alert email deleted successfully.', 'success');
          getAlertSettings()
        }
        else {
          updateMessage('Report email deleted successfully.', 'success');
          getReportSettings()
        }
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else {
          updateMessage(error.response.data.detail, 'error');
        }
      });
  }

  const clearAddAlertFields = () => {
    setIsAddAlertActive(false);
    setSelectedEvent(null);
    setSelectedDevice(null);
    setSelectedStream(null);
  }

  const clearEditAlertFields = () => {
    setIsEditAlertActive(false);
    setSelectedAlertSettingId(null);
    setSelectedEvent(null);
    setSelectedDevice(null);
    setSelectedStream(null);
  }

  const clearAddReportFields = () => {
    setIsAddReportActive(false);
    setSelectedReportDevice(null);
    setSelectedReportStream(null);
    setSelectedReportAlert(null);
    setSelectedFrequency(null);
  }

  const clearEditReportFields = () => {
    setIsEditReportActive(false);
    setSelectedReportSettingId(null);
    setSelectedReportDevice(null);
    setSelectedReportStream(null);
    setSelectedReportAlert(null);
    setSelectedFrequency(null);
  }

  const saveEditChanges = (selectedSettingId, isActive, type, report_time, report_date) => {


    if (type === "alert") {
      fast_api_axios(
        {
          method: 'patch',
          url: '/api/v1/setting/alert/' + selectedSettingId,
          data: {
            "rule_id": selectedEvent.value,
            "camera_id": selectedStream.value,
            "device_id": selectedDevice.value,
            "is_active": isActive
          }
        })
        .then(res => {
          updateMessage('Successfully edited alert email settings.', 'success');
          discardEditChanges('alert');
          getAlertSettings();
        })
        .catch(error => {
          if (error.response.data.detail === 'Please re-login') {
            updateMessage('Your session has expired, please login again.', 'error');
            logout();
            history.push('/login')
          }
          else {
            updateMessage(error.response.data.detail, 'error');
          }
        });
    }
    else {
      fast_api_axios(
        {
          method: 'patch',
          url: '/api/v1/setting/report/' + selectedSettingId,
          data: {
            "rule_id": selectedReportAlert?.value || -1,
            "device_id": selectedReportDevice?.value,
            "camera_id": selectedReportStream?.value,
            "report_type": 'REPORT_ALERT',
            "report_frequency": selectedFrequency?.value,
            "is_active": isActive,
            "report_time": selectedFrequency.value === 'DAILY' ? report_time ? report_time : '07:00' : null,
            "report_date": selectedFrequency.value === 'WEEKLY' ? report_date ? report_date : 'Monday' : null
          }
        })
        .then(res => {
          updateMessage('Successfully edited report email settings.', 'success');
          discardEditChanges('report');
          getReportSettings();
        })
        .catch(error => {
          if (error.response.data.detail === 'Please re-login') {
            updateMessage('Your session has expired, please login again.', 'error');
            logout();
            history.push('/login')
          }
          else {
            updateMessage(error.response.data.detail, 'error');
          }
        });
    }

  }

  const saveInventoryEditChanges = (settingReportId, isActive) => {

    fast_api_axios(
      {
        method: 'patch',
        url: '/api/v1/setting/report/' + settingReportId,
        data: {
          "report_frequency": selectedFrequency.value,
          "is_active": isActive
        }
      })
      .then(res => {
        updateMessage('Successfully edited report email settings.', 'success');
        discardEditChanges('report');
        getReportSettings();
        handleCloseInventoryEditModal()
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else {
          updateMessage(error.response.data.detail, 'error');
        }
      });
  }

  const discardEditChanges = (type) => {
    if (type === "alert") {
      setIsEditAlertActive(false);
      setSelectedAlertSettingId(null);
      setSelectedEvent(null);
      setSelectedDevice(null);
      setSelectedStream(null);
      handleCloseAlertEmailDiscardModal();
      handleCloseAlertEmailEditModal();
    }
    else {
      setIsEditReportActive(false);
      setSelectedReportSettingId(null);
      setSelectedFrequency(null);
      handleCloseAlertEmailDiscardModal();
      handleCloseAlertEmailEditModal();
    }
  }

  const handleChangeEmailActivate = (val, selectedSettingId, type) => {
    if (type === "alert") {
      clearEditAlertFields()
    }
    else {
      clearEditReportFields()
    }

    setSelectedAlertSettingId(selectedSettingId);
    setActivateModalType(type)

    if (val) {
      setShowAlertEmailActivateModal(true);
    }
    else {
      setShowAlertEmailDeactivateModal(true);
    }
  }


  const handleStreamStatusChangeEmailActivate = (status) => {
    if (status) {
      setShowStreamStatusEmailActivateModal(true)
    } else {
      setShowStreamStatusEmailDeactivateModal(true)
    }
  }

  const updateTimezone = (selectedTimezone) => {
    fast_api_axios(
      {
        method: 'patch',
        url: '/users',
        data: {
          'timezone': selectedTimezone.value,
        }
      })
      .then(res => {
        setTimezone(selectedTimezone);
        updateUserTimezone(selectedTimezone.value)
        updateMessage('Successfully changed your time zone.', 'success')
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else {
          updateMessage(error.response.data.detail, 'error')
        }
      });
  }

  const handleTimezoneSelect = (item) => {
    updateTimezone(item)
  }

  useEffect(() => {
    var timeZones = MomentTimezone.tz.names();
    var offsetTmzs = [];
    for (var i in timeZones) {
      offsetTmzs.push({
        label: "(UTC" + moment.tz(timeZones[i]).format('Z') + ") " + timeZones[i],
        value: timeZones[i]
      });
    }
    getAssets()
    setOffsetTmz(offsetTmzs)
    getAlertSettings()
    getReportSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAssets = () => {
    dashboard_axios({
      method: "get",
      url: "/edge/assets",
      headers: {
        Authorization: "token " + localStorage.getItem("dashboard_token"),
      },
      params: {
        user_id: localStorage.getItem("dashboardId"),
      },
      baseURL: process.env.REACT_APP_DASHBOARD_URL,
    })
      .then((res) => {
        localStorage.setItem('assets', JSON.stringify(res.data));
      })
      .catch((error) => {
        console.log('error: assets', error);
      });
  };

  const handleQuestionMarkClick = (e) => {
    if (questionTarget.current && questionActive && !questionTarget.current.contains(e.target)) {
      setQuestionActive(false)
    }
  }
  document.addEventListener('mousedown', handleQuestionMarkClick);

  return (
    <Container fluid>
      <Row>
        <div className='settings-content-wide'>
          <div className='page-content-header'>
            <div className='page-name'>Settings</div>
          </div>

          <div className='page-wrapper'>
            <Tabs
              className="settings-tabs"
              activeKey={tabKey}
              onSelect={(k) => setTabKey(k)}
            >

              <Tab eventKey="reportSettings" title="Report Settings">
                <div className='d-flex w-100 mt-3'>
                  <div className='setting-type-selector'>
                    <div className={`setting-type-selector-item ${settingTabKey === "instant" && 'active'}`} onClick={() => setSettingTabKey('instant')}>
                      Instant Reports
                    </div>
                    <div className={`setting-type-selector-item ${settingTabKey === "periodic" && 'active'}`} onClick={() => setSettingTabKey('periodic')}>
                      Periodic Reports
                    </div>
                    <div className={`setting-type-selector-item ${settingTabKey === "streamStatus" && 'active'}`} onClick={() => setSettingTabKey('streamStatus')}>
                      Stream Status Reports
                    </div>
                    {currentUser.name === "smartanalytics@chooch.com" || currentUser.name === 'choochkaiserpilot@gmail.com' ?
                      <div className={`setting-type-selector-item ${settingTabKey === "inventoryStatus" && 'active'}`} onClick={() => setSettingTabKey('inventoryStatus')}>
                        Inventory Reports
                      </div>
                      :
                      null
                    }
                  </div>
                  {settingTabKey === "instant" &&
                    <div className='instant-settings-content'>
                      {isAlertSettingsLoading ?
                        <Loader />
                        :
                        alertSettings.length > 0 ?
                          <div className='instant-settings-content-active'>
                            <div className='settings-content-header'>
                              <Button ref={questionTarget} className={`question-btn  ${questionActive ? 'active-question-mark-btn' : 'question-mark-btn'}`} onClick={() => setQuestionActive(!questionActive)}>
                                <QuestionCircle size={24} />
                              </Button>
                              <Overlay target={questionTarget.current} show={questionActive} placement="bottom-start">
                                {(props) => (
                                  <Tooltip {...props} className='manage-email-alerts'>
                                    <p style={{ fontWeight: '500', fontSize: '16px', lineHeight: '20px', marginBottom: '0.5rem' }}>Instant Reports</p>
                                    You can receive instant alert reports via email by separating them based on the stream and device.
                                  </Tooltip>
                                )}
                              </Overlay>
                              Manage E-mail Alerts
                              <Button className='purple-bordered-transparent-btn ms-auto' onClick={() => handleShowReportModal()}>
                                <PlusLg />
                                Add
                              </Button>
                            </div>
                            <Table className='alert-settings-table'>
                              <thead>
                                <tr>
                                  <th>Device</th>
                                  <th>Stream</th>
                                  <th>Alert</th>
                                  <th>Shared With</th>
                                  <th>Send E-mail</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {alertSettings.map((item, index) => {
                                  return (
                                    isEditAlertActive && item.id === selectedAlertSettingId ?
                                      <tr key={index}>
                                        <td>
                                          <Select
                                            isDisabled={true} // selectedAlertSettingId !== item.id
                                            name="deviceOptions"
                                            options={deviceOptions}
                                            className="basic-single table-select"
                                            classNamePrefix="select"
                                            placeholder='Select Device'
                                            onChange={(item) => setSelectedDevice(item)}
                                            value={isEditAlertActive && selectedAlertSettingId === item.id ? selectedDevice : deviceOptions.find(x => x.value === item.device_id)}
                                          />
                                        </td>
                                        <td>
                                          <Select
                                            isDisabled={true}
                                            name="streamOptions"
                                            options={streamOptions}
                                            className="basic-single table-select"
                                            classNamePrefix="select"
                                            placeholder='Select Stream'
                                            onChange={(item) => setSelectedStream(item)}
                                            value={isEditAlertActive && selectedAlertSettingId === item.id ? selectedStream : streamOptions.find(x => x.value === item.camera_id)}
                                          />
                                        </td>
                                        <td>
                                          <Select
                                            isDisabled={true}
                                            name="alertOptions"
                                            options={newEventOptions}
                                            className="basic-single table-select"
                                            classNamePrefix="select"
                                            placeholder='Select Alert'
                                            onChange={(item) => setSelectedEvent(item)}
                                            value={isEditAlertActive && selectedAlertSettingId === item.id ? selectedEvent : eventOptions.find(x => x.value === item.rule_id)}
                                          />
                                        </td>
                                        <td>
                                          <div className='d-flex align-items-center add-user me-2' onClick={() => showAddUserModal(item, 'alert')}>
                                            <PersonPlus size={22} color='#352B63' className='me-3' />
                                            {item.users.length > 0 ?
                                              <div className="user-count mx-1">
                                                {item.users.length}
                                                <span className='tooltiptext'>
                                                  <h5>E-mails ({item.users.length})</h5>
                                                  {item.users.map((useritem, index) => {
                                                    return <p key={index}>{useritem.email}</p>
                                                  })}
                                                </span>
                                              </div>

                                              :
                                              <div className='add-user-text'>Add User</div>
                                            }
                                          </div>
                                        </td>
                                        <td>
                                          <div className='d-flex align-items-center me-5'>
                                            <Switch
                                              className='me-2 mt-1'
                                              offColor='#9B9B9B'
                                              onColor='#8020CF'
                                              height={20}
                                              width={40}
                                              checkedIcon={false}
                                              uncheckedIcon={false}
                                              checked={item.is_active}
                                              onChange={() => handleChangeEmailActivate(!item.is_active, item.id, 'alert')}
                                            />
                                            <div className='switch-text'>{item.is_active ? 'ON' : 'OFF'}</div>
                                          </div>
                                        </td>
                                        <td>
                                          {isEditAlertActive && selectedAlertSettingId === item.id ?
                                            <div className='d-flex align-items-center justify-content-between'>
                                              <p className='mb-0 me-5 save-text' onClick={() => handleShowAlertEmailEditModal(item.id, item.is_active, 'alert')}>Save</p>
                                              <X size={30} className="discard" onClick={() => handleShowAlertEmailDiscardModal('alert')} />
                                            </div>
                                            :
                                            <Dropdown className="actions-dropdown d-inline">
                                              <Dropdown.Toggle id="dropdown-autoclose-true">
                                                <ThreeDotsVertical />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => editAlertSetting(item)}>Edit</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleShowAlertEmailDeleteModal(item.id, 'alert')}>Delete</Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          }

                                        </td>
                                      </tr>
                                      :
                                      <tr key={index}>
                                        <td>
                                          {findDeviceNameByDeviceId(item.device_id)}
                                        </td>
                                        <td>
                                          {findStreamNameByStreamId(item.camera_id)}
                                        </td>
                                        <td>
                                          {findRuleNameByRuleId(item.rule_id)}
                                        </td>
                                        <td>
                                          <div className='d-flex align-items-center add-user me-2' onClick={() => showAddUserModal(item, 'alert')}>
                                            <PersonPlus size={22} color='#352B63' className='me-3' />
                                            {item.users.length > 0 ?
                                              <div className="user-count mx-1">
                                                {item.users.length}
                                                <span className='tooltiptext'>
                                                  <h5>E-mails ({item.users.length})</h5>
                                                  {item.users.map((useritem, index) => {
                                                    return <p key={index}>{useritem.email}</p>
                                                  })}
                                                </span>
                                              </div>

                                              :
                                              <div className='add-user-text'>Add User</div>
                                            }
                                          </div>
                                        </td>
                                        <td>
                                          <div className='d-flex align-items-center me-5'>
                                            <Switch
                                              className='me-2 mt-1'
                                              offColor='#9B9B9B'
                                              onColor='#8020CF'
                                              height={20}
                                              width={40}
                                              checkedIcon={false}
                                              uncheckedIcon={false}
                                              checked={item.is_active}
                                              onChange={() => handleChangeEmailActivate(!item.is_active, item.id, 'alert')}
                                            />
                                            <div className='switch-text'>
                                              {item.device_id === -1 ? "ON" : item.is_active ? 'ON' : 'OFF'}
                                            </div>
                                          </div>
                                        </td>
                                        {item.device_id !== -1 &&
                                          <td>
                                          {isEditAlertActive && selectedAlertSettingId === item.id ?
                                            <div className='d-flex align-items-center justify-content-between'>
                                              <p className='mb-0 me-5 save-text' onClick={() => handleShowAlertEmailEditModal(item.id, item.is_active, 'alert')}>Save</p>
                                              <X size={30} className="discard" onClick={() => handleShowAlertEmailDiscardModal('alert')} />
                                            </div>
                                            :
                                            <Dropdown className="actions-dropdown d-inline">
                                              <Dropdown.Toggle id="dropdown-autoclose-true">
                                                <ThreeDotsVertical />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => editAlertSetting(item)}>Edit</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleShowAlertEmailDeleteModal(item.id, 'alert')}>Delete</Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          }

                                        </td>
                                        }
                                      </tr>
                                  )
                                })}
                              </tbody>
                            </Table>
                          </div>
                          :
                          <div className='add-first'>
                            <h5><PlusLg size={20} color='#210835' /> Add Instant Report</h5>
                            <p>You can receive instant alert reports via email by separating them based on the stream and device.</p>
                            <Button className='purple-btn' onClick={() => handleShowReportModal()}>Add Report</Button>
                          </div>
                      }
                    </div>
                  }
                  {settingTabKey === 'periodic' &&
                    <div className='instant-settings-content'>
                      {isReportSettingsLoading ?
                        <Loader />
                        :
                        reportSettings.length > 0 ?
                          <div className='instant-settings-content-active'>
                            <div className='settings-content-header'>
                              <Button ref={questionTarget} className={`question-btn  ${questionActive ? 'active-question-mark-btn' : 'question-mark-btn'}`} onClick={() => setQuestionActive(!questionActive)}>
                                <QuestionCircle size={24} />
                              </Button>
                              <Overlay target={questionTarget.current} show={questionActive} placement="bottom-start">
                                {(props) => (
                                  <Tooltip {...props} className='manage-email-alerts'>
                                    <p style={{ fontWeight: '500', fontSize: '16px', lineHeight: '20px', marginBottom: '0.5rem' }}>Periodic Reports</p>
                                    You can configure the frequency of alert reports and receive them via email. Additionally, you can organize and manage alerts by separating them based on the stream and device.
                                  </Tooltip>
                                )}
                              </Overlay>
                              Manage E-mail Alerts
                              <Button className='purple-bordered-transparent-btn ms-auto' onClick={() => handleShowPeriodicReportModal()}>
                                <PlusLg />
                                Add
                              </Button>
                            </div>
                            <Table className='alert-settings-table'>
                              <thead>
                                <tr>
                                  <th>Device</th>
                                  <th>Stream</th>
                                  <th>Alert Type</th>
                                  <th>Frequency</th>
                                  <th>Report Time</th>
                                  <th>Shared With</th>
                                  <th>Send E-mail</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {reportSettings.map((item, index) => {
                                  return (
                                    item.device_id === -1 ?
                                      <tr key={index}>
                                        <td>All Devices</td>
                                        <td>All Streams</td>
                                        <td>{findRuleNameByRuleId(item.rule_id)}</td>
                                        <td>{item.report_frequency.charAt(0).toUpperCase() + item.report_frequency.slice(1).toLowerCase()}</td>
                                        <td>
                                          {
                                            item.rule_id === -1 ?
                                              '07:00 AM UTC'
                                              :
                                              item.report_frequency === 'DAILY' ?
                                                item.report_time
                                                :
                                                item.report_frequency === 'WEEKLY' ?
                                                  item.report_date
                                                  :
                                                  null
                                          }
                                        </td>
                                        <td>
                                          <div className='d-flex align-items-center add-user me-2' onClick={() => showAddUserModal(item, 'report')}>
                                            <PersonPlus size={22} color='#352B63' className='me-3' />
                                            {item.users.length > 0 ?
                                              <div className="user-count mx-1">
                                                {item.users.length}
                                                <span className='tooltiptext'>
                                                  <h5>E-mails ({item.users.length})</h5>
                                                  {item.users.map((useritem, index) => {
                                                    return <p key={index}>{useritem.email}</p>
                                                  })}
                                                </span>
                                              </div>
                                              :
                                              <div className='add-user-text'>Add User</div>
                                            }
                                          </div>
                                        </td>
                                        <td>
                                          <div className='d-flex align-items-center me-5'>
                                            <Switch
                                              className='me-2 mt-1'
                                              offColor='#9B9B9B'
                                              onColor='#8020CF'
                                              height={20}
                                              width={40}
                                              checkedIcon={false}
                                              uncheckedIcon={false}
                                              checked={item.is_active}
                                              onChange={() => handleChangeEmailActivate(!item.is_active, item.id, 'report')}
                                            />
                                            {item.is_active ?
                                              <div className='switch-text active'>ON</div>
                                              :
                                              <div className='switch-text'>OFF</div>
                                            }
                                          </div>
                                        </td>
                                        <td></td>
                                      </tr>
                                      :
                                      isEditReportActive && item.id === selectedReportSettingId ?
                                        <tr key={index}>
                                          <td>
                                            <Select
                                              isDisabled={true}
                                              name="deviceOptions"
                                              options={deviceOptions}
                                              className="basic-single table-select"
                                              classNamePrefix="select"
                                              placeholder='Select Device'
                                              onChange={(item) => setSelectedReportDevice(item)}
                                              value={isEditReportActive && selectedReportSettingId === item.id ? selectedReportDevice : deviceOptions.find(x => x.value === item.device_id)}
                                            />
                                          </td>
                                          <td>
                                            <Select
                                              isDisabled={true}
                                              name="streamOptions"
                                              options={streamOptions}
                                              className="basic-single table-select"
                                              classNamePrefix="select"
                                              placeholder='Select Stream'
                                              onChange={(item) => setSelectedReportStream(item)}
                                              value={isEditReportActive && selectedReportSettingId === item.id ? selectedReportStream : streamOptions.find(x => x.value === item.camera_id)}
                                            />
                                          </td>

                                          <td>
                                            <Select
                                              isDisabled={true}
                                              name="alertOptions"
                                              options={eventOptions}
                                              className="basic-single table-select"
                                              classNamePrefix="select"
                                              placeholder='Select Alert Type'
                                              onChange={(item) => setSelectedReportAlert(item)}
                                              value={isEditReportActive && selectedReportSettingId === item.id ? selectedReportAlert : eventOptions.find(x => x.value === item.rule_id)}
                                            />
                                          </td>
                                          <td>
                                            <Select
                                              isDisabled={selectedReportSettingId !== item.id}
                                              isSearchable={false}
                                              name="frequencyOptions"
                                              options={frequencyOptions}
                                              className="basic-single table-select"
                                              classNamePrefix="select"
                                              placeholder='Select Frequency'
                                              onChange={(item) => setSelectedFrequency(item)}
                                              value={
                                                isEditReportActive && selectedReportSettingId === item.id ?
                                                  selectedFrequency
                                                  :
                                                  item.report_frequency === "DAILY" ?
                                                    {
                                                      label: 'Daily',
                                                      value: 'DAILY'
                                                    }
                                                    :
                                                    item.report_frequency === "WEEKLY" ?
                                                      {
                                                        label: 'Every Monday',
                                                        value: 'WEEKLY'
                                                      }
                                                      :
                                                      item.report_frequency === "MONTHLY" ?
                                                        {
                                                          label: 'Monthly',
                                                          value: 'MONTHLY'
                                                        }
                                                        :
                                                        null
                                              }
                                            />
                                          </td>
                                          <td>
                                            {
                                              selectedFrequency?.value === 'DAILY' ?
                                                <Select
                                                  options={getHoursInDay()}
                                                  className="basic-single table-select"
                                                  classNamePrefix="select"
                                                  placeholder={item.report_time?.slice(0, 5) || 'Select Time'}
                                                  onChange={(item) => setSelectedReportTime(item)}
                                                  value={getHoursInDay().find(x => x.value === selectedReportTime.value)}
                                                />
                                               : null
                                            }
                                            {
                                              selectedFrequency?.value === 'WEEKLY' &&
                                              <Select
                                                isDisabled={selectedReportAlert === null}
                                                name="weeklyOptions"
                                                options={weeklyOptions}
                                                className="basic-single table-select"
                                                classNamePrefix="select"
                                                placeholder="Select a Day"
                                                onChange={(item) => setSelectedWeekly(item)}
                                                value={weeklyOptions.find(w => w.value === selectedWeekly.value)}
                                              />
                                            }
                                          </td>
                                          <td>
                                            <div className='d-flex align-items-center add-user me-2' onClick={() => showAddUserModal(item, 'report')}>
                                              <PersonPlus size={22} color='#352B63' className='me-3' />
                                              {item.users.length > 0 ?
                                                <div className="user-count mx-1">
                                                  {item.users.length}
                                                  <span className='tooltiptext'>
                                                    <h5>E-mails ({item.users.length})</h5>
                                                    {item.users.map((useritem, index) => {
                                                      return <p key={index}>{useritem.email}</p>
                                                    })}
                                                  </span>
                                                </div>
                                                :
                                                <div className='add-user-text'>Add User</div>
                                              }
                                            </div>
                                          </td>
                                          <td>
                                            <div className='d-flex align-items-center me-5'>
                                              <Switch
                                                className='me-2 mt-1'
                                                offColor='#9B9B9B'
                                                onColor='#8020CF'
                                                height={20}
                                                width={40}
                                                checkedIcon={false}
                                                uncheckedIcon={false}
                                                checked={item.is_active}
                                                onChange={() => handleChangeEmailActivate(!item.is_active, item.id, 'report')}
                                              />
                                              {
                                                item.is_active ?
                                                  <div className='switch-text active'>ON</div>
                                                  :
                                                  <div className='switch-text'>OFF</div>
                                              }
                                            </div>
                                          </td>
                                          <td>
                                            {isEditReportActive && selectedReportSettingId === item.id ?
                                              <div className='d-flex align-items-center justify-content-between'>
                                                <p className='mb-0 me-5 save-text' onClick={() => handleShowAlertEmailEditModal(item.id, item.is_active, 'report')}>Save</p>
                                                <X size={30} className="discard" onClick={() => handleShowAlertEmailDiscardModal('report')} />
                                              </div>
                                              :
                                              <Dropdown className="actions-dropdown d-inline">
                                                <Dropdown.Toggle id="dropdown-autoclose-true">
                                                  <ThreeDotsVertical />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                  <Dropdown.Item onClick={() => editReportSetting(item)}>Edit</Dropdown.Item>
                                                  <Dropdown.Item onClick={() => handleShowAlertEmailDeleteModal(item.id, 'report')}>Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            }
                                          </td>
                                        </tr>
                                        :
                                        <tr key={index}>
                                          <td>
                                            {findDeviceNameByDeviceId(item.device_id)}
                                          </td>
                                          <td>
                                            {findStreamNameByStreamId(item.camera_id)}
                                          </td>
                                          <td>
                                            {findRuleNameByRuleId(item.rule_id)}
                                          </td>
                                          <td>
                                            {frequencyOptions.find(x => x.value === item.report_frequency)?.label}
                                          </td>
                                          <td>
                                            {
                                              item.report_frequency === 'DAILY' ?
                                                item.report_time ? item.report_time.slice(0, 5) : '07:00 AM UTC'
                                                :
                                                item.report_frequency === 'WEEKLY' ?
                                                  item.report_date
                                                  :
                                                  '07:00 AM UTC'
                                            }
                                          </td>
                                          <td>
                                            <div className='d-flex align-items-center add-user me-2' onClick={() => showAddUserModal(item, 'report')}>
                                              <PersonPlus size={22} color='#352B63' className='me-3' />
                                              {item.users.length > 0 ?
                                                <div className="user-count mx-1">
                                                  {item.users.length}
                                                  <span className='tooltiptext'>
                                                    <h5>E-mails ({item.users.length})</h5>
                                                    {item.users.map((useritem, index) => {
                                                      return <p key={index}>{useritem.email}</p>
                                                    })}
                                                  </span>
                                                </div>

                                                :
                                                <div className='add-user-text'>Add User</div>
                                              }
                                            </div>
                                          </td>
                                          <td>
                                            <div className='d-flex align-items-center me-5'>
                                              <Switch
                                                className='me-2 mt-1'
                                                offColor='#9B9B9B'
                                                onColor='#8020CF'
                                                height={20}
                                                width={40}
                                                checkedIcon={false}
                                                uncheckedIcon={false}
                                                checked={item.is_active}
                                                onChange={() => handleChangeEmailActivate(!item.is_active, item.id, 'report')}
                                              />
                                              {
                                               item.is_active ?
                                                  <div className='switch-text active'>ON</div>
                                                  :
                                                  <div className='switch-text'>OFF</div>
                                              }
                                            </div>
                                          </td>
                                          <td>
                                            {isEditAlertActive && selectedAlertSettingId === item.id ?
                                              <div className='d-flex align-items-center justify-content-between'>
                                                <p className='mb-0 me-5 save-text' onClick={() => handleShowAlertEmailEditModal(item.id, item.is_active, 'report')}>Save</p>
                                                <X size={30} className="discard" onClick={() => handleShowAlertEmailDiscardModal('report')} />
                                              </div>
                                              :
                                              <Dropdown className="actions-dropdown d-inline">
                                                <Dropdown.Toggle id="dropdown-autoclose-true">
                                                  <ThreeDotsVertical />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                  <Dropdown.Item onClick={() => editReportSetting(item)}>Edit</Dropdown.Item>
                                                  <Dropdown.Item onClick={() => handleShowAlertEmailDeleteModal(item.id, 'report')}>Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            }

                                          </td>
                                        </tr>
                                  )
                                })}
                                {isAddReportActive ?
                                  <tr>
                                    <td>
                                      <Select
                                        defaultMenuIsOpen
                                        name="deviceOptions"
                                        options={deviceOptions}
                                        className="basic-single table-select"
                                        classNamePrefix="select"
                                        placeholder='Select Device'
                                        onChange={(item) => setSelectedReportDevice(item)}
                                        value={selectedReportDevice}
                                      />
                                    </td>
                                    <td>
                                      <Select
                                        isDisabled={selectedReportDevice === null}
                                        name="streamOptions"
                                        options={streamOptions}
                                        className="basic-single table-select"
                                        classNamePrefix="select"
                                        placeholder='Select Stream'
                                        onChange={(item) => setSelectedReportStream(item)}
                                        value={selectedReportStream}
                                      />
                                    </td>
                                    <td>
                                      <Select
                                        isDisabled={selectedReportStream === null}
                                        name="alertOptions"
                                        options={alertOptions}
                                        className="basic-single table-select"
                                        classNamePrefix="select"
                                        placeholder='Select Alert'
                                        onChange={(item) => setSelectedReportAlert(item)}
                                        value={selectedReportAlert}
                                      />
                                    </td>
                                    <td>
                                      <Select
                                        isDisabled={selectedReportAlert === null}
                                        isSearchable={false}
                                        name="frequencyOptions"
                                        options={frequencyOptions}
                                        className="basic-single table-select"
                                        classNamePrefix="select"
                                        placeholder='Select Frequency'
                                        onChange={(item) => setSelectedFrequency(item)}
                                        value={selectedFrequency}
                                      />
                                    </td>
                                    <td>
                                      <div className='d-flex align-items-center add-user me-2'>
                                        <PersonPlus size={22} color='#352B63' className='me-3' />
                                        <div className='add-user-text'>Add User</div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className='d-flex align-items-center me-5'>
                                        <Switch
                                          className='me-2 mt-1'
                                          offColor='#9B9B9B'
                                          onColor='#8020CF'
                                          height={20}
                                          width={40}
                                          checkedIcon={false}
                                          uncheckedIcon={false}
                                          checked={false}
                                          onChange={() => handleChangeEmailActivate(true, selectedReportSettingId, 'report')}
                                        />
                                        <div className='switch-text'>OFF</div>
                                      </div>
                                    </td>
                                    <td>
                                      <X size={22} className="discard" onClick={clearAddReportFields} />
                                    </td>
                                  </tr>
                                  :
                                  null
                                }
                              </tbody>
                            </Table>
                          </div>
                          :
                          <div className='add-first'>
                            <h5><PlusLg size={20} color='#210835' /> Add Periodic Report</h5>
                            <p>You can configure the frequency of alert reports and receive them via email. Additionally, you can organize and manage alerts by separating them based on the stream and device.</p>
                            <Button className='purple-btn' onClick={() => handleShowPeriodicReportModal()}>Add Report</Button>
                          </div>
                      }
                    </div>
                  }
                  {settingTabKey === 'streamStatus' &&
                    <div className='instant-settings-content'>
                      {isStreamSettingsLoading ?
                        <Loader />
                        :
                        <div className='instant-settings-content-active'>
                          <div className='settings-content-header'>
                            <Button ref={questionTarget} className={`question-btn  ${questionActive ? 'active-question-mark-btn' : 'question-mark-btn'}`} onClick={() => setQuestionActive(!questionActive)}>
                              <QuestionCircle size={24} />
                            </Button>
                            <Overlay target={questionTarget.current} show={questionActive} placement="bottom-start">
                              {(props) => (
                                <Tooltip {...props} className='manage-email-alerts'>
                                  <p style={{ fontWeight: '500', fontSize: '16px', lineHeight: '20px', marginBottom: '0.5rem' }}>Stream Status Reports</p>
                                  You can control whether to receive all emails related to the selected device and streams, such as informational and alert messages.
                                </Tooltip>
                              )}
                            </Overlay>
                            Manage E-mail Alerts
                            {/*<Button className='purple-bordered-transparent-btn ms-auto' onClick={() => handleShowReportModal()}>
                              <PlusLg />
                              Add
                            </Button>*/}
                          </div>
                          <Table className='alert-settings-table'>
                            <thead>
                              <tr>
                                <th>Device</th>
                                <th>Stream</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>Send E-mail</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {/*{alertSettings.map((item, index) => {
                              return (
                                item.device_id == "-1" ?
                                  <tr key={index}>
                                    <td>All Devices</td>
                                    <td>All Streams</td>
                                    <td>
                                      <div className='d-flex align-items-center me-5'>
                                        <Switch
                                          className='me-2 mt-1'
                                          offColor='#9B9B9B'
                                          onColor='#8020CF'
                                          height={20}
                                          width={40}
                                          checkedIcon={false}
                                          uncheckedIcon={false}
                                          checked={item.is_active}
                                          onChange={() => handleChangeEmailActivate(!item.is_active, item.id, 'alert')}
                                        />
                                        {item.is_active ?
                                          <div className='switch-text active'>ON</div>
                                          :
                                          <div className='switch-text'>OFF</div>
                                        }
                                      </div>
                                    </td>
                                    <td></td>
                                  </tr>
                                  :
                                  isEditAlertActive && item.id ===  selectedAlertSettingId ?
                                    <tr key={index}>
                                      <td>
                                        <Select
                                          isDisabled={selectedAlertSettingId !== item.id}
                                          name="deviceOptions"
                                          options={deviceOptions}
                                          className="basic-single table-select"
                                          classNamePrefix="select"
                                          placeholder='Select Device'
                                          onChange={(item) => setSelectedDevice(item)}
                                          value={isEditAlertActive && selectedAlertSettingId === item.id ? selectedDevice : deviceOptions.find(x => x.value === item.device_id)}
                                        />
                                      </td>
                                      <td>
                                        <Select
                                          isDisabled={selectedAlertSettingId !== item.id}
                                          name="streamOptions"
                                          options={streamOptions}
                                          className="basic-single table-select"
                                          classNamePrefix="select"
                                          placeholder='Select Stream'
                                          onChange={(item) => setSelectedStream(item)}
                                          value={isEditAlertActive && selectedAlertSettingId === item.id ? selectedStream : streamOptions.find(x => x.value === item.camera_id)}
                                        />
                                      </td>
                                      <td>
                                        <Select
                                          isDisabled={selectedAlertSettingId !== item.id}
                                          name="alertOptions"
                                          options={eventOptions}
                                          className="basic-single table-select"
                                          classNamePrefix="select"
                                          placeholder='Select Alert'
                                          onChange={(item) => setSelectedEvent(item)}
                                          value={isEditAlertActive && selectedAlertSettingId === item.id ? selectedEvent : eventOptions.find(x => x.value === item.rule_id)}
                                        />
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center add-user me-2' onClick={() => showAddUserModal(item, 'alert')}>
                                          <PersonPlus size={22} color='#352B63' className='me-3' />
                                          {item.users.length > 0 ?
                                            <div className="user-count mx-1">
                                              {item.users.length}
                                              <span className='tooltiptext'>
                                          <h5>E-mails</h5>
                                                {item.users.map((useritem, index) => {
                                                  return <p key={index}>{useritem.email}</p>
                                                })}
                                        </span>
                                            </div>

                                            :
                                            <div className='add-user-text'>Add User</div>
                                          }
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center me-5'>
                                          <Switch
                                            className='me-2 mt-1'
                                            offColor='#9B9B9B'
                                            onColor='#8020CF'
                                            height={20}
                                            width={40}
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                            checked={item.is_active}
                                            onChange={() => handleChangeEmailActivate(!item.is_active, item.id, 'alert')}
                                          />
                                          <div className='switch-text'>{item.is_active ? 'ON' : 'OFF'}</div>
                                        </div>
                                      </td>
                                      <td>
                                        {isEditAlertActive && selectedAlertSettingId === item.id ?
                                          <div className='d-flex align-items-center justify-content-between'>
                                            <p className='mb-0 me-5 save-text' onClick={() => handleShowAlertEmailEditModal(item.id, item.is_active, 'alert')}>Save</p>
                                            <X size={30} className="discard" onClick={() => handleShowAlertEmailDiscardModal('alert')} />
                                          </div>
                                          :
                                          <Dropdown className="actions-dropdown d-inline">
                                            <Dropdown.Toggle id="dropdown-autoclose-true">
                                              <ThreeDotsVertical />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item onClick={() => editAlertSetting(item)}>Edit</Dropdown.Item>
                                              <Dropdown.Item onClick={() => handleShowAlertEmailDeleteModal(item.id, 'alert')}>Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        }

                                      </td>
                                    </tr>
                                    :
                                    <tr key={index}>
                                      <td>
                                        {findDeviceNameByDeviceId(item.device_id)}
                                      </td>
                                      <td>
                                        {findStreamNameByStreamId(item.camera_id)}
                                      </td>
                                      <td>
                                        {findRuleNameByRuleId(item.rule_id)}
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center add-user me-2' onClick={() => showAddUserModal(item, 'alert')}>
                                          <PersonPlus size={22} color='#352B63' className='me-3' />
                                          {item.users.length > 0 ?
                                            <div className="user-count mx-1">
                                              {item.users.length}
                                              <span className='tooltiptext'>
                                                <h5>E-mails</h5>
                                                {item.users.map((useritem, index) => {
                                                  return <p key={index}>{useritem.email}</p>
                                                })}
                                              </span>
                                            </div>

                                            :
                                            <div className='add-user-text'>Add User</div>
                                          }
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center me-5'>
                                          <Switch
                                            className='me-2 mt-1'
                                            offColor='#9B9B9B'
                                            onColor='#8020CF'
                                            height={20}
                                            width={40}
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                            checked={item.is_active}
                                            onChange={() => handleChangeEmailActivate(!item.is_active, item.id, 'alert')}
                                          />
                                          <div className='switch-text'>{item.is_active ? 'ON' : 'OFF'}</div>
                                        </div>
                                      </td>
                                      <td>
                                        {isEditAlertActive && selectedAlertSettingId === item.id ?
                                          <div className='d-flex align-items-center justify-content-between'>
                                            <p className='mb-0 me-5 save-text' onClick={() => handleShowAlertEmailEditModal(item.id, item.is_active, 'alert')}>Save</p>
                                            <X size={30} className="discard" onClick={() => handleShowAlertEmailDiscardModal('alert')} />
                                          </div>
                                          :
                                          <Dropdown className="actions-dropdown d-inline">
                                            <Dropdown.Toggle id="dropdown-autoclose-true">
                                              <ThreeDotsVertical />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item onClick={() => editAlertSetting(item)}>Edit</Dropdown.Item>
                                              <Dropdown.Item onClick={() => handleShowAlertEmailDeleteModal(item.id, 'alert')}>Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        }

                                      </td>
                                    </tr>
                              )
                            })}*/}

                              {
                                streamSettings ?
                                  <tr key={1}>
                                    <td>All Devices</td>
                                    <td>All Streams</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <div className='d-flex align-items-center me-5'>
                                        <Switch
                                          className='me-2 mt-1'
                                          offColor='#9B9B9B'
                                          onColor='#8020CF'
                                          height={20}
                                          width={40}
                                          checkedIcon={false}
                                          uncheckedIcon={false}
                                          checked={streamSettings?.stream_status}
                                          onChange={() => handleStreamStatusChangeEmailActivate(!streamSettings?.stream_status)}
                                        />
                                        {streamSettings?.stream_status ?
                                          <div className='switch-text active'>ON</div>
                                          :
                                          <div className='switch-text'>OFF</div>
                                        }
                                      </div>
                                    </td>
                                    <td></td>
                                  </tr>
                                  :
                                  null
                              }
                            </tbody>
                          </Table>
                        </div>
                      }
                    </div>

                  }
                  {settingTabKey === 'inventoryStatus' &&
                    <div className='instant-settings-content'>
                      {isInventorySettingsLoading ?
                        <Loader />
                        :
                        inventorySettings.length > 0 ?
                          <div className="instant-settings-content-active">
                            <div className="settings-content-header">
                              <Button ref={questionTarget} className={`question-btn  ${questionActive ? 'active-question-mark-btn' : 'question-mark-btn'}`} onClick={() => setQuestionActive(!questionActive)}>
                                <QuestionCircle size={24} />
                              </Button>
                              <Overlay target={questionTarget.current} show={questionActive} placement="bottom-start">
                                {(props) => (
                                  <Tooltip {...props} className="manage-email-alerts">
                                    <p style={{ fontWeight: '500', fontSize: '16px', lineHeight: '20px', marginBottom: '0.5rem' }}>
                                      Inventory Reports
                                    </p>
                                    You can configure the frequency of inventory reports and receive them via email.
                                  </Tooltip>
                                )}
                              </Overlay>
                              Manage E-mail Alerts
                              <Button className="purple-bordered-transparent-btn ms-auto" onClick={() => handleShowInventoryReportModal()}>
                                <PlusLg />
                                Add
                              </Button>
                            </div>

                            {/*<div className="d-flex m-3 justify-content-between align-items-center">
                              <Select
                                name="frequencyOptions"
                                options={incentoryFrequencyOptions}
                                className="basic-single inventory-frequency-select"
                                classNamePrefix="select"
                                placeholder="Select Frequency"
                                //onChange={(item) => setSelectedFrequency(item)}
                                //value={props.frequency.find(f => f.value === selectedFrequency?.value)}
                              />
                              <div className="d-flex">
                                <Form.Group className="me-3">
                                  <Form.Control type="text" placeholder="Enter e-mail"></Form.Control>
                                </Form.Group>
                                <Button className="purple-bordered-transparent-btn">Add Email</Button>
                              </div>
                            </div>*/}

                            {/*<div className="inventory-user-list">
                              <div className="inventory-user-item">
                                <div>choochkaiserpilot@gmail.com</div>
                                <X size={24}/>
                              </div>
                              <div className="inventory-user-item">
                                <div>test@chooch.com</div>
                                <X size={24}/>
                              </div>
                              {inventorySettings[0].users.map((item, index) => {
                                return (
                                  <div key={index} className="inventory-user-item">
                                    <div>{item.email}</div>
                                    <X size={24} onClick={() => editReportSetting(item)}/>
                                  </div>
                                )
                              })}
                            </div>*/}

                            <Table className="alert-settings-table">
                              <thead>
                                <tr>
                                  <th>Frequency</th>
                                  <th>Shared With
                                  </th>
                                  <th>Send E-mail
                                  </th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {inventorySettings.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        {
                                          isEditReportActive ?

                                            <Select
                                              isDisabled={selectedReportAlert !== null}
                                              isSearchable={true}
                                              name="frequencyOptions"
                                              options={frequencyOptions}
                                              className="basic-single table-select"
                                              classNamePrefix="select"
                                              placeholder="Select Frequency"
                                              onChange={(item) => setSelectedFrequency(item)}
                                              value={selectedFrequency ? selectedFrequency : frequencyOptions.find(x => x.value === item.report_frequency)}
                                            />
                                            :
                                            item.report_frequency === 'MONTHLY' ?
                                              'Monthly'
                                              :
                                              item.report_frequency === 'WEEKLY' ?
                                                'Weekly'
                                                :
                                                item.report_frequency === 'DAILY' ?
                                                  'Daily - 10:30 AM PST'
                                                  :
                                                  null
                                        }
                                      </td>
                                      <td>
                                        <div className="d-flex align-items-center add-user me-2" onClick={() => showAddUserModal(item, 'report')}>
                                          <PersonPlus size={22} color="#352B63" className="me-3" />
                                          {item.users.length > 0 ?
                                            <div className="user-count mx-1">
                                              {item.users.length}
                                              <span className="tooltiptext">
                                                <h5>E-mails ({item.users.length})</h5>
                                                {item.users.map((useritem, index) => {
                                                  return <p key={index}>{useritem.email}</p>;
                                                })}
                                              </span>
                                            </div>
                                            :
                                            <div className="add-user-text">Add User</div>
                                          }
                                        </div>
                                      </td>
                                      <td>
                                        <div className="d-flex align-items-center me-5">
                                          <Switch
                                            className="me-2 mt-1"
                                            offColor="#9B9B9B"
                                            onColor="#8020CF"
                                            height={20}
                                            width={40}
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                            checked={item.is_active}
                                            onChange={() => handleChangeEmailActivate(!item.is_active, item.id, 'report')}
                                          />
                                          {item.is_active ?
                                            <div className="switch-text active">ON</div>
                                            :
                                            <div className="switch-text">OFF</div>
                                          }
                                        </div>
                                      </td>
                                      <td>
                                        {/*<Dropdown className="actions-dropdown d-inline">
                                        <Dropdown.Toggle id="dropdown-autoclose-true">
                                          <ThreeDotsVertical />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item onClick={() => editInventorySetting(item)}>Edit</Dropdown.Item>
                                          <Dropdown.Item onClick={() => handleShowAlertEmailDeleteModal(item.id, 'report')}>Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>*/}
                                        {isEditReportActive ?
                                          <div className="d-flex align-items-center justify-content-between">
                                            <p className="mb-0 me-5 save-text" onClick={() => handleShowInventoryEditModal(item.id, item.is_active, 'report')}>Save</p>
                                            <X size={30} className="discard" onClick={() => handleShowAlertEmailDiscardModal('report')} />
                                          </div>
                                          :
                                          <Dropdown className="actions-dropdown d-inline">
                                            <Dropdown.Toggle id="dropdown-autoclose-true">
                                              <ThreeDotsVertical />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item onClick={() => editInventorySetting(item)}>Edit</Dropdown.Item>
                                              <Dropdown.Item onClick={() => handleShowAlertEmailDeleteModal(item.id, 'report')}>Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        }
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                          :
                          <div className="add-first">
                            <h5>
                              <PlusLg size={20} color="#210835" /> Add Inventory Reports
                            </h5>
                            <p>You can configure the frequency of inventory reports and receive them via email.</p>
                            <Button className="purple-btn" onClick={() => handleShowInventoryReportModal()}>
                              Add Report
                            </Button>
                          </div>
                      }
                    </div>
                  }
                </div>
              </Tab>

              <Tab eventKey="timezone" title="Time Zone">
                <div className="tab-content-box mt-2">
                  <p className="select-info-text mt-3">Select
                    a
                    city
                    in
                    your
                    time
                    zone
                    or
                    a
                    UTC
                    time
                    zone.</p>
                  <div className="d-flex align-items-center">
                    <Select
                      name="offsetTzOptions"
                      options={offsetTmz}
                      className="basic-single timezone-select"
                      classNamePrefix="select"
                      placeholder="Select Timezone"
                      onChange={(item) => handleTimezoneSelect(item)}
                      value={timezone}
                    />
                    <p className="time-text">Local
                      Time: <span>{moment().tz(timezone.value).format('YYYY-MM-DD HH:mm:ss')}</span>
                    </p>
                    <p className="time-text">Universal
                      Time: <span>{moment().utc().format('YYYY-MM-DD HH:mm:ss')}</span>
                    </p>
                  </div>
                </div>
              </Tab>

              {currentUser.name === "muhammed.ali@chooch.com" || currentUser.name === "hsayil@biltim.com.tr" || currentUser.name === "emre.gursoy@chooch.com" || currentUser.name === "hakan.akinci@chooch.com" ?
                <Tab eventKey="realTimeSettings" title="Real-Time Settings">
                  <RealTimeSettings />
                </Tab>
                :
                null
              }

            </Tabs>
          </div>

        </div>
      </Row>

      <AddUserModal
        show={showAddUser}
        type={userModalType}
        handleClose={closeAddUserModal}
        handleDone={doneAddUserModal}
        addedUserList={addedUserList}
        settingId={selectedAlertSettingId}
      />

      <AlertEmailActivateModal
        show={showAlertEmailActivateModal}
        type={activateModalType}
        handleClose={handleCloseAlertEmailActivateModal}
        activateEmail={activateEmail}
      />

      <AlertEmailDeactivateModal
        show={showAlertEmailDeactivateModal}
        type={activateModalType}
        handleClose={handleCloseAlertEmailDeactivateModal}
        deactivateEmail={deactivateEmail}
      />

      <StreamStatusEmailActiveModal
        show={showStreamStatusEmailActivateModal}
        handleClose={handleCloseStreamStatusEmailActivateModal}
        activateEmail={activateStreamStatusEmail}
      />

      <StreamStatusEmailDeactivateModal
        show={showStreamStatusEmailDeactivateModal}
        handleClose={handleCloseStreamStatusEmailDeactivateModal}
        deactivateEmail={deactivateStreamStatusEmail}
      />

      <AlertEmailDeleteModal
        show={showAlertEmailDeleteModal}
        type={deleteModalType}
        handleClose={handleCloseAlertEmailDeleteModal}
        applyDelete={applyDelete}
        settingId={selectedAlertSettingId}
      />

      <AlertEmailEditModal
        show={showAlertEmailEditModal}
        type={editModalType}
        handleClose={handleCloseAlertEmailEditModal}
        settingId={selectedAlertSettingId}
        reportTime={selectedReportTime}
        reportDate={selectedWeekly}
        isActive={selectedAlertSettingIsActive}
        applyEdit={saveEditChanges}
      />

      <AlertEmailDiscardModal
        show={showAlertEmailDiscardModal}
        type={discardModalType}
        handleClose={handleCloseAlertEmailDiscardModal}
        applyDiscard={discardEditChanges}
      />

      <AddReportModal
        show={showAddReportModal}
        handleClose={handleCloseReportModal}
        handleDone={addAlertSetting}
      />

      <AddPeriodicReportModal
        show={showAddPeriodicReportModal}
        handleClose={handleClosePeriodicReportModal}
        handleDone={addPeriodicReport}
        frequency={frequencyOptions}
      />

      <AddInventoryReportModal
        show={showInventoryReportModal}
        handleClose={handleCloseInventoryReportModal}
        frequencyOptions={inventoryFrequencyOptions}
        handleDone={addPeriodicReport}
        inventorySettings={inventorySettings}
      />

      <InventoryEditModal
        show={showInventoryEditModal}
        handleClose={handleCloseInventoryEditModal}
        settingId={selectedReportSettingId}
        isActive={selectedAlertSettingIsActive}
        applyEdit={saveInventoryEditChanges}
      />

    </Container>
  )
}
