import React, { useEffect, useState, useRef } from 'react'
import { Table, Container, Row, Button, Accordion, OverlayTrigger } from 'react-bootstrap'
import { ChevronDown, Search, Upload, Filter, ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom'
import { fast_api_axios, dashboard_axios } from '../../utils/axiosFast';
import moment from 'moment';
import { useToast } from '../../contexts/ToastContext';
import Loader from '../Loader';
import NoDataModal from './NoDataModal';
import Select from 'react-select';
import { useHistory } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext';
import Chart from "react-apexcharts";
import { DateRangePicker as RDateRangePicker } from 'rsuite';
import NotFound from '../../assets/images/restricted.png'
import momentTimezone from 'moment-timezone';
import {
  findClassTitleByRuleId
} from '../../utils/generic';

const {
  afterToday,
  allowedMaxDays,
  combine
} = RDateRangePicker;


export default function Trends() {
  momentTimezone.tz.setDefault(localStorage.getItem('timezone'));
  const { logout } = useAuth();
  const deviceData = JSON.parse(localStorage.getItem('deviceData'))
  const streamData = JSON.parse(localStorage.getItem('streamData'))
  const zoneData = JSON.parse(localStorage.getItem('zoneData'))
  const eventData = JSON.parse(localStorage.getItem('eventData'))
  const allEventData = JSON.parse(localStorage.getItem('allEventData'))

  const [granularity, setGranularity] = useState({
    'label': "Hourly",
    'value': "hour"
  });
  const { updateMessage } = useToast()
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isTrendsLoading, setIsTrendsLoading] = useState(false);
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [isPieLoading, setIsPieLoading] = useState(true);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [pieDataStreams, setPieDataStreams] = useState([]);
  const [pieDataAlerts, setPieDataAlerts] = useState([]);
  const [pieDataStreamsLabel, setPieDataStreamsLabel] = useState([]);
  const [pieDataAlertsLabel, setPieDataAlertsLabel] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartDraw, setChartDraw] = useState([]);
  const [isApplyActive, setIsApplyActive] = useState(false);

  const [chartDataset, setChartDataset] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    'start': localStorage.getItem('filterObj') !== null ? moment(JSON.parse(localStorage.getItem('filterObj')).start) : moment().startOf('day'),
    'end': localStorage.getItem('filterObj') !== null ? moment(JSON.parse(localStorage.getItem('filterObj')).end) : moment()
  })
  /*const [dateFilter, setDateFilter] = useState({
    'start': moment.tz(localStorage.getItem('timezone')).startOf('day'),
    'end': moment.tz(localStorage.getItem('timezone'))
  })*/
  /*const [dateFilter, setDateFilter] = useState({
    'start': moment().startOf('day'),
    'end': moment()
  })*/
  const [filterDevices, setFilterDevices] = useState(localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).devices : []);
  const [filterStreams, setFilterStreams] = useState(localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).streams : []);
  const [filterZones, setFilterZones] = useState(localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).zones : []);
  const [filterEvents, setFilterEvents] = useState(localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).events : []);
  const [showNoDataModal, setShowNoDataModal] = useState(false);
  const [filterObj, setFilterObj] = useState({
    'start': localStorage.getItem('filterObj') !== null ? moment(JSON.parse(localStorage.getItem('filterObj')).start) : moment().startOf('day'),
    'end': localStorage.getItem('filterObj') !== null ? moment(JSON.parse(localStorage.getItem('filterObj')).end) : moment(),
    'devices': localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).devices : [],
    'streams': localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).streams : [],
    'zones': localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).zones : [],
    'events': localStorage.getItem('filterObj') !== null ? JSON.parse(localStorage.getItem('filterObj')).events : [],
  });
  const [hourlySelectedDay, setHourlySelectedDay] = useState(moment(dateFilter['start']).startOf('day'));


  const [noData, setNodata] = useState(false);
  const handleCloseNoDataModal = () => setShowNoDataModal(false);

  let history = useHistory();

  const pieColors = [
    '#8020CF',
    '#22FCDB',
    '#0C0043',
    '#FF00F8',
    '#FFD000',
    '#1F54CF',
    '#D47235'
  ]

  const lineData = {
    labels: chartLabels,
    datasets: chartDataset
  };

  const handleDeviceSelect = (items) => {
    setFilterDevices(items);
  };
  const handleStreamSelect = (items) => {
    setFilterStreams(items);
  };
  const handleZoneSelect = (items) => {
    setFilterZones(items);
  };
  const handleEventSelect = (items) => {
    setFilterEvents(items);
  };


  const getPieData = () => {
    let start = moment(dateFilter['start']).unix()
    let end = moment(dateFilter['end']).unix()
    let devices = filterDevices.map(device => device.value);
    let cameras = filterStreams.map(camera => camera.value);

    const allZoneData = [];
    JSON.parse(localStorage.getItem("assets")).forEach((device) => {
      device.cameras.forEach((camera) => {
        camera.zones.forEach((zone) => {
          if (allZoneData.find(o => o.value === zone.zone_id) === undefined) {
            allZoneData.push({
              value: zone.zone_id,
              label: zone.zone,
            });
          }
        });
      });
    });

    let selectedZoneIds = [];
    filterZones.forEach((item) => {
      var newSelectedZones = allZoneData.filter((ch) => ch.label === item.label);
      newSelectedZones.forEach((item) => {
        selectedZoneIds.push(item);
      })
    })

    let zones = selectedZoneIds.map(zone => zone.value);


    const allEventData = [];
    JSON.parse(localStorage.getItem("assets")).forEach((device) => {
      device.cameras.forEach((camera) => {
        camera.zones.forEach((zone) => {
          zone.rules.forEach((rule) => {
            if (!allEventData.some(e => e.value === rule.rule_id)) {
              allEventData.push(
                {
                  value: rule.rule_id,
                  label: rule.event,
                }
              )
            }
          });
        });
      });
    });

    let selectedEventIds = [];
    filterEvents.forEach((item) => {
      var newSelectedEvents = allEventData.filter((ch) => ch.label === item.label);
      newSelectedEvents.forEach((item) => {
        selectedEventIds.push(item);
      })
    })

    let rules = selectedEventIds.map(rule => rule.value);

    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/topn/',
        params: {
          "start": start,
          "end": end,
          "devices": devices.length > 0 ? devices.toString() : null,
          "cameras": cameras.length > 0 ? cameras.toString() : null,
          "zones": zones.length > 0 ? zones.toString() : null,
          "rules": rules.length > 0 ? rules.toString() : null,
        }
      })
      .then(res => {

        var event_camera_dict = {};
        JSON.parse(localStorage.getItem("assets")).forEach((device) => {
          device.cameras.forEach((camera) => {
            camera.zones.forEach((zone) => {
              zone.rules.forEach((rule) => {
                event_camera_dict[rule.rule_id] = camera.camera_name;
              });
            });
          });
        });


        let myStreams = res.data.cameras.filter(function (element) {
          return streamData.find(x => x.value === element.camera_id) && element
        });

        let myAlerts = res.data.alerts.filter(function (element) {
          return allEventData.find(x => x.value === element.rule_id) && element
        });
        setPieDataStreams(myStreams.sort((a, b) => b.count - a.count).slice(0, 5).map(a => a.count))
        setPieDataAlerts(myAlerts.sort((a, b) => b.count - a.count).slice(0, 5).map(a => a.count))
        setPieDataStreamsLabel(myStreams.sort((a, b) => b.count - a.count).slice(0, 5).map(a => streamData.find(x => x.value === a.camera_id) ? streamData.find(x => x.value === a.camera_id).label : '-'))
        setPieDataAlertsLabel(myAlerts.sort((a, b) => b.count - a.count).slice(0, 5).map(a => allEventData.find(x => x.value === a.rule_id) ? allEventData.find(x => x.value === a.rule_id).label + ' - ' + findClassTitleByRuleId(a?.rule_id) + ' - ' + event_camera_dict[allEventData.find(x => x.value === a.rule_id).value] : '-'))

        setIsPieLoading(false)
      })
      .catch(error => {
        console.log(error)
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getChartData = (graVal, hsd) => {
    setIsChartLoading(true);
    let start = moment(dateFilter['start']).unix()
    let end = graVal === 'hour' ? moment(dateFilter['end']).format('MM/DD/YYYY') === moment(hsd).format('MM/DD/YYYY') ? moment(dateFilter['end']).unix() : moment(hsd).endOf('day').unix() : moment(dateFilter['end']).unix()
    let devices = filterDevices.map(device => device.value);
    let cameras = filterStreams.map(camera => camera.value);

    const allZoneData = [];
    JSON.parse(localStorage.getItem("assets")).forEach((device) => {
      device.cameras.forEach((camera) => {
        camera.zones.forEach((zone) => {
          if (allZoneData.find(o => o.value === zone.zone_id) === undefined) {
            allZoneData.push({
              value: zone.zone_id,
              label: zone.zone,
            });
          }
        });
      });
    });

    let selectedZoneIds = [];
    filterZones.forEach((item) => {
      var newSelectedZones = allZoneData.filter((ch) => ch.label === item.label);
      newSelectedZones.forEach((item) => {
        selectedZoneIds.push(item);
      })
    })

    let zones = selectedZoneIds.map(zone => zone.value);


    const allEventData = [];
    JSON.parse(localStorage.getItem("assets")).forEach((device) => {
      device.cameras.forEach((camera) => {
        camera.zones.forEach((zone) => {
          zone.rules.forEach((rule) => {
            if (!allEventData.some(e => e.value === rule.rule_id)) {
              allEventData.push(
                {
                  value: rule.rule_id,
                  label: rule.event,
                }
              )
            }
          });
        });
      });
    });

    let selectedEventIds = [];
    filterEvents.forEach((item) => {
      var newSelectedEvents = allEventData.filter((ch) => ch.label === item.label);
      newSelectedEvents.forEach((item) => {
        selectedEventIds.push(item);
      })
    })

    let rules = selectedEventIds.map(rule => rule.value);

    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/distrubtion/',
        params: {
          "granularity": graVal,
          "start": start,
          "end": end,
          "devices": devices.length > 0 ? devices.toString() : null,
          "cameras": cameras.length > 0 ? cameras.toString() : null,
          "zones": zones.length > 0 ? zones.toString() : null,
          "rules": rules.length > 0 ? rules.toString() : null,
        }
      })
      .then(res => {
        let myAlerts = res.data.filter(dataRow => allEventData.find(x => x.value === dataRow[0].rule_id));
        var event_camera_dict = {};
        JSON.parse(localStorage.getItem("assets")).forEach((device) => {
          device.cameras.forEach((camera) => {
            camera.zones.forEach((zone) => {
              zone.rules.forEach((rule) => {
                event_camera_dict[rule.rule_id] = camera.camera_name;
              });
            });
          });
        });

        let labels = [];
        myAlerts.forEach((a, alertIndex) => a.forEach((x, index) => {
          let timeItem = x.time;
          timeItem = timeItem.includes('+') ? timeItem : timeItem + '+00:00'
          myAlerts[alertIndex][index].time = timeItem;

          var index = labels.findIndex(i => i === moment(timeItem).format('MM/DD/YYYY HH:mm'));
          index === -1 && labels.push(moment(timeItem).format('MM/DD/YYYY HH:mm'))

          /*var index = labels.findIndex(i => i === moment(x.time).format('MM/DD/YYYY HH:mm'));
          index === -1 && labels.push(moment(x.time).format('MM/DD/YYYY HH:mm'))*/
        }));

        var duration = moment.duration(dateFilter['end'].diff(dateFilter['start'])).asDays();

        if (graVal === 'hour') {
          setChartDraw([])
          var labelDatesRemoved = []
          var draw = []

          labels.forEach((item, index) => {
            if (index === 0 || index === labels.length - 1) {
              //labelDatesRemoved.push(moment(item).format('MM/DD/YYYY HH:mm'))
              labelDatesRemoved.push(item)
            }
            else {
              //labelDatesRemoved.push(moment(item).format('HH:mm'))
              labelDatesRemoved.push(item.slice(11, 16))
            }
            draw.push(item)
          });


          setChartDraw(draw)
          setChartLabels(labelDatesRemoved)
        } else if (graVal === 'day') {
          setChartDraw([])
          var labelDatesRemovedtwo = []
          var drawtwo = []
          var tzOffset = moment().utcOffset() / 60;

          labels.forEach((item, index) => {
            if (duration <= 60) {
              //labelDatesRemovedtwo.push(moment(item).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY'))
              labelDatesRemovedtwo.push(moment(item).local().add(-tzOffset, 'hours').format('MM/DD/YYYY'))
            }
            else {
              if (index % 2 === 0) {
                //labelDatesRemovedtwo.push(moment(item).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY'))
                labelDatesRemovedtwo.push(moment(item).local().add(-tzOffset, 'hours').format('MM/DD/YYYY'))
              }
              else {
                labelDatesRemovedtwo.push('')
              }
            }

            //drawtwo.push(moment(item).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY hh:mm A'));
            drawtwo.push(moment(item).local().add(-tzOffset, 'hours').format('MM/DD/YYYY'));
            //labelDatesRemovedtwo.push(moment(item).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY'))

          });

          setChartDraw(drawtwo)
          setChartLabels(labelDatesRemovedtwo)
        }
        else if (graVal === 'week') {
          setChartDraw([])
          var labelDatesRemovedtwo = []
          var drawtwo = []
          var tzOffset = moment().utcOffset() / 60;

          labels.forEach((item, index) => {
            //drawtwo.push(moment(item).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY hh:mm A'));
            drawtwo.push(moment(item).local().add(-tzOffset, 'hours').format('MM/DD/YYYY'));
            //labelDatesRemovedtwo.push(moment(item).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY'))
            labelDatesRemovedtwo.push(moment(item).local().add(-tzOffset, 'hours').format('MM/DD/YYYY'))

          });
          setChartDraw(drawtwo)
          setChartLabels(labelDatesRemovedtwo)
        }

        let datasets = [];
        myAlerts.forEach((item, index) => {
          let innerDataset = [];
          labels.forEach(function (labelItem) {
            let cnt = item.find(o => moment(o.time).format('MM/DD/YYYY HH:mm') === labelItem) ? item.find(o => moment(o.time).format('MM/DD/YYYY HH:mm') === labelItem).count : 0;
            innerDataset.push(cnt)
          })

          let chartDataset = {
            name: allEventData.find(x => x.value === item[0].rule_id) ? allEventData.find(x => x.value === item[0].rule_id).label + ' - ' + findClassTitleByRuleId(item[0]?.rule_id) + ' - ' + event_camera_dict[allEventData.find(x => x.value === item[0].rule_id).value] : '-',
            data: innerDataset,
            fill: false,
            borderColor: pieColors[index % 13],
            backgroundColor: pieColors[index % 13],
            tension: 0.1
          }
          datasets.push(chartDataset)
        })

        let sortedDatasets = datasets.sort((a, b) => b.data.reduce((a, b) => a + b, 0) - a.data.reduce((a, b) => a + b, 0))

        sortedDatasets.forEach((item, index) => {
          item.backgroundColor = pieColors[index % 13]
          item.borderColor = pieColors[index % 13]
        })

        setChartDataset(sortedDatasets)
        setIsChartLoading(false);

      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getChartDataHourly = (start, end) => {
    setIsChartLoading(true);
    let devices = filterDevices.map(device => device.value);
    let cameras = filterStreams.map(camera => camera.value);

    const allZoneData = [];
    JSON.parse(localStorage.getItem("assets")).forEach((device) => {
      device.cameras.forEach((camera) => {
        camera.zones.forEach((zone) => {
          if (allZoneData.find(o => o.value === zone.zone_id) === undefined) {
            allZoneData.push({
              value: zone.zone_id,
              label: zone.zone,
            });
          }
        });
      });
    });

    let selectedZoneIds = [];
    filterZones.forEach((item) => {
      var newSelectedZones = allZoneData.filter((ch) => ch.label === item.label);
      newSelectedZones.forEach((item) => {
        selectedZoneIds.push(item);
      })
    })

    let zones = selectedZoneIds.map(zone => zone.value);


    const allEventData = [];
    JSON.parse(localStorage.getItem("assets")).forEach((device) => {
      device.cameras.forEach((camera) => {
        camera.zones.forEach((zone) => {
          zone.rules.forEach((rule) => {
            if (!allEventData.some(e => e.value === rule.rule_id)) {
              allEventData.push(
                {
                  value: rule.rule_id,
                  label: rule.event,
                }
              )
            }
          });
        });
      });
    });

    let selectedEventIds = [];
    filterEvents.forEach((item) => {
      var newSelectedEvents = allEventData.filter((ch) => ch.label === item.label);
      newSelectedEvents.forEach((item) => {
        selectedEventIds.push(item);
      })
    })

    let rules = selectedEventIds.map(rule => rule.value);

    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/distrubtion/',
        params: {
          "granularity": 'hour',
          "start": start,
          "end": end,
          "devices": devices.length > 0 ? devices.toString() : null,
          "cameras": cameras.length > 0 ? cameras.toString() : null,
          "zones": zones.length > 0 ? zones.toString() : null,
          "rules": rules.length > 0 ? rules.toString() : null,
        }
      })
      .then(res => {
        let myAlerts = res.data.filter(dataRow => allEventData.find(x => x.value === dataRow[0].rule_id));

        var event_camera_dict = {};
        JSON.parse(localStorage.getItem("assets")).forEach((device) => {
          device.cameras.forEach((camera) => {
            camera.zones.forEach((zone) => {
              zone.rules.forEach((rule) => {
                event_camera_dict[rule.rule_id] = camera.camera_name;
              });
            });
          });
        });

        let labels = [];
        myAlerts.forEach((a, alertIndex) => a.forEach((x, index) => {
          let timeItem = x.time;
          timeItem = timeItem.includes('+') ? timeItem : timeItem + '+00:00'
          myAlerts[alertIndex][index].time = timeItem;
          var index = labels.findIndex(i => i === moment(timeItem).format('MM/DD/YYYY HH:mm'));
          index === -1 && labels.push(moment(timeItem).format('MM/DD/YYYY HH:mm'))
        }));

        setChartDraw([])
        var labelDatesRemoved = []
        var draw = []

        labels.forEach((item, index) => {
          if (index === 0 || index === labels.length - 1) {
            //labelDatesRemoved.push(moment(item).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY hh:mm A'))
            labelDatesRemoved.push(item)
          }
          else {
            //labelDatesRemoved.push(moment(item).tz(localStorage.getItem('timezone')).format('hh:mm A'))
            labelDatesRemoved.push(item.slice(11, 16))
          }
          //draw.push(moment(item).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY hh:mm A'))
          draw.push(item)
        });

        setChartDraw(draw)
        setChartLabels(labelDatesRemoved)

        let datasets = [];
        myAlerts.forEach((item, index) => {
          let innerDataset = [];
          labels.forEach(function (labelItem) {
            let cnt = item.find(o => moment(o.time).format('MM/DD/YYYY HH:mm') === labelItem) ? item.find(o => moment(o.time).format('MM/DD/YYYY HH:mm') === labelItem).count : 0;
            innerDataset.push(cnt)
          })

          let chartDataset = {
            name: allEventData.find(x => x.value === item[0].rule_id) ? allEventData.find(x => x.value === item[0].rule_id).label + ' - ' + event_camera_dict[allEventData.find(x => x.value === item[0].rule_id).value] : '-',
            data: innerDataset,
            fill: false,
            borderColor: pieColors[index % 13],
            backgroundColor: pieColors[index % 13],
            tension: 0.1
          }
          datasets.push(chartDataset)
        })

        let sortedDatasets = datasets.sort((a, b) => b.data.reduce((a, b) => a + b, 0) - a.data.reduce((a, b) => a + b, 0))

        sortedDatasets.forEach((item, index) => {
          item.backgroundColor = pieColors[index % 13]
          item.borderColor = pieColors[index % 13]
        })

        setChartDataset(sortedDatasets)
        setHourlySelectedDay(moment.unix(start))
        setIsChartLoading(false);

      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const handlePrevDay = () => {
    let start = moment(hourlySelectedDay).subtract(1, 'days').format('MM/DD/YYYY') === moment(dateFilter['start']).format('MM/DD/YYYY') ? moment(dateFilter['start']).unix() : moment(hourlySelectedDay).subtract(1, 'days').startOf('day').unix();
    let end = moment(hourlySelectedDay).subtract(1, 'days').endOf('day').unix();

    getChartDataHourly(start, end);
  }

  const handleNextDay = () => {
    let start = moment(hourlySelectedDay).add(1, 'days').startOf('day').unix();
    let end = moment(hourlySelectedDay).add(1, 'days').format('MM/DD/YYYY') === moment(dateFilter['end']).format('MM/DD/YYYY') ? moment(dateFilter['end']).unix() : moment(hourlySelectedDay).add(1, 'days').endOf('day').unix();
    getChartDataHourly(start, end);
  }

  const getAlertsDetail = () => {
    let start = moment(dateFilter['start']).unix()
    let end = moment(dateFilter['end']).unix()
    let devices = filterDevices.map(device => device.value);
    let cameras = filterStreams.map(camera => camera.value);

    const allZoneData = [];
    JSON.parse(localStorage.getItem("assets")).forEach((device) => {
      device.cameras.forEach((camera) => {
        camera.zones.forEach((zone) => {
          if (allZoneData.find(o => o.value === zone.zone_id) === undefined) {
            allZoneData.push({
              value: zone.zone_id,
              label: zone.zone,
            });
          }
        });
      });
    });

    let selectedZoneIds = [];
    filterZones.forEach((item) => {
      var newSelectedZones = allZoneData.filter((ch) => ch.label === item.label);
      newSelectedZones.forEach((item) => {
        selectedZoneIds.push(item);
      })
    })

    let zones = selectedZoneIds.map(zone => zone.value);


    const allEventData = [];
    JSON.parse(localStorage.getItem("assets")).forEach((device) => {
      device.cameras.forEach((camera) => {
        camera.zones.forEach((zone) => {
          zone.rules.forEach((rule) => {
            if (!allEventData.some(e => e.value === rule.rule_id)) {
              allEventData.push(
                {
                  value: rule.rule_id,
                  label: rule.event,
                }
              )
            }
          });
        });
      });
    });

    let selectedEventIds = [];
    filterEvents.forEach((item) => {
      var newSelectedEvents = allEventData.filter((ch) => ch.label === item.label);
      newSelectedEvents.forEach((item) => {
        selectedEventIds.push(item);
      })
    })

    let rules = selectedEventIds.map(rule => rule.value);

    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/detail/',
        params: {
          "start": start,
          "end": end,
          "devices": devices.length > 0 ? devices.toString() : null,
          "cameras": cameras.length > 0 ? cameras.toString() : null,
          "zones": zones.length > 0 ? zones.toString() : null,
          "rules": rules.length > 0 ? rules.toString() : null,
        }
      })
      .then(res => {
        if (res.data.length === 0) {
          setShowNoDataModal(true);
          setNodata(true);
        }
        let myAlerts = res.data.filter(function (element) {
          return allEventData.find(x => x.value === element.rule_id) && element
        });

        setTableData(myAlerts.slice(0, 5));
        myAlerts.slice(0, 5).length > 0 ? setNodata(false) : setNodata(true);
        setIsLoading(false)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  useEffect(() => {
    let localFilter = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : null
    if (localFilter === null) {
      setIsApplyActive(false);
    }
    else {
      if (
        JSON.stringify(localFilter.devices) === JSON.stringify(filterDevices) &&
        JSON.stringify(localFilter.streams) === JSON.stringify(filterStreams) &&
        JSON.stringify(localFilter.zones) === JSON.stringify(filterZones) &&
        JSON.stringify(localFilter.events) === JSON.stringify(filterEvents)) {
        setIsApplyActive(false);
      }
      else {
        if (filterDevices.length + filterStreams.length + filterZones.length + filterEvents.length === 0) {
          setIsApplyActive(false);
        }
        else {
          setIsApplyActive(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDevices, filterStreams, filterZones, filterEvents])

  const handleGranularitySelect = (opt) => {
    if (opt.value === "hour") {
      setHourlySelectedDay(moment(dateFilter['start']).startOf('day'));
    }
    setGranularity(opt);
    getChartData(opt.value, moment(dateFilter['start']).startOf('day'));
  }

  const handleFilterApply = () => {
    let newFilterObj = {
      'start': dateFilter['start'],
      'end': dateFilter['end'],
      'devices': filterDevices,
      'streams': filterStreams,
      'zones': filterZones,
      'events': filterEvents,
    }

    localStorage.setItem('filterObj', JSON.stringify(newFilterObj));

    setFilterObj(newFilterObj);
    setShowFilterBox(false);
  }

  const clearAllFilters = () => {
    setFilterDevices([]);
    setFilterStreams([]);
    setFilterZones([]);
    setFilterEvents([]);
    setDateFilter({
      'start': moment().tz(localStorage.getItem('timezone')).startOf('day'),
      'end': moment().tz(localStorage.getItem('timezone'))
    });
    setShowFilterBox(false);
    localStorage.removeItem('filterObj');
  }

  useEffect(() => {
    let newFilterObj = {
      'start': dateFilter['start'],
      'end': dateFilter['end'],
      'devices': filterDevices,
      'streams': filterStreams,
      'zones': filterZones,
      'events': filterEvents,
    }

    if (moment(dateFilter['end']).diff(moment(dateFilter['start']), 'days') > 0) {
      setGranularity({
        'label': "Daily",
        'value': "day"
      })
      getAllData("day", moment(dateFilter['start']).startOf('day'))
    }
    else{
      setGranularity({
        'label': "Hourly",
        'value': "hour"
      })
      getAllData("hour", moment(dateFilter['start']).startOf('day'))
    }

    localStorage.setItem('filterObj', JSON.stringify(newFilterObj));
    setFilterObj(newFilterObj);
    setHourlySelectedDay(moment(dateFilter['start']).startOf('day'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter]);

  const getAssets = () => {
    dashboard_axios({
      method: "get",
      url: "/edge/assets",
      headers: {
        Authorization: "token " + localStorage.getItem("dashboard_token"),
      },
      params: {
        user_id: localStorage.getItem("dashboardId"),
      },
      baseURL: process.env.REACT_APP_DASHBOARD_URL,
    })
      .then((res) => {
        localStorage.setItem('assets', JSON.stringify(res.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllData = (gra = granularity.value, hsd = moment(dateFilter['start']).startOf('day')) => {
    getAssets()
    getPieData()
    getChartData(gra, hsd)
    getAlertsDetail()
  }

  useEffect(() => {
    setIsPieLoading(true)
    setIsLoading(true);
    setIsChartLoading(true);
    getAllData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterObj]);

  const getLatest = () => {
    setIsTrendsLoading(true);
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/latest_entry/',
        params: {
          "application": 0
        }
      })
      .then(res => {
        if (res.data !== null) {
          /*setFilterDevices(
            deviceData.filter((item) =>
              res.data.device_id === item.value
            )
          );
          setFilterStreams(
            streamData.filter((item) =>
              res.data.camera_id === item.value
            )
          );

          setFilterZones(
            zoneData.filter((item) =>
              res.data.zone_ids.find((zone) => item.value === zone)
            )
          );

          setFilterEvents(
            eventData.filter((item) =>
              res.data.rule_id === item.value
            )
          );*/

          setDateFilter({
            'start': moment(res.data.alert_date + '+00:00').tz(localStorage.getItem('timezone')).subtract(7, 'days'),
            'end': moment(res.data.alert_date + '+00:00').tz(localStorage.getItem('timezone'))
          });
        }
        else {
          setDateFilter({
            'start': moment().tz(localStorage.getItem('timezone')).startOf('day'),
            'end': moment().tz(localStorage.getItem('timezone'))
          });
        }
        setIsTrendsLoading(false);
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const goToAllComment = (item) => {
    history.push('/trends/comment/' + item.device_id + '/' + item.camera_id + '/' + item.rule_id);
  }

  const dropdownRef = useRef();
  const closeOpenMenus = (e) => {
    if (dropdownRef.current && showFilterBox && !dropdownRef.current.contains(e.target)) {
      setShowFilterBox(false)
    }
  }
  document.addEventListener('mousedown', closeOpenMenus)

  const getDiffer = () => {
    var tzOffset = moment().utcOffset() / 60;
    var utcLocal = new Date().getTimezoneOffset() / 60;
    var differ = -(tzOffset + utcLocal)
    return differ;
  }

  return (
    <>
      <Container fluid>
        <Row className='d-flex'>
          <div className='trends-content-wide'>
            <div className='page-content-header d-flex justify-content-between align-items-center'>
              <div className='page-name'>Alert Trends</div>
              <div className='d-flex'>
                <div className='filter-dropdown' ref={dropdownRef}>
                  <Button className="light-purple-btn me-3" onClick={() => setShowFilterBox(!showFilterBox)}>
                    <Filter size={24} />
                    &nbsp;
                    Filter
                    &nbsp;
                    {filterDevices.length + filterStreams.length + filterZones.length + filterEvents.length > 0 ?
                      <span className='filter-selected-count'>{(filterDevices.length + filterStreams.length + filterZones.length + filterEvents.length)}</span>
                      :
                      null
                    }
                    &nbsp;
                    <ChevronDown size={16} />
                  </Button>
                  {showFilterBox ?
                    <div className='filters-box'>
                      <Accordion>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header className={filterDevices.length > 0 && 'selected-filters-name'}>
                            {filterDevices.length > 0 ? filterDevices.map(itm => itm.label).join(',') : 'Device'}
                          </Accordion.Header>
                          <Accordion.Body>
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              name="deviceOptions"
                              options={deviceData}
                              className="basic-multi-select mt-2"
                              classNamePrefix="select"
                              placeholder={<span><Search className="mb-1 me-1" /> Select Devices</span>}
                              onChange={(items) => handleDeviceSelect(items)}
                              value={filterDevices}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header className={filterStreams.length > 0 && 'selected-filters-name'}>
                            {filterStreams.length > 0 ? filterStreams.map(itm => itm.label).join(',') : 'Stream'}
                          </Accordion.Header>
                          <Accordion.Body>
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              name="streamOptions"
                              options={streamData}
                              className="basic-multi-select mt-2"
                              classNamePrefix="select"
                              placeholder={<span><Search className="mb-1 me-1" /> Select Streams</span>}
                              onChange={(items) => handleStreamSelect(items)}
                              value={filterStreams}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header className={filterZones.length > 0 && 'selected-filters-name'}>
                            {filterZones.length > 0 ? filterZones.map(itm => itm.label).join(',') : 'Zone'}
                          </Accordion.Header>
                          <Accordion.Body>
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              name="zoneOptions"
                              options={zoneData}
                              className="basic-multi-select mt-2"
                              classNamePrefix="select"
                              placeholder={<span><Search className="mb-1 me-1" /> Select Zones</span>}
                              onChange={(items) => handleZoneSelect(items)}
                              value={filterZones}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header className={filterEvents.length > 0 && 'selected-filters-name'}>
                            {filterEvents.length > 0 ? filterEvents.map(itm => itm.label).join(',') : 'Events'}
                          </Accordion.Header>
                          <Accordion.Body>
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              name="eventOptions"
                              options={eventData}
                              className="basic-multi-select mt-2"
                              classNamePrefix="select"
                              placeholder={<span><Search className="mb-1 me-1" /> Select Events</span>}
                              onChange={(items) => handleEventSelect(items)}
                              value={filterEvents}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className='d-flex justify-content-between align-items-center px-4 py-2'>
                        <div className={filterDevices.length + filterStreams.length + filterZones.length + filterEvents.length > 0 ? 'clear-filter-active' : 'clear-filter'} onClick={clearAllFilters}>Clear Filter</div>
                        <div className='d-flex justify-content-between'>
                          <Button className="cancel-filter me-3" onClick={() => setShowFilterBox(false)}>Cancel</Button>
                          <Button className="apply-filter-enabled"
                            disabled={!isApplyActive}
                            onClick={handleFilterApply}
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    </div>
                    :
                    null
                  }
                </div>
                <RDateRangePicker
                  placeholder="Default"
                  editable={false}
                  className="date-filter-button me-3"
                  placement="bottomEnd"
                  disabledDate={combine(allowedMaxDays(90), afterToday())}
                  format="MM/dd/yyyy HH:mm"
                  value={[
                    new Date(moment(dateFilter['start']).format('MM/DD/YYYY HH:mm')),
                    new Date(moment(dateFilter['end']).format('MM/DD/YYYY HH:mm'))
                  ]}
                  ranges={[
                    {
                      label: 'Current Time',
                      value: [
                        new Date(new Date(moment().startOf('day')) - getDiffer() * (60 * 60 * 1000)),
                        new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                      ],
                      closeOverlay: true
                    },
                    {
                      label: 'yesterday',
                      value: [
                        new Date(new Date(moment().subtract(1, 'days').startOf('day')) - getDiffer() * (60 * 60 * 1000)),
                        new Date(new Date(moment().subtract(1, 'days').endOf('day')) - getDiffer() * (60 * 60 * 1000))
                      ],
                      closeOverlay: true
                    },
                    {
                      label: 'Last Hour',
                      value: [
                        new Date(new Date(moment().subtract(1, 'hours')) - getDiffer() * (60 * 60 * 1000)),
                        new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                      ],
                      closeOverlay: true
                    },
                    {
                      label: 'Last 3 Days',
                      value: [
                        new Date(new Date(moment().subtract(3, 'days')) - getDiffer() * (60 * 60 * 1000)),
                        new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                      ],
                      closeOverlay: true
                    },
                    {
                      label: 'Last 15 Days',
                      value: [
                        new Date(new Date(moment().subtract(15, 'days')) - getDiffer() * (60 * 60 * 1000)),
                        new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                      ],
                      closeOverlay: true
                    },
                    {
                      label: 'Last 30 Days',
                      value: [
                        new Date(new Date(moment().subtract(30, 'days')) - getDiffer() * (60 * 60 * 1000)),
                        new Date(new Date() - getDiffer() * (60 * 60 * 1000))
                      ],
                      closeOverlay: true
                    },
                  ]}
                  cleanable={false}
                  onChange={(e) => {
                    setDateFilter({
                      'start': moment(e[0]).add(getDiffer(), 'hours'),
                      'end': moment(e[1]).add(getDiffer(), 'hours')
                    })
                  }}
                />
              </div>
            </div>

            <div className='page-wrapper'>
              {isTrendsLoading ?
                <Loader />
                :
                <>
                  <div className='d-flex w-100'>
                    <div className='pie-chart-box me-5'>
                      {isPieLoading ?
                        <Loader />
                        :
                        <div className='w-100'>
                          <h5>TOP STREAMS</h5>
                          <Chart
                            series={
                              [{
                                name: '',
                                data: pieDataStreams
                              }]
                            }
                            type="bar"
                            height="220"
                            options={{
                              chart: {
                                toolbar: {
                                  tools: {
                                    download: false //!pieDataStreamsLabel.every(item => item === 0) // <== line to add // <== line to add
                                  },
                                  export: {
                                    csv: {
                                      filename: 'Top-Strems-' + new Date().toISOString().slice(0, 10),
                                    },
                                  },
                                },
                              },
                              plotOptions: {
                                bar: {
                                  borderRadius: 4,
                                  horizontal: true,
                                  dataLabels: {
                                    position: 'top'
                                  }
                                }
                              },
                              dataLabels: {
                                enabled: true,
                                offsetX: 25,
                                style: {
                                  colors: ['#333']
                                },
                                formatter: function (value) {
                                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                }
                              },
                              xaxis: {
                                categories: pieDataStreamsLabel,
                                labels: {
                                  formatter: function (value) {
                                    return Number.isInteger(value) ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                  }
                                },
                              },
                              tooltip: {
                                y: {
                                  formatter: function (val, opt) {
                                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                  }
                                },
                              },

                              colors: [
                                function ({ value, seriesIndex, dataPointIndex, w }) {
                                  return pieColors[dataPointIndex];
                                }
                              ]
                            }}
                          />
                        </div>
                      }
                    </div>


                    <div className='pie-chart-box'>
                      {isPieLoading ?
                        <Loader />
                        :
                        <div className='w-100'>
                          <h5>TOP ALERTS</h5>
                          <Chart
                            series={
                              [{
                                name: '',
                                data: pieDataAlerts
                              }]
                            }
                            type="bar"
                            height="220"
                            options={{
                              chart: {
                                toolbar: {
                                  tools: {
                                    download: false //!pieDataAlertsLabel.every(item => item === 0) // <== line to add // <== line to add
                                  },
                                  export: {
                                    csv: {
                                      filename: 'Top-Alerts-' + new Date().toISOString().slice(0, 10),
                                    },
                                  },
                                },
                              },
                              plotOptions: {
                                bar: {
                                  borderRadius: 4,
                                  horizontal: true,
                                  dataLabels: {
                                    position: 'top'
                                  }
                                }
                              },
                              dataLabels: {
                                enabled: true,
                                offsetX: 25,
                                style: {
                                  colors: ['#333']
                                },
                                formatter: function (value) {
                                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                }
                              },
                              xaxis: {
                                categories: pieDataAlertsLabel,
                                labels: {
                                  formatter: function (value) {
                                    return Number.isInteger(value) ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                  }
                                },
                              },
                              tooltip: {
                                y: {
                                  formatter: function (val, opt) {
                                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                  }
                                }
                              },
                              colors: [
                                function ({ value, seriesIndex, dataPointIndex, w }) {
                                  return pieColors[dataPointIndex];
                                }
                              ]
                            }}
                          />
                        </div>
                      }
                    </div>
                  </div>
                  <div className='line-chart-box mt-3'>
                    <div className='d-flex justify-content-between'>
                      <h5>Alerts Distribution</h5>

                      {granularity.label === "Hourly" &&
                        <div className='d-flex align-items-center'>
                          {moment(hourlySelectedDay).format('MM/DD/YYYY') === moment(dateFilter['start']).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY') ?
                            <ChevronLeft className='chevron-disabled' size={20} />
                            :
                            <ChevronLeft className='chevron-enabled' size={20} onClick={() => handlePrevDay()} />
                          }

                          <div className='current-day mx-2'>
                            {moment(hourlySelectedDay).format('MM/DD/YYYY')}
                          </div>

                          {
                            moment(dateFilter['end']).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY') === moment(hourlySelectedDay).format('MM/DD/YYYY') ?
                              <ChevronRight className='chevron-disabled' size={20} />
                              :
                              <ChevronRight className='chevron-enabled' size={20} onClick={() => handleNextDay()} />
                          }
                        </div>
                      }
                      <div className='d-flex me-3'>
                        <div
                          className={granularity.label === "Hourly" ? 'activeGranularity' : 'granularity'}
                          onClick={() => handleGranularitySelect({
                            'label': "Hourly",
                            'value': "hour",
                          })}
                        >
                          Hourly
                        </div>
                        <div>&nbsp;|&nbsp;</div>
                        <div
                          className={granularity.label === "Daily" ? 'activeGranularity' : 'granularity'}
                          onClick={() => handleGranularitySelect({
                            'label': "Daily",
                            'value': "day",
                          })}
                        >
                          Daily
                        </div>
                        <div>&nbsp;|&nbsp;</div>
                        <div
                          className={granularity.label === "Weekly" ? 'activeGranularity' : 'granularity'}
                          onClick={() => handleGranularitySelect({
                            'label': "Weekly",
                            'value': "week",
                          })}
                        >
                          Weekly
                        </div>
                      </div>
                    </div>

                    {isChartLoading ?
                      <Loader />
                      :
                      lineData.datasets.length === 0 ?
                        <div className="line-graph-not-found">
                          <div className="text-center">
                            <img src={NotFound} alt="not-found" className="not-found-img" />
                            <p className="not-found-text">Looks like no data found!</p>
                            <p>Please select another day.</p>
                          </div>
                        </div>
                        :
                        <Chart
                          options={{

                            chart: {
                              animations: {
                                enabled: false,
                              },
                              fontFamily: 'Lato, sans-serif',
                              type: 'line',
                              toolbar: {
                                autoSelected: 'zoom',
                                tools: {
                                  zoom: false,
                                  zoomin: false,
                                  zoomout: false,
                                  pan: false,
                                  selection: false,
                                  reset: false,
                                  download: false //!lineData.labels.every(item => item === 0) // <== line to add // <== line to add
                                },
                                export: {
                                  csv: {
                                    filename: 'Alerts-Distribution' + new Date().toISOString().slice(0, 10),
                                  },
                                },
                              },
                            },
                            dataLabels: {
                              enabled: false,
                            },
                            stroke: {
                              curve: 'straight',
                              width: 3
                            },
                            grid: {
                              row: {
                                colors: ['transparent']
                              },
                              padding: {
                                top: 0,
                                right: 20,
                                bottom: 50,
                                left: 20
                              },
                            },
                            xaxis: {
                              categories: lineData.labels,
                              tooltip: {
                                formatter: function (value, opts) {
                                  const v = opts.w.globals.categoryLabels[opts.dataPointIndex]
                                  if (v === undefined || v === '') {
                                    return ''
                                  } else if (opts.w.globals.categoryLabels[opts.dataPointIndex].length === 5) {
                                    return opts.w.globals.categoryLabels[opts.dataPointIndex]
                                  } else {
                                    return v;
                                  }
                                },
                              },
                              labels: {
                                rotate: -70,
                                style: {
                                  fontSize: '11px',
                                  fontWeight: 400,
                                },
                                rotateAlways: true,
                                formatter: function (value) {
                                  var xaxisLabel = document.querySelectorAll('.apexcharts-xaxis-label tspan')
                                  xaxisLabel.forEach((char, i) => {
                                    /*if (char.textContent.length === 5 && char.textContent !== '12:00') {
                                      document.querySelectorAll('.apexcharts-xaxis-label tspan')[i].classList.add('hidetime');
                                    }*/
                                  })
                                  return value;
                                }
                              }
                            },
                            tooltip: {
                              fixed: {
                                enabled: false
                              },
                              enabled: true,
                              custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                                const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
                                const hoverIndexes = w.globals.seriesX.map(seriesX => {
                                  return seriesX.findIndex(xData => xData === hoverXaxis);
                                });

                                let hoverList = '';
                                hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                                  if (hoverIndex >= 0) {
                                    hoverList += `<span class="apexcharts-tooltip-marker circlemake" style="background-color: ${pieColors[seriesEachIndex % 13]};"></span><span>${w.globals.seriesNames[seriesEachIndex]}: ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                                  }
                                });
                                const formatHoverX = chartDraw[dataPointIndex]

                                return `<div class="card">
                              <div class="card-header p-1">${formatHoverX}</div>
                              <div class="card-body p-1">
                               ${hoverList}
                              </div>
                            </div>`;
                              }
                            },
                            colors: pieColors,
                            legend: {
                              position: 'bottom',
                              horizontalAlign: 'center',
                            },
                          }}
                          series={
                            lineData.datasets
                          }
                          height={350}
                          type="line"
                        />
                    }
                  </div>
                  <div className='table-box h-auto mt-3'>
                    <div className='d-flex justify-content-between align-items-center mb-3 me-3'>
                      <h5>Alerts Details</h5>
                      {!noData && <Link to="/trends/details"><h5 className='see-table'>See All</h5></Link>}

                    </div>
                    {isLoading ?
                      <Loader />
                      :
                      <Table>
                        <thead>
                          <tr>
                            <th>ALERTS</th>
                            <th>DEVICE</th>
                            <th>STREAM</th>
                            <th>ZONE</th>
                            <th>REVIEWED</th>
                            <th>REVIEWED %</th>
                            <th>COMMENT</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.map((item, index) => {
                            return <tr key={index}>
                              <td className='applications'>{allEventData.find(x => x.value === item.rule_id) ? allEventData.find(x => x.value === item.rule_id).label + ' - ' + findClassTitleByRuleId(item.rule_id) : '-'}</td>
                              <td>{deviceData.find(x => x.value === item.device_id) ? deviceData.find(x => x.value === item.device_id).label : '-'}</td>
                              <td>{streamData.find(x => x.value === item.camera_id) ? streamData.find(x => x.value === item.camera_id).label : '-'}</td>
                              <td>{item.zone_ids.length > 0 ? zoneData.find(x => x.value === item.zone_ids[0]) ? zoneData.find(x => x.value === item.zone_ids[0]).label : '-' : '-'}</td>
                              <td className={item.reviewed_amount === 0 ? 'none-reviewed' : item.reviewed_amount === item.total_amount ? 'all-reviewed' : 'some-reviewed'}>{item.reviewed_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/{item.total_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                              <td className="percentage-col">{item.percentage}%</td>
                              <td>
                                {item.reviewed_amount === 0 ?
                                  <span className='comment comment-disabled'>View Comment</span>
                                  :
                                  <span className='comment' onClick={() => goToAllComment(item)}>View Comment</span>
                                }
                              </td>
                            </tr>
                          })
                          }
                        </tbody>
                      </Table>
                    }
                  </div>
                </>
              }
            </div>
          </div>
        </Row>
      </Container>

      <NoDataModal
        show={showNoDataModal}
        handleClose={handleCloseNoDataModal}
        getLatest={getLatest}
      />
    </>
  )
}
