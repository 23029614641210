import React, { useEffect, useState } from 'react';
import { Modal, Form, OverlayTrigger, Popover, Button, Row, Col } from 'react-bootstrap';
import { ArrowLeft, InfoCircle } from 'react-bootstrap-icons';
import ReactPlayer from 'react-player/lazy'
import arrowLeft from '../../assets/images/arrow-left.png';
import arrowRight from '../../assets/images/arrow-right.png';
import { fast_api_axios } from '../../utils/axiosFast';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext';
import Loader from '../Loader';
import { useToast } from '../../contexts/ToastContext';
import RuleInfoBox from './RuleInfoBox';
import { findClassTitleByRuleId } from '../../utils/generic';

export default function ModalDisplayVideo(props) {
  const { currentUser } = useAuth();
  const { updateMessage } = useToast();
  let history = useHistory();
  const { logout } = useAuth();

  const [isPlaying, setIsPlaying] = useState(true);
  const [isViewImage, setIsViewImage] = useState(false);
  const [isVideo, setIsVideo] = useState(true);

  const deviceData = JSON.parse(localStorage.getItem('deviceData'))
  const streamData = JSON.parse(localStorage.getItem('streamData'))
  const zoneData = JSON.parse(localStorage.getItem('zoneData'))
  const allEventData = JSON.parse(localStorage.getItem('allEventData'))

  const [review, setReview] = useState('');
  const [isReviewed, setIsReviewed] = useState(props.isReviewed);
  const [isVideoReviewed, setIsVideoReviewed] = useState(props.isReviewed);
  const [isImageReviewed, setIsImageReviewed] = useState(props.isReviewed);
  const [isReviewCheckActive, setIsReviewCheckActive] = useState(false);
  const [isPredictionCorrect, setIsPredictionCorrect] = useState('');
  const [isAnyReviewed, setIsAnyReviewed] = useState(false);

  const [alertDate, setAlertDate] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [device, setDevice] = useState(null);
  const [stream, setStream] = useState(null);
  const [zone, setZone] = useState(null);
  const [ruleInfoObj, setRuleInfoObj] = useState({
    "rule_count": null,
    "count_logic": null,
    "proximity": null,
    "temporal": null,
    "zones": null
  })
  const [countInfo, setCountInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);

  const [imageUrl, setImageUrl] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);

  const [imageList, setImageList] = useState([]);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const changeActiveImage = (index) => {
    setActiveImageIndex(index)
    setImageUrl(imageList[index])
  }

  const getAlertDetail = () => {
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/' + props.id
      })
      .then(res => {
        setYear(moment(res.data.alert_date).year())
        setMonth((moment(res.data.alert_date).month() + 1))
        setDay(moment(res.data.alert_date).date())

        setImageUrl(res.data.image_url)
        setVideoUrl(res.data.video_url)

        if (res.data.alert_type === 2) {
          setImageList(res.data.image_list)
          setActiveImageIndex(0);
        }

        setDevice(deviceData.find(x => x.value === res.data.device_id) ? deviceData.find(x => x.value === res.data.device_id).label : '-');
        setStream(streamData.find(x => x.value === res.data.camera_id) ? streamData.find(x => x.value === res.data.camera_id).label : '-');
        res.data.zone_ids.length > 0 ? setZone(zoneData.find(x => x.value === res.data.zone_ids[0]) ? zoneData.find(x => x.value === res.data.zone_ids[0]).label : '-') : setZone('Entire Scene');
        var event_name = allEventData.find(x => x.value === res.data.rule_id) ? allEventData.find(x => x.value === res.data.rule_id).label : '-'
        var class_title = findClassTitleByRuleId(res.data.rule_id)
        var eventAndClass = class_title ? event_name + ' - ' + class_title : event_name
        setAlertName(eventAndClass);
        setAlertDate(moment.utc(res.data.alert_date).tz(localStorage.getItem('timezone')).format('MM/DD/YYYY HH:mm'))
        setRuleInfoObj({
          "rule_count": res.data.rule_count,
          "count_logic": res.data.count_logic,
          "proximity": res.data.proximity,
          "temporal": res.data.temporal,
          "zones": res.data.zones
        })
        setZone(res.data.zones || 'Entire Scene');
        setCountInfo(res.data.counts)
        getFeedbackDetail()
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const getFeedbackDetail = () => {
    fast_api_axios(
      {
        method: 'get',
        url: '/api/v1/alerts/' + props.id + '/feedback'
      })
      .then(res => {
        let reviewedBy = []
        res.data.results.forEach(item => {
          reviewedBy.push(
            {
              "name": item.user.username
            }
          )
        })
        if (res.data.results.length > 0) {
          setIsReviewed(true)
          setIsVideoReviewed(true)
          setIsImageReviewed(true)
          setReview(res.data.results.find(item => item.user_id === JSON.parse(localStorage.getItem('user')).id).message)
          setIsPredictionCorrect(res.data.results.find(item => item.user_id === JSON.parse(localStorage.getItem('user')).id).is_correct ? 'true' : 'false')
        }
        setIsLoading(false)
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
      });
  }

  const sendReview = (isCorrect) => {
    setIsPredictionCorrect(isCorrect)
    fast_api_axios(
      {
        method: 'put',
        url: '/api/v1/alerts/' + props.id + '/feedback',
        data: {
          "user_id": currentUser.id,
          "is_correct": isCorrect,
          "message": review
        }
      })
      .then(res => {
        setIsAnyReviewed(true)
        updateMessage('Congratulations! You reviewed the alert.', 'success');
        openNext();
        //props.handleRefresh()
        //closeModal()
      })
      .catch(error => {
        if (error.response.data.detail === 'Please re-login') {
          updateMessage('Your session has expired, please login again.', 'error');
          logout();
          history.push('/login')
        }
        else {
          updateMessage('Something went wrong.', 'error')
        }
      });
  }

  const onProgress = (info) => {
    if (info.played >= 0.7) {
      //setIsReviewCheckActive(true)
      setIsVideoReviewed(true)
    }
  }

  const onPause = () => {
    setIsPlaying(false)
  }

  useEffect(() => {
    if (props.show) {
      getAlertDetail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const handleReviewChange = (e) => {
    setReview(e.target.value)
  }

  const openPrevious = () => {
    if (props.alertIndex > 0) {
      setIsLoading(true);
      closeModal();
      props.handleOpenNewVideoModal(props.data[props.alertIndex - 1].alert_uuid, props.data[props.alertIndex - 1], props.alertIndex - 1)
    }
  }

  const openNext = () => {
    if (props.data[props.alertIndex + 1] !== undefined && props.data[props.alertIndex + 1].alert_uuid !== undefined) {
      setIsLoading(true);
      closeModal();
      props.handleOpenNewVideoModal(props.data[props.alertIndex + 1].alert_uuid, props.data[props.alertIndex + 1], props.alertIndex + 1)
    }
    else {
      closeModal()
      props.handleClose()
      window.location.reload();
    }
  }

  const closeModal = () => {
    setReview('')
    setIsReviewed(false)
    setIsImageReviewed(false)
    setIsVideo(true);
    setIsVideoReviewed(false)
    setIsReviewCheckActive(false)
    setIsPredictionCorrect('')
    setAlertName(null)
    setDevice(null)
    setStream(null)
    setZone(null)
    setIsLoading(true)
  }

  const closeModals = () => {
    setReview('')
    setIsReviewed(false)
    setIsImageReviewed(false)
    setIsVideo(true);
    setIsVideoReviewed(false)
    setIsReviewCheckActive(false)
    setIsPredictionCorrect('')
    setAlertName(null)
    setDevice(null)
    setStream(null)
    setZone(null)
    setIsLoading(true)
    setIsViewImage(false)
    props.handleClose()
    if (isAnyReviewed) {
      window.location.reload();
    }
    setIsAnyReviewed(false);
  }

  const handleNotVideo = (e) => {
    if (e.srcElement.error['code'] === 4) {
      setIsVideoReviewed(true);
      setIsVideo(false);
    } else {
      console.log(e)
    }
  }

  return (
    <Modal show={props.show} onHide={closeModals} centered size={isViewImage ? 'xl' : 'xl'} fullscreen={isViewImage} dialogClassName={isVideo && !isViewImage ? 'modal-95w' : ''}>
      <Modal.Header closeButton>
        {isViewImage ? <ArrowLeft className='video-modal-back' size={30} onClick={() => setIsViewImage(false)} /> : null}
      </Modal.Header>
      <Modal.Body className="video-modal">
        {isLoading ?
          <div className="d-flex align-items-center h-100">
            <Loader />
          </div>
          :
          isViewImage && imageUrl ?
            <div className="fullscreenContent">
              <img className="fullscreenImg" src={imageUrl} alt="img"/>
              <p className="video-date">
                <span>{alertDate}</span></p>
            </div>
            :
            <div className="alert-detail-view">
              <div className="d-flex justify-content-between p-3">
                <div className="d-flex flex-column">
                  <div className='alert-name'>
                  Alert: &nbsp;
                  <span>
                    {alertName}
                    &nbsp;
                    {props.item &&
                      <OverlayTrigger
                        placement="right-end"
                        overlay={
                          <Popover id="properties-tooltip" arrowProps={null}>
                            <RuleInfoBox data={ruleInfoObj} />
                          </Popover>
                        }
                      >
                        <InfoCircle color='#0052CC' size={16} />
                      </OverlayTrigger>
                    }
                  </span>
                </div>
                <div className='stream-name'>
                  Stream: <span>{stream}</span>
                </div>
              </div>
              <div className='d-flex flex-column'>
                <div className='device-name'>
                  Device: <span>{device}</span>
                </div>
                <div className='zone-name'>
                  Alert Zone: <span>{zone}</span>
                </div>
              </div>
              <div className='d-flex flex-column'>
                <div className='alert-date'>
                  <span>{alertDate}</span>
                </div>
              </div>
            </div>
            <div className="review-content">
              <div className="d-flex align-items-center gap-1">
                <InfoCircle size={16}/>
                Is this detection correct
              </div>
              {isReviewed ?
                <>
                  <button className={`yes-btn ${isPredictionCorrect === 'true' ? 'active' : ''}`} onClick={() => sendReview('true')}>Yes</button>
                  <button className={`no-btn ${isPredictionCorrect === 'false' ? 'active' : ''}`} onClick={() => sendReview('false')}>No</button>
                </>
                :
                <>
                  <button className="yes-btn" onClick={() => sendReview('true')}>Yes</button>
                  <button className="no-btn" onClick={() => sendReview('false')}>No</button>
                </>
              }
              <div className="image-view">
                Alert Image: <span className="alert-link" onClick={() => setIsViewImage(true)}>View Image</span>
              </div>
            </div>
            <div className="source-content">
              <img src={arrowLeft} alt="leftarrow" className={`arrow ${props.alertIndex === 0 ? 'disabled' : ''}`} onClick={openPrevious} />
              {isVideo && props.item?.alert_type === 0 ?
                <ReactPlayer
                  url={videoUrl}
                  muted={true}
                  playing={isPlaying}
                  controls={true}
                  playbackRate={1}
                  onProgress={onProgress}
                  playsinline={true}
                  onPause={onPause}
                  width="800px"
                  height="450px"
                  onError={(e) => handleNotVideo(e)}
                  light={imageUrl}
                />
                :
                <img className='alert-image' src={imageUrl} alt="img" />
              }
              <img src={arrowRight} alt="rightArrow" className={`arrow ${props.alertIndex === props.data.length - 1 ? 'disabled' : ''}`} onClick={openNext} />
            </div>
            { props.item?.alert_type === 1 ?
              <div className='prompt-content'>
                <div className='prompt'>
                  Prompt: <span>{props.item?.extra?.prompt}</span>
                </div>
                <div className='result' title={props.item?.extra?.result}>
                  Result: <span>{props.item?.extra?.result}</span>
                </div>
              </div>
              :
              null
            }
          </div>
        }

      </Modal.Body>
    </Modal >
  );
}
